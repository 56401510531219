import React from 'react';

import classes from './TopDrawer.module.css';
// import Aux from '../../hoc/Auxiliary/Auxiliary';

const TopDrawer = (props) => {

    let attachedClasses = [classes.Row, classes.Close];
    let topDrawerClasses = [classes.TopDrawer];

    if (props.open) {
        attachedClasses = [classes.Row, classes.Open];
        topDrawerClasses = [classes.TopDrawer, classes.TopDrawerOpen];
    }

    // let buttons = (
    //     <Aux>
    //         <button onClick={props.toggle} className={classes.Button}>
    //             <b style={{fontSize: '1.2rem'}}>{props.open ? '▲ ' : '▼ '}</b> 
    //             Included Models
    //         </button>
    //     </Aux>
    // );

    const titleSymbol = props.open ? '▲ ' : '▼ ';
    
    return (
        <div className={classes.Container}>
            <div className={topDrawerClasses.join(' ')}>
            <h6 className={classes.Title} onClick={props.toggle}>{titleSymbol} {props.title}</h6>
                <div className={attachedClasses.join(' ')}>
                    {/* <hr /> */}
                    {props.children}
                </div>
            </div>
            {/* {buttons} */}
        </div>
    );
};

export default TopDrawer;