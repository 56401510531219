import React, { useState } from 'react'; 
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { bscStartTime, ascStartTime, bscSessionLength, vacStartTime } from '../../../../utilities/globalVariables';
import classes from './Calendar.module.css';
import Modal from '../../../../UI/Modal/Modal';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import { raw } from '../../../../translations/en/raw';

const Calendar = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();

    // get data from props
    const children = props.children;
    // const centres = props.centres;
    const casualData = props.casualData;
    const rollData = props.rollData;
    const excursionData = props.excursionData;
    console.log('excursionData: ', excursionData);
    // console.log('rollData: ', rollData);
    const events = [];

    // make sure component has received props before trying to create event object
    if (children && children.length > 0) {

        // iterate through each row in RDS table roll and turn row into an event object to display on calendar
        rollData.filter(obj => obj.location_type === 'centre').forEach(obj => {
            // const id = obj.id;
            // console.log('rollData obj for which event being created: ', obj);
            // console.log('children data: ', children);
            const title = children.filter(child => +child.child_id === +obj.child_id)[0].first_name;
            let startHours, endHours, color;
            if (obj.session === 'bsc') {
                color = 'pink'
                startHours = bscStartTime;
            } else if (obj.session === 'asc') {
                color = 'blue';
                startHours = ascStartTime;
            } else {
                color = 'green';
                startHours = vacStartTime;
            }
            endHours = startHours + bscSessionLength;
            const start = new Date(obj.date).setTime(new Date(obj.date).getTime() + (startHours*60*60*1000));;
            const end = new Date(obj.date).setTime(new Date(obj.date).getTime() + (endHours*60*60*1000));;
            events.push({
                // id: id, 
                title: title, start: start, end: end, color: color, centre_id: obj.centre_id, child_id: obj.child_id, date: obj.date, session: obj.session
            })
        });
    
        // go through casual bookings and find any which haven't been added to roll yet to display on calendar as pending
        casualData.forEach(obj => {
            
            // skip iteration of forEach if booking cancelled
            const status = obj.status;
            if (status && status.includes('cancel')) {
                return;
            }
    
    
            // check if current casual booking row is anywhere in events
            let onRoll = false;
            events.forEach(event => {
                if (event.session === obj.session && +event.child_id === +obj.child_id && +event.centre_id === +obj.centre_id && event.date === obj.date) {
                    onRoll = true;
                }
            });
    
            // if not on roll yet, add to events 
            if (!onRoll) {
                const title = children.filter(child => +child.child_id === +obj.child_id)[0].first_name + ' (pending)';
                let startHours, endHours;
                const color = 'grey';
                if (obj.session === 'bsc') {
                    startHours = bscStartTime;
                } else if (obj.session === 'asc') {
                    startHours = ascStartTime;
                } else {
                    startHours = vacStartTime;
                }
                endHours = startHours + bscSessionLength;
                const start = new Date(obj.date).setTime(new Date(obj.date).getTime() + (startHours*60*60*1000));;
                const end = new Date(obj.date).setTime(new Date(obj.date).getTime() + (endHours*60*60*1000));;                
                events.push({
                    // id: id, 
                    title: title, start: start, end: end, color: color, extendedProps: {centre_id: obj.centre_id, child_id: obj.child_id, date: obj.date, session: obj.session}
                })
            }        
            // console.log('casualData object: ', obj);
        });
    }

    // get unique excursion Ids
    const excursionIds = excursionData.map(obj => obj.excursion_id).filter((v, i, a) => a.indexOf(v) === i);
    console.log('excursionIds: ', excursionIds);
    
    // populate excursions on calendar
    excursionIds.forEach(id => {
        const detail = excursionData.filter(obj => obj.excursion_id === id);
        const start = detail[0].start_date;
        const end = detail[0].end_date;
        const color = 'rgb(136, 218, 181)';
        
        const extendedProps = {};
        detail.forEach(obj => {extendedProps[obj.key] = obj.value});

        events.push({title: 'Excursion ' + id, start: start, end: end, allDay: true, color: color, extendedProps: extendedProps});

    })

    // console.log('events: ', events);

    // const events = [
    //     {
    //       id: 1,
    //       title: 'event 1',
    //       start: '2022-01-09T10:00:00',
    //       end: '2022-01-09T12:00:00',
    //     },
    //     {
    //       id: 2,
    //       title: 'event 2',
    //       start: '2022-01-16T13:00:00',
    //       end: '2022-01-16T18:00:00',
    //     },
    //     { id: 3, title: 'event 3', start: '2022-01-11', end: '2022-01-20' },
    //   ];
      
    const handleEventClick = (event) => {

        const detail = event.extendedProps;
        const title = event.title;

        console.log('[handleEventClick] - extendedProps: ', detail);
        setShowModal(true);

        const content = (
            <Aux>
                <h5 className={classes.ExcursionTitle}>{title}</h5>
                {
                    Object.keys(detail).map(key => {
                        return (
                            <div className={classes.ExcursionRow}>
                                <span className={classes.ExcursionKey}>{key}</span>
                                <span className={classes.ExcursionValue}>{detail[key]}</span>
                            </div>
                        );
                    })
                }

            </Aux>
        );
        
        setModalContent(content)
    }

      
    return (
        <div className={classes.Calendar}>
            <Modal show={showModal} modalClosed={() => setShowModal(false)}>
                {modalContent}
            </Modal>
            <div className={classes.CalendarInfoBar}>
                <span className={classes.InfoBarText}>Note: Regularly scheduled bookings will only show on calendar two weeks out</span>
                <div className={classes.Key}>
                    <span>Key</span> 
                    <ul style={{marginBottom: '3px'}}>
                        {/* <li className={classes.BscKey}><span style={{color: '#464646'}}>{raw['bsc']}</span></li>
                        <li className={classes.AscKey}><span style={{color: '#464646'}}>{raw['asc']}</span></li>
                        <li className={classes.VacKey}><span style={{color: '#464646'}}>{raw['vac']}</span></li>
                        <li className={classes.ExcursionKey}><span style={{color: '#464646'}}>test</span></li> */}
                        <li className={classes.BscKey}>{raw['bsc']}</li>
                        <li className={classes.AscKey}>{raw['asc']}</li>
                        <li className={classes.VacKey}>{raw['vac']}</li>
                        <li className={classes.ExcKey}>Excursion</li>
                    </ul>
                </div>

            </div>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                left: 'prev next today dayGridMonth,timeGridWeek,timeGridDay',
                center: 'showSchedule changeSchedule',
                right: 'newBooking'
                }}
                customButtons={{
                    newBooking: {
                        text: 'new casual booking',
                        click: () => props.newCasual()
                    },
                    changeSchedule: {
                        text: 'change permanent bookings',
                        click: () => props.updateSchedule()
                    },
                    showSchedule: {
                        text: 'show permanent bookings',
                        click: () => props.showSchedule()
                    }
                }}
                events={events}
                timeZone="Aus/Sydney"
                nowIndicator
                dateClick={(e) => props.newCasual(e.dateStr)}
                eventClick={(e) => handleEventClick(e.event)}
                // eventClick={(info) => {
                //     alert('Event: ' + info.event.title);
                //     alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                //     alert('View: ' + info.view.type);
                
                //     // change the border color just for fun
                //     info.el.style.borderColor = 'red';
                // }}
          />
        </div>        
    );
}

export default Calendar