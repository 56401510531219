import { React, useState } from 'react';
import { useHistory } from "react-router-dom";

import classes from './MasterData.module.css';
import axios from 'axios';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import MasterDataTable from '../../../UI/MasterDataTable/MasterDataTable';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
// import { masterDataPrimaryKeys } from '../../../utilities/globalObjects';
import { handleAwsApiError, handleBanner } from '../../../utilities/functions';
import Modal from '../../../UI/Modal/Modal';
import Banner from '../../../UI/Banner/Banner';

const MasterData = (props) => {

    // data state
    const [data, setData] = useState();
    const [selectedTable, setSelectedTable] = useState();
    
    // UI state
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [bannerText, setBannerText] = useState('');

    // react router history instance to navigate user to other pages
    let history = useHistory();    

    const getMasterData = async (tableName) => {
        setLoading(true);

        // clear any previously applied filters now we're fetching a new table
        // setColumnFilters({});
        setSelectedTable(tableName);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: tableName}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            setData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);
    }


    let table = <MasterDataTable handleBanner={(text) => handleBanner(setShowBanner, setBannerText, text)} updateAllowed insertAllowed deleteAllowed='delete' token={props.token} updateCompleted={getMasterData} setLoading={setLoading} setError={setError} data={data} table={selectedTable}/>;
    // if (data) {
        // table = <MasterDataTable submit={updateMasterData} data={data}/>;       
        
        // table = <MasterDataTable showFilter={showFilter} updateFilterValue={updateFilterValue} columnToFilter={columnToFilter} filters={columnFilterOptions} headerClicked={openFilterPane} clicked={clicked} changed={setUpdateValue} submit={updateMasterData} updateRow={updateRow} updateCol={updateCol} updateValue={updateValue} data={filteredData}/>;       
    // }
    // console.log('filtered data: ', filteredData);

    let masterDataContent = <SpinnerDark />;
    if (!loading) {
        masterDataContent = (
            <Aux>
                {table}
            </Aux>
        );
    }

    return (
        <div  className={classes.MasterData}> 
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <Banner show={showBanner}>{bannerText}</Banner>
            {/* onClick={updateRow ? clearUpdateValues : null} */}
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('groups')}>Groups</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('centres')}>Centres</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('accounts')}>Accounts</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('all_user_accounts')}>All User Accounts</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('guardians')}>Guardians</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('children')}>Children</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('children_medical')}>Medical</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('emergency_contacts')}>Emergency Contacts</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('form_approvals')}>Approvals</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('form_submissions')}>Submissions</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('documents_metadata')}>Documents</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('centre_sessions_calendar')}>Centre Calendar</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('variables')}>System Variables</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('idp_group_access')}>Group Access</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('role_access')}>Role Access</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('role_list')}>Role List</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('kiosks')}>Kiosks</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('kiosk_admins')}>Kiosk Admins</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('user_roles')}>User Roles</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('excursions')}>Excursions</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('excursion_detail')}>Excursion Detail</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('pricing_vouchers')}>Vouchers</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('pricing_rates')}>Rates</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('pricing_session_hours')}>Hours</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('pricing_ccs_rebates')}>Rebates</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('roll')}>Roll</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('roll_event_log')}>Roll events</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('session_report_validation')}>Session Report Validation</button>
            <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('pending_is_case_claim_submissions')}>Pending IS Case Claims</button>
            {/* <button className={[classes.Button,'btn', 'btn-sm', 'btn-outline-success', 'px-3'].join(' ')} onClick={() => getMasterData('pk_test')}>pk_test</button> */}
            {masterDataContent}
        </div>
    )
}

export default MasterData;
