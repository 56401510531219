import {React, useState, useEffect, useCallback } from 'react';
import { Form } from 'react-formio';
// import Table from 'react-bootstrap/Table';
import axios from 'axios';
import classes from './Approvals.module.css';
import Spinner from '../../../UI/Spinner/Spinner';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Modal from '../../../UI/Modal/Modal';
import TopDrawer from '../../../UI/TopDrawer/TopDrawer';

import { useHistory } from "react-router-dom";
import { AWS_API, formioAPI, cognitoUserpoolId, cognitoClientId } from '../../../utilities/globalVariables';
import { updateTableByFormType, masterDataPrimaryKeys, autoApprovedForms } from '../../../utilities/globalObjects';
import { scrollPageToTop, handleAwsApiError} from '../../../utilities/functions';
import { raw } from '../../../translations/en/raw';
import Banner from '../../../UI/Banner/Banner';

const Approvals = (props) => {

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showPendingForms, setShowPendingForms] = useState({});
    const [showBanner, setShowBanner] = useState(false);
    const [bannerText, setBannerText] = useState('');    
    
    // data state
    const [selectedFormObj, setSelectedFormObj] = useState();
    const [pendingFormCounts, setPendingFormCounts] = useState();
    const [pendingForms, setPendingForms] = useState();

    // react router history instance to navigate user to other pages
    let history = useHistory();
    
    // async function to get form submission data, filter to remove approved, and save form ids pending approval
    const getAllPendingForms = useCallback(async () => {
        setLoading(true);
        let allForms;
        let approvedFormIds;

        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: headers, 
            params: {table: 'form_submissions'} // can specify column and condition here, but for now let's just get all pending forms and show each type as a notification
        };

        // get all submitted form IDs that aren't "auto-approved"
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('response: ', res.data);
            allForms = res.data.filter(obj => !autoApprovedForms.includes(obj.form_type));
            console.log('allForms: ', allForms);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
            setLoading(false);
            return false;
        }     

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config2 = {
            headers: headers, 
            params: {table: 'form_approvals'}
        };

        // get all approved form_ids
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config2);
            console.log('response: ', res.data);
            approvedFormIds = res.data.map(obj => obj.form_id);
            console.log('approvedFormIds: ', approvedFormIds);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
            setLoading(false);
            return false;
        } 

        // filter list of form IDs to only those still pending approval
        const newPendingForms = allForms.filter(obj => !approvedFormIds.includes(obj.form_id));
        setPendingForms(newPendingForms);

        console.log('newPendingForms: ', newPendingForms);

        // create pending forms notification object
        const newPendingFormCounts = newPendingForms.reduce((agg, curr) => {
            if (agg[curr.form_type]) {
               agg[curr.form_type] += 1
            } else {
               agg[curr.form_type] = 1
            }
            return agg
         }, {})
         
        console.log('newPendingFormCounts: ', newPendingFormCounts);
        setPendingFormCounts(newPendingFormCounts);  
        
        // get submission json data for each ID still pending approval aosh-get-form-submissions
        // try {
        //     const res = await axios.post(AWS_API + 'aosh-get-form-submissions', {ids: pendingForms, formType: 'enrol'}, {headers: headers});
        //     console.log('response from aosh-get-form-submissions: ', res.data);
        //     setPendingSubmissions(res.data.formSubmissions);          
        // } catch (err) {
        //     setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
        // }

        setLoading(false);
    }, [props.token, history]);

    const handleBanner = (text) => {
        setShowBanner(true);
        setBannerText(text);
        setTimeout(() => {
            setShowBanner(false);
            // setBannerText('');
        }, 2000);
    }


    // call function to get form ids pending approval on component render
    useEffect(() => {
        getAllPendingForms(props.email);
    }, [getAllPendingForms, props.email]);

    // function to approve enrolment and write master data to RDS
    const postMasterData = async (submissionObject) => {
        setLoading(true);
        
        console.log('submissionObject: ', submissionObject);

        // check form type being approved and send to the appropriate lambda function to write the data
        if (submissionObject.formType === 'enrol' || submissionObject.formType === 'enrolNewChild') {
            
            // create data object for final lambda function and headers for authorising with lambda authoriser on API Gateway
            const postData = {
                submissionData: submissionObject.data,
                formId: submissionObject.formId,
                formType: submissionObject.formType
            };
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            console.log('approving enrolment, posting data: ', postData);

            try {
                const res = await axios.post(AWS_API + '/initial-master-data/write', {data: postData}, headers);
                console.log('postMasterData response: ', res.data);
                
                // update pending forms and recalculate notifications etc
                getAllPendingForms(); // this will trigger loading again, so don't setLoading = false in this path
                setSelectedFormObj();
                handleBanner('Enrolment Approved');
                // alert('enrolment approved!');
    
            } catch (err) {
                setError(handleAwsApiError(err, history) ?? 'Error while writing master data, transaction aborted');
                setLoading(false);
            }

        } else {
            // if it's not a new enrolemnt or new child being enrolled, send to generic update master data to update specified table and mark form as approved
            
            // attempt to get primary keys for selected table to use in SQL update statement, throw error if keys haven't been added for given table
            const formTableMap = updateTableByFormType[submissionObject.formType];
            const selectedTable = formTableMap.table;
            const primaryKeys = masterDataPrimaryKeys[selectedTable]; // ['account_id']  or ['child_id', 'guardian_id']
            if (!primaryKeys) {
                setError('Primary keys not found for selected table: ' + selectedTable);
                setLoading(false);
                return false
            }

            console.log('primaryKeys: ', primaryKeys);

            // ------------------------------------------------------------------
            // section currently hardcoded to work for updateBookingSchedule form, if other form requests are added for approval here need to make this dynamic

            // get permanent booking data to look for currently open schedules for these children, in order to update end_dates
            
            // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
            const innerHeaders = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
            const headers = {headers: innerHeaders};
            const config = {
                headers: innerHeaders, 
                params: {table: 'permanent_bookings'}
            };
            
            // call lambda function to get master data
            let permBookingData;
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                permBookingData = res.data;
            } catch (err) {
                setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching permanent booking data');
            }     
            console.log('permBookingData: ', permBookingData);
            
            
            // iterate through each child included in schedule change request
            const numChildren = submissionObject.data.childIds.length;
            let childCounter = 0;
            for (const childId of submissionObject.data.childIds) {
                childCounter += 1;
                const centreId = submissionObject.data.centreId;
                // iterate through sessions needing updating for each child
                const sessionsToUpdate = ['bsc', 'asc'];
                for (let session of sessionsToUpdate) {
                    // const pkValues = [childId, submissionObject.data.centreId, session];
                    
                    // convert date from dd/mm/yyyy to yyyy-mm-dd 
                    const dateParts = submissionObject.data.startDate.split("/");
                    const startDateSQLFormat = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];                    
                    
                    // first update existing schedule end-date to day before new schedule's start date

                    // find any open bookings for given child/session/centre combo
                    const openAndFutureBookings = permBookingData.filter(obj => obj.child_id === childId && obj.centre_id === centreId && obj.session === session && (!obj.end_date || new Date(obj.end_date).getTime() >= new Date().getTime()));
                    const futureBookings = openAndFutureBookings.filter(obj => new Date(obj.start_date).getTime() > new Date().getTime());
                    const openBookings = openAndFutureBookings.filter(obj => new Date(obj.start_date).getTime() <= new Date().getTime());
                    console.log('openBookings: ', openBookings);
                    console.log('futureBookings: ', futureBookings);


                    // set up end date in SQL format
                    const endDate = new Date(startDateSQLFormat);
                    endDate.setDate(endDate.getDate() - 1);
                    const endDateSQLFormat = endDate.toISOString();

                    // create date string for today to update last_updated field
                    const lastUpdated = new Date().toISOString();

                    // close each row of permanent bookings for this child/centre/session combo where end_date is null or in the future
                    for (let i = 0; i < openBookings.length;  i++) {
                        const booking = openBookings[i];
                        const startDateOfRowToClose = booking.start_date;
                        console.log('updating open booking with start date: ', startDateOfRowToClose);
                        
                        const updateEndDate = {
                            end_date: endDateSQLFormat,
                            last_updated: lastUpdated 
                        };

                        // create object of primary keys and values for each needed to update table
                        const primaryKeysObj = {
                            child_id: childId,
                            centre_id: centreId,
                            session: session,
                            start_date: startDateOfRowToClose
                        };  
        
                        let postData = {
                            table: selectedTable,
                            primaryKeysObj: primaryKeysObj,
                            updateObj: updateEndDate
                        };

                        // update end_date for schedule being changed
                        try {
                            const res = await axios.post(AWS_API + 'master-data/update', {postData: postData}, headers);
                            console.log('postMasterData response: ', res.data);
                            console.log('end date updated for ' + submissionObject.formType + ' to: ' + endDateSQLFormat);
                            
                        } catch (err) {
                            setError(handleAwsApiError(err, history) ?? 'Error encountered while attempting to approve form');
                            setLoading(false);
                        }                      
                    }

                    // for new booking request, check if we're updating an existing future booking or creating a new one in the case where no future booking exists
                    let futureStartDate;
                    if (futureBookings.length > 0) futureStartDate = futureBookings[0].start_date;
                    console.log('futureStartDate: ', futureStartDate);

                    // create object of primary keys and values for each needed to update table
                    const primaryKeysObj = {
                        child_id: childId,
                        centre_id: centreId,
                        session: session,
                        start_date: futureStartDate ?? startDateSQLFormat // if there's a start_date in the future already, update that schedule and set the start date to the new requested start date, 
                    };                                                          // otherwise insert row for requested start date
                    
                    
                    // get correct column mapping based on whether fortnightly or weekly data given
                    let schedule = 'weekly';
                    if (submissionObject.data.fortnightly) {
                        schedule = 'fortnightly';
                    }
                    console.log('schedule: ', schedule);
                    
                    // create object of columns and values to update
                    const updateObj = {};
                    formTableMap.updateCols.forEach((col, index) => {
                        const name = formTableMap.updateValueName[schedule][index];
                        updateObj[col] =  submissionObject.data[schedule][name][session]
                    });
                    
                    // set end date to null, in case this is updating the schedule of a previously closed row that had the same start date
                    updateObj['last_updated'] = lastUpdated;
                    updateObj['end_date'] = null;
                    
                    // if we're updating an existing future schedule request, add in the new startdate to updateObj
                    if (futureStartDate) {
                        updateObj['start_date'] = startDateSQLFormat;
                    }
                    
                    console.log('updateObj: ', updateObj);
                    console.log('primaryKeysObj: ', primaryKeysObj);

                    let postData = {
                        table: selectedTable,
                        primaryKeysObj: primaryKeysObj,
                        updateObj: updateObj
                    };
    
                    // on second of two iterations add in request to approve form
                    if (session === 'asc' && childCounter === numChildren) {
                        postData = {
                            ...postData,
                            approveForm: {
                                formId: submissionObject.formId,
                                formType: submissionObject.formType,
                                homeCentreId: submissionObject.data.centreId,
                                childId: childId,
                            }
                        };
                    }
                        
                    console.log('approving form, posting this to updateMasterData: ', postData);
        
                    // // call lambda function to update perm booking schedule in master data
                    try {
                        const res = await axios.post(AWS_API + 'master-data/update', {postData: postData}, headers);
                        console.log('postMasterData response: ', res.data);
                        console.log(submissionObject.formType + ' form for ' + session + ' session approved!');
                        handleBanner('Form Approved');
                    } catch (err) {
                        setError(handleAwsApiError(err, history) ?? 'Error encountered while attempting to approve form');
                        setLoading(false);
                    }             
                }
            }

            // update pending forms and recalculate notifications etc
            
            getAllPendingForms(); // this will trigger loading again, so don't setLoading = false in this path
            setSelectedFormObj();



        }

    }

    // function to intercept form submission data and save to S3 when submit button pressed and CustomSubmit event fired - used for admin to make overwrites to user submitted form
    const updateFormSubmission = async (submission) => {
        setLoading(true);
        console.log('updateFormSubmission input param: ', submission);

        // get old submission object to take ID for form submission updating
        // const oldSubmissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};

        // create new object with correct ID and new submission data
        const newSubmissionObject = {
            ...selectedFormObj,
            data: submission
        };

        console.log('new submission object: ', newSubmissionObject);
        
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        try {
            const res = await axios.post(AWS_API + 'form-submissions/update', {postData: newSubmissionObject}, headers);
            console.log('response from postSubmission to S3 via form-submissions/udpate: ', res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error while updating form submission');
        }
        
        getAllPendingForms();
        setLoading(false);
    }

    // console.log('selected form is: ', selectedForm);

    // function to get submission json data from S3 for selected form
    const getFormSubmission = async (formId, formType, submittedBy) => {
        console.log('get form submission for id, type, submittedBy: ', formId, formType, submittedBy);
        
        setLoading(true);

        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};

        const postData = {ids: [formId], formType: formType, submittedBy: submittedBy};

        // get submission json data for selected form ID
        try {
            const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
            console.log('response from form-submissions/fetch: ', res.data);
            setSelectedFormObj(res.data.formSubmissions[0]);        
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
        }
        setLoading(false);

    }

    console.log('selectedFormObj: ', selectedFormObj);
    console.log('pendingForms: ', pendingForms);


    // effect to check centre remaining spots for start day of requested permanent booking schedule in selected form
    // useEffect(() => {
    //     if (selectedFormObj && !loading) {

    //         // get form data for selected form
    //         const formData = selectedFormObj;
    //         console.log('formData: ', formData);
    //         const permBookingRequest = formData.data.child.permanentBookings;
    //         const centreId = formData.data.child.centre;
            
    //         // get date for next monday to show capacity to admin user, and format it to yyyy-mm-dd as required by lambda fn
    //         let date = new Date();
    //         date.setDate(date.getDate() + (((1 + 7 - date.getDay()) % 7) || 7));
    //         const dateString = date.toLocaleDateString('sv-SW');
    //         // console.log('dateString: ', dateString); 
    //         // const dateParts = dateString.split("/");
    //         // const startDateSQLFormat = +dateParts[2] + "-" + dateParts[1] + "-" + +dateParts[0];            
    //         console.log('date, centre: ', dateString, centreId);
    //         console.log('perm: ', permBookingRequest);

    //         // get remaining spots
    //         const config = {
    //             headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //             params: {date: dateString, centreId: centreId}
    //         };   

    //         // make call to lambda function to calculate remaining spaces for given day and centre
    //         axios.get(AWS_API + 'remaining-centre-spaces', config)
    //         .then(res => {
    //             const data = res.data;
    //             console.log('Remaining spaces in selected centre next Monday: ', data);
                
    //         })
    //         .catch(err => {
    //             setError(handleAwsApiError(err, history) ?? 'Error while trying to calculate remaining center spaces');
    //         });
            
            
    //     }
    // }, [selectedFormObj, pendingForms, loading, history, props.token]);

    // populate object in state with each type of pending form to show/hide pending form top drawers
    useEffect(() => {

        if (pendingFormCounts) {

            let newShowPendingForms = {};

            Object.keys(pendingFormCounts).forEach(key => {
                newShowPendingForms = {
                    ...newShowPendingForms,
                    [key] : false
                }
            })
            console.log('Initialising showPendingForms UI state to: ', newShowPendingForms);
            setShowPendingForms(newShowPendingForms);
        }

    }, [pendingFormCounts])


    // whenever pendingFormCounts change, update notifications for user
    let pendingFormNotifications = null;
    if (pendingFormCounts) {
        pendingFormNotifications = Object.keys(pendingFormCounts).map(key => 
        <div key={key} onClick={() => setShowPendingForms({...showPendingForms, [key]: !showPendingForms[key]})} className={classes.Notification}>
            <div className={classes.NotificationTitle}>{raw[key]}  </div>  
            <span className={classes.NotificationBadge}>{pendingFormCounts[key]}</span>
        </div>)
    }    

    // populate enrolment form with selected submission data if form has been selected
    let populatedForm = null;
    if (selectedFormObj && !loading) {
        // const submissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};
        // console.log('submissionObject: ', submissionObject);
        populatedForm = (
            <div className={classes.Form}>
                <button className='btn btn-block btn-outline-success' onClick={() => postMasterData(selectedFormObj)} >Approve Form</button>
                <hr />
                <Form 
                    src={formioAPI + selectedFormObj.formType} 
                    submission={selectedFormObj}
                    onNextTab={scrollPageToTop}
                    onCustomSubmit={updateFormSubmission} 
                />
                <p style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}}>Note: This submit button will update the original form submission - Intended use is to correct any mistakes in user's form</p>
            </div>
        );
    }

    // for each type of pending form, create a collapsable top drawer with details inside
    let topDrawers;
    if (pendingForms && pendingFormCounts) {
        topDrawers = Object.keys(pendingFormCounts).map(key => { 
            
            // get details for each form in current form type
            const rows = pendingForms.filter(obj => obj.form_type === key).sort((El1, El2) => {return El1.form_id - El2.form_id}).map((obj, index) => {
                return (
                    <tr key={index}>
                        <td>{obj.form_id}</td>
                        <td>{obj.child_id}</td>
                        <td>{obj.centre_id}</td>
                        <td>{obj.submitted_by}</td>
                        <td>{new Date(obj.submitted_at).toLocaleDateString()}</td>
                        <td><span className={classes.Link} onClick={() => getFormSubmission(obj.form_id, obj.form_type, obj.submitted_by)}>View</span></td>
                    </tr>
                ); 
            });            

            return (
                <TopDrawer key={key} title={key} open={showPendingForms[key]} toggle={() => setShowPendingForms({...showPendingForms, [key]: !showPendingForms[key]})}> 
                    <table className="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                            <th>Form ID</th>
                            <th>Child ID</th>
                            <th>Centre ID</th>
                            <th>Submitted By</th>
                            <th>Submitted</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}  
                        </tbody>
                    </table>
                </TopDrawer>
            );
        });
    }

    let approvalsContent = <div style={{paddingTop: '15vh'}}><Spinner /></div>
    if (!loading) {
        approvalsContent = (
            <Aux>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>
                <Banner show={showBanner}>{bannerText}</Banner>
                
                <div className={classes.Wrapper}>
                    {/* Left nav bar */}
                    <div className={classes.LeftBar}>
                        <h6>Notifications</h6>
                        <hr />
                        {pendingFormNotifications && pendingFormNotifications.length>0 ? pendingFormNotifications : <i>nothing to approve</i>}
                    </div>

                    {/* Form.io form */}
                    <div className={classes.Content} >
                        {topDrawers}
                        {populatedForm}                      
                    </div>

                    {/* Empty right nav bar */}
                    <div className={classes.RightBar}></div>

                </div>
            </Aux>
        ); 
    }

    return approvalsContent
}

export default Approvals;