import { React, useEffect, useState } from 'react';
import axios from 'axios';
import classes from './SessionSignIns.module.css';
import { AWS_API, cognitoClientId, cognitoUserpoolId, public_s3_url } from '../../../utilities/globalVariables';
import { handlePrompt } from '../../../utilities/functions';
// import { useHistory } from "react-router-dom";
// import Modal from '../../../UI/Modal/Modal';
import noPhotos from '../../../assets/images/no-photos.png';
import dietary from '../../../assets/images/dietary.png';
import centreIcon from '../../../assets/images/centre-icon.png';
import busIcon from '../../../assets/images/bus-icon.png';
import med from '../../../assets/images/med.png';
import { bscSessionLength, bscStartTime } from '../../../utilities/globalVariables';
import Modal from '../../../UI/Modal/Modal';
import SmallSpinnerDark from '../../../UI/SmallSpinnerDark/SmallSpinnerDark';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
// import { handleAwsApiError } from '../../../utilities/functions';
import { raw } from '../../../translations/en/raw';
import Prompt from '../../../UI/Prompt/Prompt';
import Drills from '../../Admin/Roll/Drills/Drills';


const SessionSignIns = (props) => {

    // data state
    const [updateRollIds, setUpdateRollIds] = useState([]);
    const [childDetail, setChildDetail] = useState();
    const [displayedRollIds, setDisplayedRollIds] = useState([]);
    const [photos, setPhotos] = useState({});

    // UI state
    const [showChildDetail, setShowChildDetail] = useState(false);
    const [prompt, setPrompt] = useState({});
    const [showDrillForm, setShowDrillForm] = useState();

    // filtering state
    const [displaySessionOptions, setDisplaySessionOptions] = useState([]);
    const [displayCentreOptions, setDisplayCentreOptions] = useState([]);
    const [displaySession, setDisplaySession] = useState();
    const [displayCentre, setDisplayCentre] = useState();
    const [displayLocation, setDisplayLocation] = useState();
    const [displayLocationOptions, setDisplayLocationOptions] = useState([]);
    const [displaySchool, setDisplaySchool] = useState();
    const [displaySchoolOptions, setDisplaySchoolOptions] = useState([]);

    // ref data state
    const [schoolNameData, setSchoolNameData] = useState();

    // hoc state
    const [photosLoading, setPhotosLoading] = useState(false);
    // const [rollMarkingLoading, setRollMarkingLoading] = useState(false);

    // datetime state
    const [currentDate, setCurrentDate] = useState(new Date());



    // create react-router history object to navigate user to other pages
    // const history = useHistory();    

    // console logs
    // console.log('updateRollIds: ', updateRollIds);
    // console.log('upcoming sessions: ', props.upcomingSessions);
    // console.log('displaySession: ', displaySession);
    // console.log('displayCentre: ', displayCentre);
    // console.log('displaySchool: ', displaySchool);
    // console.log('schoolname data: ', schoolNameData);
    // 
    // get signed in user ID from passed in user data if component is being rendered in kiosk, otherwise don't pass this param to update roll and function will just use signed in user ID from context
    let signedInUserId;
    if (props.userData) {
        signedInUserId = props.userData.person_id;
    }

    // get school id - name mappings from public s3 bucket
    useEffect(() => {
        const fetchSchoolNameData = async (ids) => {
            try {
                let schoolLookupObj = {};
                const res = await axios.get(public_s3_url + 'schools.json');
                // console.log('res: ', res.data);
                const filteredSchools = res.data.filter(obj => ids.includes(+obj.ACARAId))
                // console.log('filteredSchools: ', filteredSchools);
                filteredSchools.forEach(obj => schoolLookupObj[obj.ACARAId] =  obj.schoolName);
                setSchoolNameData(schoolLookupObj);
            } catch (err) {
                console.log('error while fetching school names: ', err);
            }           
        }

        if (props.upcomingSessions) {
            const schoolIds = props.upcomingSessions.map(obj => obj.school_child_attends).filter((x, i, a) => a.indexOf(x) === i);
            fetchSchoolNameData(schoolIds);
        }

    }, [props.upcomingSessions])

    useEffect(() => {
        // get current date and time to only show sessions happening in near future
        const today = new Date();
        const hourNow = today.getHours();
        setCurrentDate(today);
        // console.log('hourNow: ', hourNow);

        // create array of sessions happening today
        const sessionOptions = props.upcomingSessions.map(obj => obj.session).filter((v, i, a) => a.indexOf(v) === i);
        // console.log('sessionOptions: ', sessionOptions);
        setDisplaySessionOptions(sessionOptions);

        // create array of locations
        const locationOptions = props.upcomingSessions.map(obj => obj.location_type).filter((v, i, a) => a.indexOf(v) === i);
        // console.log('locationOptions: ', locationOptions);
        setDisplayLocationOptions(locationOptions);
        setDisplayLocation('centre');
        
        // create array of schools
        const schoolOptions = props.upcomingSessions.map(obj => obj.school_child_attends).filter((v, i, a) => a.indexOf(v) === i);
        schoolOptions.unshift('All Schools');
        // console.log('schoolOptions: ', schoolOptions);
        setDisplaySchoolOptions(schoolOptions);
        setDisplaySchool(schoolOptions[0]);

        
        const initialDisplayOptions = sessionOptions.filter(el => {
            console.log('bsc cuttoff: ', bscSessionLength + bscStartTime);
            if (hourNow <= bscStartTime + bscSessionLength) {
                return el === 'bsc' || el === 'vac'
            } else {
                return el === 'asc' || el === 'vac'
            }        
        });
        // console.log('initial display options: ', initialDisplayOptions);
        setDisplaySession(initialDisplayOptions[0]);
        
        
    }, [props.upcomingSessions])

    // set display options which depend on other display options
    useEffect(() => {


        // create array of centres (for when component being used by admin for multiple centres)
        const centreOptions = props.upcomingSessions.filter(obj => obj.location_type === displayLocation).map(obj => obj.centre_id).filter((v, i, a) => a.indexOf(v) === i).sort((a,b) => a-b);
        // console.log('centreOptions: ', centreOptions);
        setDisplayCentreOptions(centreOptions);
        setDisplayCentre(centreOptions[0]);
        
        
    }, [props.upcomingSessions, displayLocation])    


    

    const handleUpdateRollIds = (rollId) => {
        // if ID already in array, remove it, otherwise add it
        if (updateRollIds.includes(rollId)) {
            const index = updateRollIds.indexOf(rollId);
            const newRollIds = [...updateRollIds];
            newRollIds.splice(index, 1);
            setUpdateRollIds(newRollIds);
        } else {
            setUpdateRollIds([...updateRollIds, rollId]);
        }
    }

    // function to handle submission of check-in/check-out
    const handleSubmit = (updateRollEntries, signedInUserId, updateType) => {
        // first check there are no outstanding permission slips
        const rollIdsMissingSlip = props.upcomingSessions.filter(obj => obj.permission_slip_outstanding).map(obj => obj.id);
        console.log('rollIdsMissingSlip: ', rollIdsMissingSlip);

        const entriesMissingSlip = updateRollEntries.filter(obj => rollIdsMissingSlip.includes(obj.id));
        if (entriesMissingSlip.length > 0) {
            alert('Permission slip missing for: ' + entriesMissingSlip.map(obj => props.upcomingSessions.filter(session => session.id === obj.id)[0].first_name));
        }
        console.log('updateRollEntries: ', updateRollEntries);
        console.log('signedInUserId: ', signedInUserId);
        console.log('updateType: ', updateType);


        props.updateRoll(updateRollEntries, signedInUserId, updateType);
    }

    const handleChildDetail = (rollId, details) => {
        setShowChildDetail(true);
        const rollEntry = props.upcomingSessions.filter(obj => obj.id === rollId)[0];
        // console.log('rollEntry: ', rollEntry);
        setChildDetail(
            <div>
                {details.map(el => {
                    // console.log('el: ', el);
                    // console.log('roll e of el: ', rollEntry[el])
                    return (
                        <div key={el} className={classes.DetailRow} >
                            <span className={classes.DetailKey}>{el}: &nbsp;</span>
                            <span>{rollEntry[el]?.toString()}</span>
                        </div>

                    );

                })}
            </div>
        )
    }

    // if a roll entry has been selected, create the object to pass to the backend with all roll information necessary to control access
    let updateRollEntries;
    if (updateRollIds) {
        const updateRollEntriesAllFields = props.upcomingSessions.filter(obj => updateRollIds.includes(obj.id));
        updateRollEntries = updateRollEntriesAllFields.map(({id, centre_id, child_id}) => ({id, centre_id, child_id}))
        // console.log('updateRollEntries: ', updateRollEntries);
    }

    const handleNewDrill = () => {
        if (updateRollIds?.length > 0) {
            setShowDrillForm(true)
        } else {
            alert('No children selected');
        }
    }

    
    // useEffect to determine childIds to display for roll sessions matching today, selected centre and selected session
    useEffect(() => {

        // get current date and time to only show sessions happening in near future
        const today = currentDate;
        // const hourNow = today.getHours();
        // console.log('hourNow: ', hourNow);

        let newDisplayedRollEntries = props.upcomingSessions
        .filter(obj => obj.session === displaySession && +obj.centre_id === +displayCentre && obj.location_type === displayLocation)
        .filter(obj => {
            // get date to only show sessions happening today
            const date = new Date(obj.date);

            return (
                date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
            );

        })

        // if we're on bus view, filter children to those at selected school
        if (displayLocation === 'bus') {
            newDisplayedRollEntries = newDisplayedRollEntries.filter(obj => obj.school_child_attends === +displaySchool || displaySchool === 'All Schools');
        }

        const newDisplayedRollIds = newDisplayedRollEntries.map(obj => obj.id);

        // console.log('newDisplayedRollIds: ', newDisplayedRollIds);
        setDisplayedRollIds(newDisplayedRollIds);

        
    }, [props.upcomingSessions, displayCentre, displaySession, displayLocation, displaySchool, currentDate]);


    // useEffect to fetch child photos for roll items displayed
    useEffect(() => {

        
        const getPhotos = async (childIds) => {
       


            // upcoming sessions data
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            const postData = {childId: childIds};
            try {
                const res = await axios.post(AWS_API + 'form-submissions/fetch-photos', {postData: postData}, headers);
                // setUpcomingSessions(res.data);
                const newPhotos = res.data;
                console.log('res: ', newPhotos);
                
                // if there are any new children to add, update photos in state
                const existingIds = Object.keys(photos);
                const newIds = Object.keys(newPhotos);
                const difference = newIds.filter(id => !existingIds.includes(id));
                console.log('new photos fetched not already in photos for: ', difference);
                if (difference.length > 0) {
                    setPhotos({...photos, ...newPhotos});
                }
                // setLoading(false);
            } catch (err) {
                // setLoading(false);
                // setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
                console.log('error whilst fetching photos: ', err);
            }   
            
            setPhotosLoading(false);
            
      
        }

        // get child Ids for whom we need photos and call function
        if (props.upcomingSessions) {
            const childIds = props.upcomingSessions.filter(obj => displayedRollIds.includes(obj.id)).map(obj => obj.child_id).filter((x, i, a) => a.indexOf(x) === i);
            
            // if photos already in state, check if we are missing a photo for any children on roll and only request those ones
            let photoMissingIds = [];
            if (photos) {
                const photosLoadedIds = Object.keys(photos);
                console.log('photos loaded child Ids: ', photosLoadedIds);
    
                // check if we already have photos for all children, otherwise fetch new ones needed
                photoMissingIds = childIds.filter(id => !photosLoadedIds.includes(id.toString()));
                console.log('photoMissingIds: ', photoMissingIds);

            } else {
                // no photos in state, so request photo for all children on roll
                photoMissingIds = childIds;
            }


            if (photoMissingIds.length > 0) {
                setPhotosLoading(true);
                console.log('attempting to fetch photos for ids: ', photoMissingIds);
                getPhotos(photoMissingIds);

            }

        }
        
        
    }, [displayedRollIds, props.token, props.upcomingSessions, photos]);




    // console.log('photos: ', photos);

    // generate JSX for roll sessions matching today, selected centre and selected session
    let rollMarkedStatus;
    const rows = props.upcomingSessions.filter(obj => displayedRollIds.includes(obj.id))
    .sort((a,b) => {
        // console.log('a: ', a);
        // console.log('b: ', b);
        if (a.status === 'absent' && b.status !== 'absent') {return 1}
        if (a.status !== 'absent' && b.status === 'absent') {return -1}
        else {return 0}
    })
    .map((obj, index) => {

        // get roll marked status from one of filtered rows (they should all have the same status as only one session can be displayed at once)
        rollMarkedStatus = obj.roll_marked_status

        // create child photo img tag if we have one
        let childPhoto = <img alt="missing" src={noPhotos} style={{width: '15px'}}/>
        if (photos && photos[obj.child_id]) {
            childPhoto = <img alt="child's face" src={'data:image/jpg;base64,' + photos[obj.child_id]} style={{width: '50px', borderRadius: '50%'}}/>;

        }

        // add class based on checked in status
        let classList = [classes.Row];
        
        if (obj.checked_in && obj.checked_out) {
            classList.push(classes.RowCheckedOut);
        } else if (obj.checked_in) {
            classList.push(classes.RowCheckedIn);
        } else if (obj.status === 'absent') {
            classList.push(classes.RowAbsent);
        }

        // add selected class if selected
        if (updateRollIds.includes(obj.id)) {
            classList.push(classes.SelectedRow);
        } 

        // set default values to show which may be overridden if roll isn't yet marked
        let checkedInTD = obj.checked_in ? new Date(obj.checked_in_at).toLocaleTimeString(): null;
        let checkedOutTD = obj.checked_out ? new Date(obj.checked_out_at).toLocaleTimeString(): null;
        let rollStatus = obj.status;
        
        // limit parent view until roll is marked
        if (props.parentView && !obj.roll_marked_status) {
            checkedInTD = 'Pending'; 
            checkedOutTD = 'Pending';
            rollStatus = 'Pending'
            classList = classList.filter(el => ![classes.RowCheckedIn, classes.RowCheckedOut, classes.RowAbsent].includes(el));
        } 

    

        return (
            <tr className={classList.join(' ')} key={index}>
                <td className={classes.TableItem} onClick={() => handleUpdateRollIds(obj.id)}>{photosLoading ? <SmallSpinnerDark /> : childPhoto}</td>
                <td className={classes.TableItem}>{new Date(obj.date).toLocaleDateString()}</td>
                <td onClick={() => handleChildDetail(obj.id, ['guardian_first_name', 'guardian_surname', 'preferred_contact'])} className={classes.TableItem}>{obj.first_name}</td>
                {/* <td className={classes.TableItem}>{obj.centre_id}</td> */}
                {/* <td className={classes.TableItem}>{obj.session}</td> */}
                {/* <td className={classes.TableItem}>{obj.checked_in ? <div className={classes.CheckInBadge}>{new Date(obj.checked_in_at).toLocaleTimeString()}</div>: null}</td> */}
                {/* <td className={classes.TableItem}>{obj.checked_out ? <div className={classes.CheckInBadge}>{new Date(obj.checked_out_at).toLocaleTimeString()}</div>: null}</td> */}
                <td className={classes.TableItem}>{checkedInTD}</td>
                <td className={classes.TableItem}>{checkedOutTD}</td>
                <td className={classes.TableItem}>{rollStatus}</td>
                <td onClick={() => handleChildDetail(obj.id, ['photo_permissions_internal', 'photo_permissions_external'])} className={classes.TableItem}>{(!obj.photo_permissions_internal || !obj.photo_permissions_external) ? <img alt="no-photos" src={noPhotos} style={{width: '15px'}}/> : null}</td>
                {/* onClick={handleChildDetail() => ('')} <tbjd className={classes.TableItem}>{!obj.photo_permissions_external ? <img alt="no-photos" src={noPhotos} style={{width: '15px'}}/> : null}</td> */}
                <td onClick={() => handleChildDetail(obj.id, ['dietary_restrictions_detail'])} className={classes.TableItem}>{obj.dietary_restrictions ? <img alt="dietary-restrictions" src={dietary} style={{width: '15px'}}/> : null}</td>
                <td onClick={() => handleChildDetail(obj.id, ['diagnosed_condition_detail'])} className={classes.TableItem}>{obj.diagnosed_condition ? <img alt="medical-condition" src={med} style={{width: '15px'}}/> : null}</td>
            </tr>
            );        
    });
    

    let centreSelect;
    if (props.multipleCentres) {
        centreSelect = (
            <div className={classes.RollSelect}>
                <span>Centre</span>
                <select value={displayCentre} onChange={(e) => setDisplayCentre(e.target.value)} className='form-control'>
                    {displayCentreOptions.map(id => <option key={id} value={id}>{props.upcomingSessions.filter(obj => obj.centre_id === id)[0].centre_name}</option>)}
                </select>

            </div>

        );
    }

    console.log('prompt: ', prompt);
  
    return (
        <div className={classes.SessionSignIns} >

            <Modal show={showChildDetail} modalClosed={() => setShowChildDetail(false)}>
                <h3>Child Summary Info</h3>
                <hr/>
                {childDetail}
            </Modal>   
            <Modal show={showDrillForm} modalClosed={() => setShowDrillForm(false)}>
                <Drills submitFn={props.submitRollEvent} rollEntries={updateRollEntries} />
            </Modal>              
            <Prompt data={prompt} setData={setPrompt} />
            <div className={classes.Title}>
                <div className={classes.TitleInfo}>
                    <h4 className={classes.TitleText}>{currentDate.toDateString("en-GB", {timeZone: 'Australia/Sydney'})}</h4>
                    {props.adminFlag && 
                    <button 
                    disabled={rollMarkedStatus === 'marked' ? true : false} 
                    onClick={() => props.submitRollAsMarked(currentDate.toLocaleDateString("sv-SW", {timeZone: 'Australia/Sydney'}), displaySession, displayCentre, 'marked')} 
                    className={rollMarkedStatus === 'marked' ? 'btn btn-xs btn-info' : 'btn btn-xs btn-outline-info'}>
                        Roll Marked
                    </button>}
                    <img alt="location type" src={displayLocation === 'centre' ? centreIcon : busIcon} style={{height: '50px', marginRight: '5px'}} />

                </div>
                <div className={classes.TitleSelects}>
                    <div className={classes.RollSelect}>
                        <span>Location</span>
                        <select value={displayLocation} onChange={(e) => setDisplayLocation(e.target.value)} className='form-control' style={{textTransform: 'capitalize'}}>
                            {displayLocationOptions.map(option => <option key={option} value={option}>{option}</option>)}
                        </select>
                    </div>

                    {centreSelect}

                    <div className={classes.RollSelect}>
                        <span>Session</span>
                        <select  value={displaySession} onChange={(e) => setDisplaySession(e.target.value)} className='form-control'>
                            {displaySessionOptions.map(option => <option key={option} value={option}>{raw[option]}</option>)}
                        </select>
                    </div>
                </div>

                {displayLocation === 'bus' && <div className={classes.TitleSelects}>
                    <div className={classes.RollSelect}>
                        <span>School</span>
                        <select  value={displaySchool} onChange={(e) => setDisplaySchool(e.target.value)} className='form-control'>
                            {displaySchoolOptions.map(id => <option key={id} value={id}>{schoolNameData[id] ?? id}</option>)}
                        </select>
                    </div>
                </div>}
                
            </div>
            <table className={["table", classes.Table].join(" ")}>
                <thead >
                    <tr>
                        <th>Photo</th>
                        <th>Date</th>
                        <th>Child</th>
                        {/* <th>Centre</th> */}
                        {/* <th>Session</th> */}
                        <th>In</th>
                        <th>Out</th>
                        <th>Status</th>
                        <th></th>
                        {/* <th></th> */}
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows}  
                </tbody>
            </table>
            <div className={classes.CheckInButtons}>
                <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px'}}>
                    {props.checkinAllowed && <button onClick={() => handlePrompt('submit check in', () => handleSubmit(updateRollEntries, signedInUserId, 'check-in'), setPrompt)} className="btn btn-outline-success" style={{flex: 1}}>Check In</button>}
                    {props.checkinAllowed && <button onClick={() => handlePrompt('submit check out', () => handleSubmit(updateRollEntries, signedInUserId, 'check-out'), setPrompt)} className="btn btn-outline-success" style={{flex: 1}}>Check Out</button>}
                    {props.absentAllowed && <button onClick={() => handlePrompt('submit absence', () => handleSubmit(updateRollEntries, signedInUserId, 'absent'), setPrompt)} className="btn btn-outline-warning" style={{flex: 1}}>Mark Absent</button>}
                </div>
                
                <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px'}}>
                    {props.headcountAllowed && <button onClick={() => {props.getHeadcounts(displayCentre, currentDate);}} className="btn btn-outline-danger" style={{flex: 1}}>Headcounts</button>}
                    {props.headcountAllowed && <button onClick={() => handlePrompt('submit headcount', () => props.submitRollEvent(updateRollEntries, 'headcount'), setPrompt)} className="btn btn-outline-danger" style={{flex: 1}}>New Headcount</button>}
                    {props.headcountAllowed && <button onClick={handleNewDrill} className="btn btn-outline-danger" style={{flex: 1}}>New Drill</button>}
                    {/* {props.headcountAllowed && <button onClick={() => handlePrompt('test message', () => alert('proceeded!'), setPrompt)} className="btn btn-outline-danger" style={{flex: 1}}>Test</button>} */}
                </div>
                <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px'}}>
                    {props.uploadPhoto && <button onClick={() => props.uploadPhoto(updateRollEntries)} className="btn btn-outline-secondary" style={{flex: 1}}>Upload New Photo</button>}
                </div>
                
                {/* <button onClick={() => props.updateRoll('checkOut', updateRollEntries, signedInUserId)} style={{width: '49%'}} className="btn btn-outline-success">Check Out</button> */}
            </div>
            {props.logout ? <hr/> : null}
            {props.logout ? <button onClick={props.logout} className='btn btn-block btn-outline-info'>Close Session</button> : null}

        </div>
    );
}

export default SessionSignIns;