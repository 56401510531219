import { React, useState, useEffect, useCallback } from 'react';
import { scrollPageToTop, handleBannerText } from '../../../utilities/functions';
import { formioAPI, AWS_API, cognitoClientId, cognitoUserpoolId, public_s3_url } from '../../../utilities/globalVariables';
import { useHistory } from "react-router-dom";
import classes from './Enrol.module.css';
import Modal from '../../../UI/Modal/Modal';
import axios from 'axios';
import Formio from 'formiojs/Formio';
import { handleAwsApiError } from '../../../utilities/functions';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Spinner from '../../../UI/Spinner/Spinner';
import { raw } from '../../../translations/en/raw';
import Banner from '../../../UI/Banner/Banner';

// import { cognitoURL } from '../../../utilities/globalVariables';
// import { Form } from 'react-formio';

const Enrol = (props) => {

    // data state
    const [draftSubmission, setDraftSubmission] = useState();
    const [child1CRN, setChild1CRN] = useState();
    // const [sampleObject, setSampleObject] = useState();
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [showInvalidModal, setShowInvalidModal] = useState(false);
    const [missingFields, setMissingFields] = useState();
    const [formToShow, setFormToShow] = useState();
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();


    // create react-router history object to navigate user to other pages
    const history = useHistory();

    // function to call API to get data
    const fetchData = useCallback(async (tableName) => {
        
        // if token found, call lambda function to account master data
        if (props.token) {
            
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {tableName: tableName}
            };
            try {
                const res = await axios.get(AWS_API + 'account-owner-data', config);
                // console.log('response: ', res.data);
                return res.data;
            } catch (err) {
                setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
            }   
        } else {
            console.log('no token, redirecting to auth');
            history.replace('/auth');
        }
    }, [history, props.token]);    

    // effect to get enrolment submissions for signed in user to determine which form to show them and what data to pre-populate
    useEffect(() => {

        // loading true before calling data fetch functions
        setLoading(true); 
          
        // anon async function to await the data response and put into state
        (async () => {

            // look for original enrolment form submitted by signed in user recorded in RDS table form_submissions
            const foundSubmissions = await fetchData('form_submissions');
            const foundOriginalEnrolment = foundSubmissions.filter(obj => obj.form_type === 'enrol');
            
            // if an enrolment form has been found, show user enrolNewChild form instead of enrol form and pre-populate some info from their original form
            if (foundOriginalEnrolment.length > 0) {
                setFormToShow('enrolNewChild');

                const originalFormId = foundOriginalEnrolment[0].form_id;

                // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
                const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
                
                const postData = {ids: [originalFormId], formType: 'enrol', submittedBy: props.email};
                
                // get actual submission for found form_id from S3
                try {
                    const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
                    console.log('response from form-submissions/fetch: ', res.data);
                    
                    // create object with pre-filled data and remove parts that need to be filled in for the new child being enrolled
                    const prepopulatedData = {...res.data.formSubmissions[0]};
                    const newChild1CRN = res.data.formSubmissions[0].data.child.crn;
                    setChild1CRN(newChild1CRN);
                    console.log('child1CRN: ', newChild1CRN);
                    console.log('prepopulated data: ', prepopulatedData);
                    delete prepopulatedData.data.primaryGuardian.relationshipToChild;
                    delete prepopulatedData.data.primaryGuardian.livesWithChild;
                    delete prepopulatedData.data.primaryGuardian.authorisation;
                    delete prepopulatedData.data.secondaryGuardian.relationshipToChild;
                    delete prepopulatedData.data.secondaryGuardian.authorisation;
                    delete prepopulatedData.data.child;
                    delete prepopulatedData.data.confirmedTCs;
                    delete prepopulatedData.data.medical;
                    delete prepopulatedData.data.documents;
                    delete prepopulatedData.data.other.courtOrders1;
                    delete prepopulatedData.data.other.courtOrders2;
                    delete prepopulatedData.data.other.immunisationsUpToDate;
                    delete prepopulatedData.data.other.photoPermissionsExternal;
                    delete prepopulatedData.data.other.photoPermissionsInternal;
                    delete prepopulatedData.data.other.groupAuthTransport;
                    delete prepopulatedData.data.other.groupAuthEvac;
                    delete prepopulatedData.data.signature;
                    console.log('prepopulated data after deletion: ', prepopulatedData);
                    setDraftSubmission(prepopulatedData);        
                } catch (err) {
                    setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
                }                


            } else {
                setFormToShow('enrol');
            }
            setLoading(false);
        })();

        // upon useEffect completion, set loading to false
        return () => {
            setLoading(false);
        }    

    }, [fetchData, history, props.token, props.email]);    

    // console.log('draftSubmission: ', draftSubmission);


    // function to intercept submission and send data to S3 instead of form.io servers
    const postSubmission = useCallback(async (submission) => {
        setLoading(true);

        // if user is enrolling an additional child, check that CRN isn't the same as the first child enrolled's CRN
        const newChildCRN = submission.child.crn;
        if (newChildCRN === child1CRN) {
            setError("New child's CRN cannot be the same as another child in your account");
            console.log('error thrown for CRN');
            setLoading(false);
            setDraftSubmission({data: {...submission}});
        } else {
            
            // first record this submission in RDS, generate ID and use this to save in S3
            // let formId = null;
            console.log('posting submission: ', submission);
            
            // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            const postData = {submission: submission, formType: formToShow, centreId: submission.child.centre};
            
            try {
                const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
                console.log('response from postSubmission to S3: ', res.data);
                // formId = res.data.formWrittenId;
            } catch (err) {
                setLoading(false);
                setDraftSubmission(draftSubmission);
                setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while recording form submission, transaction aborted');
                return false;
            }

            // show success banner and then redirec to accountOwner home
            handleBannerText(setBannerText, 'Enrolment Submitted');
            setLoading(false);
            setTimeout(() => {
                history.replace('/account');                
            }, 2000);
    
        }

    }, [history, props.token, formToShow, child1CRN, draftSubmission]);


    // render form.io form using SDK on component render or submission change and attach listeners to deal with custom submission
    useEffect(() => {

        setLoading(true);

        if (formToShow) {
            Formio.createForm(document.getElementById('formio'), formioAPI + formToShow )
            .then((form) => {
        
                
                // if a draft has been restored, populate form with draft submission
                if (draftSubmission || formToShow === 'enrolNewChild') {
                    form.ready.then(() => form.setSubmission(draftSubmission));
                    console.log('inserted draft submission into form');
                    
                    // if no draft submission, set form submission with users email
                } else {
                    form.ready.then(() => form.setSubmission({data: {primaryGuardian: {email: props.email}}}));
                    console.log('Set blank form with email of user');
                }
        
                // function to handle custom submission, check for validity and either warn user or submit form
                const customSubmit = (submission) => {
                    const isValid = form.checkValidity(null, true, null, false);
                    console.log('isValid: ', isValid);  
                    
                    // if form is invalid, figure out which fields haven't been touched and alert user, otherwise submit form
                    if (!isValid) {
                        form.setPristine(false);
                        
                        // initalise array to add empty fields if form fails validation
                        const newMissingFields = [];
    
                        // iterate through components and check any that are visible, required and have no value given - these have likely been missed by user
                        form.everyComponent((component) => {
                            const required = component.component.validate.required;
                            const value = component['_data'][component.component.key];
                            const visible = component['_visible'];
                            const valueType = typeof value;
                            let valueConstruct
                            if (valueType === 'object') {valueConstruct = value.constructor}

                            const path = component.path;
                            const label = component.component.label;
                            const parentLabel = component.parent.label;
                            const parentTitle = component.parent.component.title;
                            const tab = component.tab;                            

                            if (component.component.label.toLowerCase().includes('practice address')) {
                                console.log('component including address: ', component);
                                console.log('label: ', label);
                                console.log('visible: ', visible);
                                console.log('required: ', required);
                                console.log('value: ', value);
                                console.log('valueType: ', valueType);
                                console.log('valueConstruct: ', valueConstruct);                                
                                console.log('object keys len: ', Object.keys(value).length);                                
                            }
                                // console.log('every comp: ', component);
                                
                            if (required && visible && (!value || (valueConstruct === Object && Object.keys(value).length === 0))) {
                                

                                
                                // construct display label with best info we've got 
                                let displayLabel = path;
                                if (tab) {
                                    displayLabel = component.parent.component.components[tab].label;
                                } else if (parentTitle) {
                                    displayLabel = parentTitle
                                } else if (parentLabel) {
                                    displayLabel = parentLabel;
                                } 
                                
                                console.log('missing value for: ', displayLabel);
                                console.log('component: ', component);
                                console.log('tab: ', tab);
                                console.log('parentLabel: ', parentLabel);
                                console.log('parentTitle: ', parentTitle);
                                
                                newMissingFields.push(displayLabel + ' - ' + label);
                            }
                        });
                        
                        // set state to show empty fields to user in modal 
                        if (newMissingFields.length <= 0) newMissingFields.push("Apologies, we can't find the missing field - please look through the form for the red error message");
                        setMissingFields(newMissingFields);
                        setShowInvalidModal(true);
                    } else {
                       
                        // form passed validation, submit
                        postSubmission(submission);
                    }
                }
        
                // add listener with custom functions
                form.on('CustomSubmit', (submission) => customSubmit(submission));
                form.on('NextTab', scrollPageToTop);
                
                // set on change handler to write changes to local storage so user can restore from saved drafts based on which form they're filling out
                const draftName = (formToShow === 'enrolNewChild') ? 'draftNewChildEnrolment' : 'draftEnrolmentForm';
                form.on('initialized', () => {
                    setTimeout(() => {
                        form.on('change', (submission) =>{ 
                            localStorage.setItem(draftName, JSON.stringify({data: submission.data}));
                            console.log('changed submission: ', submission.data);
                        });
                    }, 1000);
                    
                });    
    
                // wide array of other methods that can be used here, i.e. form.everyComponent
                // full list in docs here: https://help.form.io/developers/form-renderer 
            })
        }


        // setTimeout(() => {
            setLoading(false);
        // }, 1000);

    }, [postSubmission, props.email, draftSubmission, formToShow])

    // console.log('sampleObject: ', sampleObject);

    const getSampleEnrolData = async () => {
        try {
            const res = await axios.get(public_s3_url + 'enrolTestSubmission.json');
            console.log('response from s3 reference data: ', res.data);
            const newSampleData = {
                ...res.data,
            };
            // update email to signed in user so they can use this sample data and update secondary guardian email to a random email address so RDS unique constraint isn't triggered
            newSampleData.data.primaryGuardian.email = props.email;
            newSampleData.data.secondaryGuardian.email = props.email + '.au';
            setDraftSubmission(res.data); 
            // console.log('sampleObject: ', res.data.formSubmissions[0]);         
        } catch (err) {
            console.log('error: ', err);
        }
    }


    // let startFormLoading = (
    //     <Form 
    //         // src={formioAPI + "enrol"} 
    //         src={formioAPI + "enrol"} 
    //         onNextTab={scrollPageToTop}
    //         onCustomSubmit={postSubmission}
    //         submission={sampleObject}
    //         // onChange={(submission) => console.log('submission: ', submission)}
           
    //     />
    // );

    // let form = <Spinner />;
    // if (!loading) {
    //     form = startFormLoading;
    // }


    // check for previously saved draft submission, and if it exists render a button to load it
    let restoreDraft = null;
    const draftName = (formToShow === 'enrolNewChild') ? 'draftNewChildEnrolment' : 'draftEnrolmentForm';
    if (localStorage.getItem(draftName)) {
        // const draft = JSON.parse(localStorage.getItem(draftName));
        // console.log('found draft submission: ', draft);
        restoreDraft =  <button className="btn btn-outline-info btn-block" onClick={() => setDraftSubmission(JSON.parse(localStorage.getItem(draftName)))}>Restore Draft</button>;
    }

    let displayMissingFields = null;
    if (missingFields) {
        displayMissingFields = missingFields.map(el => <li key={el}>{el}</li>);
    }


    let content = (
        <Aux >
            {/* Invalid form submission error to direct user to missing fields */}
            <Modal show={showInvalidModal} modalClosed={() => setShowInvalidModal(false)}>
                <h5>Sorry, it looks like there's something missing!</h5>
                <hr />
                    <h6>The below required fields appear to be empty:</h6>
                <div style={{textAlign: 'left'}}> 
                    <ul>{displayMissingFields}</ul>
                </div>
                    <p>If you look through the form, you should see any missing information highlighted in <span style={{color: 'red', fontWeight: 'bold'}}> red.</span></p>
            </Modal>

            {/* Error modal to show user any data call errors */}
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 

            <Banner show={bannerText}>{bannerText}</Banner>        

            <div className={classes.Wrapper}>
                {/* Left nav bar */}
                <div className={classes.LeftBar}>
                    <button className="btn btn-outline-info btn-block" onClick={getSampleEnrolData}>Insert Sample Data</button>
                    {restoreDraft}
                    < hr/>
                </div>

                {/* Form.io form */}
                <div className={classes.FormColumn} >
                    <div className={classes.FormTitle}>
                        <h6 style={{textTransform: 'capitalize'}}>Form: {raw[formToShow]}</h6>
                        {formToShow === 'enrolNewChild' && <span><i>Note - If your details are not automatically loaded into the form (i.e. First Name, Surname etc.) please refresh the page.</i></span>}
                    </div>
                    <div className={classes.Form} id="formio"></div>
                </div>

                {/* Empty right nav bar */}
                <div className={classes.RightBar}></div>

            </div>
            
       
        </Aux>
    );

    if (loading) {
        content = <div style={{paddingTop: '15vh'}}><Spinner /></div>
    }

    return content
}

export default Enrol;