import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const navigationItems = (props) => {

    let roleSpecificLinks = null;
    let infoItems = null;
    let logout = null;
  
    if (props.role === 'Head Office') {
      roleSpecificLinks = (
        <Aux>
          <NavigationItem link="/roll">Roll</NavigationItem>
          <NavigationItem link="/approvals">Approvals</NavigationItem>
          <NavigationItem link="/centreLink">CentreLink</NavigationItem>
          <NavigationItem link="/masterData">Data</NavigationItem>
          <NavigationItem link="/mgmt">Admin Mgmt</NavigationItem>
        </Aux>
      );
    } else if (props.role === 'Centre Admin') {
      roleSpecificLinks = (
        <Aux>
          {/* <NavigationItem link="/approvals">Approvals</NavigationItem> */}
          <NavigationItem link="/roll">Roll</NavigationItem>
          <NavigationItem link="/masterData">Data</NavigationItem>
          {/* <NavigationItem link="/photos">Photos</NavigationItem> */}
        </Aux>
      );    
    } else if (props.role === 'Account Owner') {
        roleSpecificLinks = (
            <Aux>
          <NavigationItem link="/account">Home</NavigationItem>
          <NavigationItem link="/enrol">Enrol</NavigationItem>
          <NavigationItem link="/checkin">Check-in</NavigationItem>
        </Aux>
      );    
    } else if (props.role === 'Kiosk') {
      roleSpecificLinks = (
        <Aux>
          <NavigationItem link="/">Home</NavigationItem>
        </Aux>
      );    
    }
  
    if (props.role) {
      infoItems = (
        <Aux>
          <NavigationItem infoOnly>{props.role}</NavigationItem>
          <NavigationItem infoOnly >{props.email}</NavigationItem>
          
        </Aux>
      );

      logout = <NavigationItem  infoOnly clicked={props.signout}>Logout</NavigationItem>;
    }

    return (
    <ul className={classes.NavigationItems}>
          {infoItems}
          {roleSpecificLinks}
          {logout}

    </ul>

    );

}

export default navigationItems;