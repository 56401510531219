import { React, useEffect, useState } from 'react';
import axios from 'axios';
import classes from './CreateKioskLogin.module.css';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
import { handleAwsApiError } from '../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../UI/Modal/Modal';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import AdminPin from '../AdminPin/AdminPin';
import SearchContacts from '../SearchContacts/SearchContacts';


const CreateKioskLogin = (props) => {


    // UI state
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [showAdminPin, setShowAdminPin] = useState(true);
    // const [showUserSignup, setShowUserSignup] = useState(false);
    
    // data state
    const [userData, setUserData] = useState();

    // create react-router history object to navigate user to other pages
    const history = useHistory();

    // console logs
    console.log('userData: ', userData);

    // on re-render, make sure admin pin is prompted for
    useEffect(() => {
        setShowAdminPin(true);
    }, [])

    // function to pass into AdminPin component to validate user-entered Kiosk admin pin server-side
    const checkPin = async (pin) => {
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            pin: pin
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/kiosk-pin-check', {postData: postData}, headers);
            console.log('response: ', res.data);
            setShowAdminPin(false);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
        } finally {
            setLoading(false);
        }   
    }


    const createUser = async (pin) => {
        console.log('creating user with userData: ', userData);
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            userData: userData,
            pin: pin
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/create-user', {postData: postData}, headers);
            console.log('response: ', res.data);
            alert('User Created!');
            setLoading(false);
            props.userCreated();
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while trying to create user');
            setLoading(false);
        }   

    }

    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Aux>
                {showAdminPin ? <AdminPin checkPin={checkPin}/> : null}
                {(!showAdminPin && !userData) ? <SearchContacts route='eligible-users' setUserData={setUserData} token={props.token}/> : null}
                {(!showAdminPin && userData) ? <UserSignup createUser={createUser} userData={userData} /> : null}
            </Aux>
        );
    }

    return (
        <div className={classes.CreateKioskLogin}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            {content}
        </div>
    )
}




const UserSignup = (props) => {
    // data state & varaibles
    const [pin, setPin] = useState('');
    const phoneNumber = props.userData.phone_number

    const validate = (userPin) => {
        setPin(''); 

        if (userPin.toString().length === 4) {
            props.createUser(userPin);
        } else {
            alert('PIN must be 4-digits');
        }
    }

    return (
        <div className="form-group">
            <h4>Hello, {props.userData.first_name}!</h4>
            <p>Please choose a pin number which you will use in combination with your registered phone number to access the Kiosk in future</p>

            <h6>Registered Phone Number</h6>
            <input style={{fontSize: '1.3rem', marginBottom: '10px'}}  className="form-control" name="phone" type="text"
                value={phoneNumber}
                disabled
            />
            <h6>4-Digit PIN</h6>
            <input style={{fontSize: '1.3rem', marginBottom: '10px'}} autoFocus className="form-control" name="pincode" type="password" maxLength="4" inputMode="numeric"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
            />
            <button className="btn btn-block btn-success" onClick={() => validate(pin)}>Enter</button>
        </div>
    );    
}

export default CreateKioskLogin;
