import { React, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Aux from '../../hoc/Auxiliary/Auxiliary';
import classes from './MasterDataTable.module.css';
import { masterDataSortKeys, masterDataPrimaryKeys } from '../../utilities/globalObjects';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../utilities/globalVariables';
import { handleAwsApiError } from '../../utilities/functions';

const Table = (props) => {

  // filtering UI state
  const [showFilter, setShowFilter] = useState(false);
  const [columnToFilter, setColumnToFilter] = useState();
  const [filteredData, setFilteredData] = useState();
  const [columnFilterOptions, setColumnFilterOptions] = useState([]);
  const [updateRow, setUpdateRow] = useState();
  const [updateCol, setUpdateCol] = useState();
  const [updateValue, setUpdateValue] = useState('');
  const [columnFilters, setColumnFilters] = useState({});
  const [showInsertRow, setShowInsertRow] = useState(false);
  const [newRowData, setNewRowData] = useState({});

  // react router history instance to navigate user to other pages
  let history = useHistory();    

  // once data props is received and component rendered, make a copy in state to apply filters to
  useEffect(() => {
    setFilteredData(props.data);
  }, [props.data]);

//   console.log('filteredData: ', filteredData);

  const deleteMasterData = async (row) => {
    console.log('requesting delete for row: ', row);
    props.setLoading(true);

    // attempt to get primary keys for selected table to use in SQL update statement, throw error if keys haven't been added for given table
    const primaryKeys = masterDataPrimaryKeys[props.table]; // ['account_id']  or ['child_id', 'guardian_id']
    if (!primaryKeys) {
        props.setError('Primary keys not found for selected table: ' + props.table);
        props.setLoading(false);
        return false
    }    
    
    const primaryKeysObj = {};
    primaryKeys.forEach(key => primaryKeysObj[key] = row[key]);
    console.log('primaryKeysObj: ', primaryKeysObj);

    // if all of the primary keys weren't provided there will be an undefined in pkValues array - throw error
    if (Object.values(primaryKeysObj).includes(undefined)) {props.setError('Data was not provided for all primary keys'); props.setLoading(false); return false;}    
    const postData = {
      table: props.table,
      rowToDelete: primaryKeysObj
    };
    const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

    // call lambda function to update master data item
    try {
        const res = await axios.post(AWS_API + 'master-data/delete', {postData: postData}, headers);
        console.log('deleteMasterData response: ', res.data);
        // update was successful if updated table is returned in response
        if (res.data) {
            // alert('Successfully deleted row from ' + props.table);
            props.handleBanner('Successfully deleted row from ' + props.table);
            props.updateCompleted(props.table);
            // clearUpdateValues();
        // if updated table not returned, insert failed - most likely due to value not meeting constraints
        } else {
            alert('Delete unsuccessful');
        }
        
    } catch (err) {
        props.setError(handleAwsApiError(err, history) ?? 'Error encountered while attempting to delete master data');
    } finally {
        props.setLoading(false);
    }        
  }

  const updateMasterData = async (e, updateCols, updateRow, updateValues) => {

    console.log('event e passed to updateMasterData: ', e);
    console.log('updateCol: ', updateCols);
    console.log('updateRow: ', updateRow);
    console.log('updateValue: ', updateValues);

    // if enter key was pressed, run submit code otherwise do nothing
    const charCode = (typeof e.which == "number") ? e.which : e.keyCode;
    if (charCode === 13 || e === 'insert') {

        // function was called to insert new row, check permission allowed in this use of component
        if (e === 'insert') {
          if (!props.insertAllowed) {
            alert('insert functionality not authorised');
            return false;
          }
        }

        // function was called on enter key press with one field being updated, check update permission allowed
        if (charCode === 13) {
          if (!props.updateAllowed) {
            alert('update functionality not authorised');
            return false;
          }
        }

        props.setLoading(true);
        
        // attempt to get primary keys for selected table to use in SQL update statement, throw error if keys haven't been added for given table
        const primaryKeys = masterDataPrimaryKeys[props.table]; // ['account_id']  or ['child_id', 'guardian_id']
        if (!primaryKeys) {
            props.setError('Primary keys not found for selected table: ' + props.table);
            props.setLoading(false);
            return false
        }

        const primaryKeysObj = {};
        const pkValues = primaryKeys.map(key => updateRow[key]);
        primaryKeys.forEach(key => primaryKeysObj[key] = updateRow[key]);
        console.log('pkValues: ', pkValues);

        // if all of the primary keys weren't provided there will be an undefined in pkValues array - throw error
        if (pkValues.includes(undefined)) {props.setError('Data was not provided for all primary keys'); props.setLoading(false); return false;}

        // if function was called to insert a new row, create array of update cols and values which don't include primary keys
        if (e === 'insert') {
            updateCols = Object.keys(updateRow).filter(key => !primaryKeys.includes(key));
            updateValues = Object.keys(updateRow).filter(key => !primaryKeys.includes(key)).map(key => updateRow[key]);
        }
        let updateObj = {};
        // create object from updateCols and updateValues arrays, removing primary keys if method is insert (as primary keys will be included in the row data and will overlap with pimaryKeysObj)
        if (e !== 'insert') {
            // if more than one update value has been provided
            if (typeof updateCols === 'object') {updateCols.forEach((col, index) => updateObj[col] = updateValues[index])}
            // only one update value provided
            else {updateObj[updateCols] = updateValues}
        } else {
            Object.keys(updateRow).filter(key => !primaryKeys.includes(key)).forEach(key => updateObj[key] = updateRow[key]);
        }

        // create primary keys object

        console.log('updateObj: ', updateObj);
        console.log('primaryKeysObj: ', primaryKeysObj);

        console.log('submitted table, updateObj, primaryKeysObj: ', props.table, updateObj, primaryKeysObj);

        const postData = {
            table: props.table,
            // primaryKeys: primaryKeys,
            // pkValues: pkValues,
            // updateCols: [updateCols],
            // updateValues: [updateValues],
            updateObj: updateObj,
            primaryKeysObj: primaryKeysObj
        };
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

        // call lambda function to update master data item
        try {
            const res = await axios.post(AWS_API + 'master-data/update', {postData: postData}, headers);
            console.log('postMasterData response: ', res.data);
            // update was successful if updated table is returned in response
            if (res.data) {
                // alert('Successfully updated ' + props.table + ' where primary keys are: ' + Object.values(primaryKeysObj));
                props.handleBanner('Successfully updated ' + props.table);

                props.updateCompleted(props.table);
                // clearUpdateValues();
            // if updated table not returned, insert failed - most likely due to value not meeting constraints
            } else {
                alert('Update unsuccessful, validation failed');
            }
            
        } catch (err) {
            props.setError(handleAwsApiError(err, history) ?? 'Error encountered while attempting to update master data, transaction aborted');
        } finally {
            props.setLoading(false);
        }    
        
    }  
}  

  // return list of unique values available in column to filter table by
  const openFilterPane = (column) => {
    setShowFilter(!showFilter);
    setColumnToFilter(column);

    // create array of all column values and make unique
    const values = [...props.data.map(obj => obj[column])];
    const uniqueValues = values.filter((x, i, a) => a.indexOf(x) === i);

    setColumnFilterOptions(uniqueValues);
  }  

  // if a table has been loaded and a column filter value has been selected, filter data object to only show rows matching filter
  const updateFilterValue = (value) => {
    const newColumnFilters = {...columnFilters, [columnToFilter]: value};
    console.log('column filters: ', columnFilters);
    console.log('new column filters: ', newColumnFilters);
    setFilteredData([...props.data.filter((o) => Object.keys(newColumnFilters).every((k) => newColumnFilters[k] === o[k]))]);
    setColumnFilters(newColumnFilters);
  }  

  const clicked = (key, row) => {
    setUpdateCol(key);
    setUpdateRow(row);
  }  

  const clearUpdateValues = () => {
    setUpdateValue('');
    setUpdateCol();
    setUpdateRow();
  }  
    
  const getKeys = function(){
    return filteredData.length > 0 ? Object.keys(filteredData[0]) : [];
  }

  const getHeader = function(){

    // logic for showing filter pane
    let columnFilterValues, columnFilter;
    if (columnFilterOptions && showFilter) {

      // create array of filter values, and replace booleans with a string so they show up
      columnFilterValues = (
          columnFilterOptions.map(val =>  {
            let newValue;
            if (val === true) {newValue = 'TRUE'}
            else if (val === false) {newValue = 'FALSE'}
            else if (columnToFilter === 'date' || columnToFilter === 'dob' || columnToFilter === 'start_date') {newValue = new Date(val).toLocaleDateString();}
            else if (columnToFilter === 'created_at') {newValue = new Date(val).toLocaleString();}
            else {newValue = val}

            return <li key={val} onClick={() => updateFilterValue(val)} className="list-group-item list-group-item-action">{newValue}</li>
        })
      );

      columnFilter = (
        <div className={classes.Filters}>
          <ul className="list-group">
            {columnFilterValues}
          </ul>
        </div>    
      );    
    }
  
    var keys = getKeys();
    return keys.map((key, index)=>{
      return <th className={classes.HeaderItem} onClick={() => openFilterPane(key)} key={index}>{key.toUpperCase()}▼{columnToFilter === key ? columnFilter : null}</th>
    })
  }

  // function to use when sorting data - check if specific sort key exists, otherwise just sort on first column
  const compareFunction = (a, b, keys) => {
    const sortKey = masterDataSortKeys[props.table] ?? keys[0];

    if (sortKey === 'date') {
      const dateA = new Date(a[sortKey]);
      const dateB = new Date(b[sortKey]);
      return dateA - dateB;
    } else {
      return a[sortKey] - b[sortKey];
    }

  }

  const getRowsData = function(){
    var items = filteredData;
    var keys = getKeys();

    // console.log('[getRowsData] - items: ', items);

    

    return items.sort((a, b) => compareFunction(a, b, keys)).map((row, index)=>{
      
      // if delete prop was passed in, add a button to the end of each row with delete function handler
      let deleteRowButton;
      if (props.deleteAllowed) {
        const deleteFunction = props.deleteFunction ? props.deleteFunction : deleteMasterData;
        deleteRowButton = <td><button onClick={() => deleteFunction(row)} className="btn btn-sm btn-outline-danger">{props.deleteAllowed}</button></td>;

      }
      
      return (
        <tr key={index}>
          <RenderRow clicked={clicked} changed={setUpdateValue} updateFunction={updateMasterData} updateValue={updateValue} updateRow={updateRow} updateCol={updateCol} key={index} data={row} keys={keys} />
          {deleteRowButton}
        </tr>
      );
    });
  }

console.log('newRowData: ', newRowData);
  
  // populate row of inputs for user to insert new row to table
  let insertRow;
  if (showInsertRow) {
    insertRow = (
      <tr>
        {Object.keys(filteredData[0]).map(key => <td key={key}><input style={{fontSize: '.8rem', minWidth: '40px'}} className="form-control" placeholder={key} value={newRowData[key]} onChange={(e) => setNewRowData({...newRowData, [key]: e.target.value})}/></td>)}
      </tr>
    );
  }
  // console.log('newRowData: ', newRowData);

  let tableContent = null;
  if (filteredData) {
    tableContent = (
      <Aux>
        <table onClick={updateRow ? clearUpdateValues : null} className="table table-striped table-hover">
          <thead className={classes.Header}>
              <tr>{getHeader()}</tr>
          </thead>
          <tbody>
            {getRowsData()}
            {insertRow}
          </tbody>
        </table>
        {showInsertRow 
        ?<button onClick={() => updateMasterData('insert', null, newRowData, null)} className='btn btn-block btn-outline-warning'>Insert</button>
        :<button onClick={()=>setShowInsertRow(!showInsertRow)} className='btn btn-block btn-outline-info'>Add New Row</button>}
      </Aux>
    );
  }

  return tableContent;
}

export default Table;

const RenderRow = (props) => {
  return props.keys.map((key, index)=>{
      if (props.updateRow === props.data && props.updateCol === key) {
        // table data item is the one that has been clicked on by user, so return an input component and update parent state with any value changes
        return (
          <td key={index}>
            <input className="form-control" autoFocus onKeyPress={(e) => props.updateFunction(e, props.updateCol, props.updateRow, props.updateValue)} value={props.updateValue} onChange={(e) => props.changed(e.target.value)}/>
          </td>
        );
      } else {
        // just return table data item, but make some formatting changes first
        const value = props.data[key];
        let formattedValue = value;
        // console.log('new date value: ', new Date(value));
        // boolean values
        if (value === true) {
          formattedValue = 'TRUE'
        }
        else if (key === 'date' || key === 'dob' || key === 'start_date'|| key === 'end_date' || key === 'expiry_date' ) {
          formattedValue = props.data[key] ? new Date(value).toLocaleDateString() : props.data[key];
          // console.log('value, type of value: ', new Date(value));
        } else if (key === 'created_at' || key === 'status_changed_at' || key === 'checked_in_at' || key === 'checked_out_at' || key === 'approved_at') {
        // } else if (new Date(value) !== 'Invalid Date') {
          formattedValue = props.data[key] ? new Date(value).toLocaleString() : props.data[key];
        }
        return <td onClick={() => props.clicked(key, props.data)} key={index}>{formattedValue}</td>
      }
  })
}