import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../UI/Modal/Modal';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import SessionSignIns from '../../Kiosk/SessionSignIns/SessionSignIns';
import classes from './CheckIns.module.css';
import Banner from '../../../UI/Banner/Banner';

const CheckIns = (props) => {

    // hoc state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [bannerText, setBannerText] = useState();

    // data state
    const [upcomingSessions, setUpcomingSessions] = useState();
    // const [permissionSlipsData, setPermissionSlipsData] = useState();
    // const [showPermissionSlip, setShowPermissionSlip] = useState();

    // console logs
    console.log('upcomingSessions: ', upcomingSessions);
    // console.log('permissionSlipsData: ', permissionSlipsData);

    // create react-router history object to navigate user to other pages
    const history = useHistory();    

    // function to call API to get upcoming sessions data to display on kiosk roll summary for checkins + to call API to get outstanding permission slips
    const getUpcomingSessions = useCallback(async () => {
        setLoading(true);

        // upcoming sessions data
        let config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {tableName: 'upcoming_sessions'}
        };
        try {
            const res = await axios.get(AWS_API + 'account-owner-data', config);
            setUpcomingSessions(res.data);
            console.log('res: ', res.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
        }   
  
  
    }, [history, props.token]);


  
    // fetch account owner data for portal - linked children, permanent & casual bookings
    useEffect(() => {

        getUpcomingSessions();

    }, [getUpcomingSessions]);


    const updateRoll = async (rollEntries, signedInUserId, updateType) => {
        console.log('checking child in for roll session, using authorised user ID: ', rollEntries);
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rollEntries: rollEntries,
            updateType: updateType
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/update-roll', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `${updateType} action completed`);
            getUpcomingSessions();
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while checking children in');
            setLoading(false);
        }         
    }

    console.log('[CheckIns.js] - upcoming sessions: ', upcomingSessions);

    let content = <SpinnerDark />
    if (!loading && upcomingSessions) {
        content = <SessionSignIns parentView absentAllowed multipleCentres token={props.token} upcomingSessions={upcomingSessions} updateRoll={updateRoll} />;
        // (
            // <div>
            //     <button onClick={getUpcomingSessions} className='btn btn-outline-info btn-block'>Get Test</button>
            //     <button onClick={updateRoll} className='btn btn-outline-info btn-block'>Post Test</button>
            // </div>
        // ); 
    }


    return (
        <div className={classes.CheckIns}>
            <Banner show={bannerText}>{bannerText}</Banner>    
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>  
            {content}
        </div>
    );
}

export default CheckIns;