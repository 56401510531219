import {React, useState, useCallback, useEffect, Fragment} from 'react';
import classes from './ProviderProfile.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';

const ProviderProfile = (props) => {

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    const [editing, setEditing] = useState();
    
    // data state
    const [data, setData] = useState();
    const [updatedProviderData, setUpdatedProviderData] = useState();
    
    // react router history instance to navigate user to other pages
    let history = useHistory();       

    // console logs
    console.log('data: ', data);
    console.log('updatedProviderData: ', updatedProviderData);

    const getData = useCallback(async () => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/read-provider-profile', config);
            console.log('data fetch res: ', res.data);
            setData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);


    }, [history, props]);

    // function to update provider profile data
    const updateProfile = useCallback(async () => {
        
        setLoading(true);
    
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            updatedProviderData
        };
        try {
            const res = await axios.post(AWS_API + 'ccs/update-provider-profile', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Profile updated`);
            setUpdatedProviderData();
            getData();
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error while updating provider profile');
            setLoading(false);
        }           


    }, [history, props, getData, updatedProviderData]); 


    // get initial data
    useEffect(() => {
        getData();
    }, [getData])

    // show inputs for editable fields if we're in editing mode
    let inputs;
    if (editing) {
        inputs = (
            <Fragment>
                <hr />
                <h6>New Profile Values</h6>
                <div className={classes.Row}> <span className={classes.Item}>Business Name</span> 
                <input className="form-control" type="text" value={updatedProviderData?.businessName ?? ''} onChange={(e) => {setUpdatedProviderData({...updatedProviderData, businessName: e.target.value})}}/></div>  
                <p> </p> 
                <button className='btn btn-success btn-block btn-sm' onClick={updateProfile}>Submit</button>             
            </Fragment>
        );
    }

    let content = <SpinnerDark />;
    if (!loading && data) {
        const provData = data[0];
        content = (
            <Fragment>
                    <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                        <button className="btn btn-info btn-sm" onClick={() => setEditing(!editing)}>Edit Profile</button>
                    </div>
                    <div className={classes.Row}> <span className={classes.Item}>ID</span> <div>{provData.providerID}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Legal Name (ABR Registered)</span> <div>{provData.ProviderName.results.filter(obj => obj.type === 'LGL')[0].name}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Business Name</span> <div>{provData.ProviderName.results.filter(obj => obj.type === 'BUS')[0].name}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Address</span> <div>{provData.Address.results[0].streetLine1 + ', ' + provData.Address.results[0].streetLine2 + ', ' + provData.Address.results[0].suburb}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>CCS Approval Start Date</span> <div>{provData.CCSApproval.results[0].startDate}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>CCS Approval End Date</span> <div>{provData.CCSApproval.results[0].endDate}</div> </div>

                    {inputs}
            </Fragment>
        );
    }

    
    return (
        <div className={classes.ProviderProfile}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>    
            <Banner show={bannerText}>{bannerText}</Banner>                

            <h3>
                Provider Profile
            </h3>
            {content}

        </div>
    )
}

export default ProviderProfile;