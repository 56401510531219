import {React, useState, useCallback, useEffect, Fragment} from 'react';
import classes from './ServiceProfiles.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';

const ServiceProfiles = (props) => {

    // UI state
    const [loadingServices, setLoadingServices] = useState(false);
    const [loadingProfiles, setLoadingProfiles] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    const [editing, setEditing] = useState();

    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [updatedServiceData, setUpdatedServiceData] = useState();
    
    // data state
    const [data, setData] = useState([]);
    const [serviceProfileData, setServiceProfileData] = useState([]);
    
    // react router history instance to navigate user to other pages
    let history = useHistory();       

    // console logs
    console.log('data: ', data);
    console.log('serviceProfileData: ', serviceProfileData);
    console.log('updatedServiceData: ', updatedServiceData);

    // function to enable editing mode
    const enableEditing = () => {


        // initalise new object with old values as defaults
        const initialUpdatedServiceObj = {
            physicalAddress: {
                ...serviceProfileData[0].Address.results.filter(obj => obj.type === 'ZPHYSICAL')[0],
            },
            postalAddress: {
                ...serviceProfileData[0].Address.results.filter(obj => obj.type === 'ZPOSTAL')[0],

            },
            serviceName: serviceProfileData[0].ServiceName.results[0].name
        }

        console.log('initial: ', initialUpdatedServiceObj);

        setUpdatedServiceData(initialUpdatedServiceObj);
        setEditing(true);
        
    }

    const getData = useCallback(async () => {
        
        setLoadingServices(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'centres'}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setData(res.data ?? []);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }            

        setLoadingServices(false);


    }, [history, props]);    

    const getServiceProfileData = useCallback(async (serviceCRN) => {
        
        setLoadingProfiles(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {serviceCRN: serviceCRN}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/read-service-profile', config);
            console.log('data fetch res: ', res.data);
            setServiceProfileData(res.data ?? []);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }            

        setLoadingProfiles(false);


    }, [history, props]);

    // function to update provider profile data
    const updateProfile = useCallback(async () => {
        
        setLoadingProfiles(true);

        // get the CCS start date to use as the start date in service profile update call
        const ccsStartDate = serviceProfileData[0].CCSApproval.results[0].startDate;
        
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            updatedServiceData: updatedServiceData,
            ccsStartDate: ccsStartDate,
            serviceCRN: selectedServiceCRN
        };
        try {
            const res = await axios.post(AWS_API + 'ccs/update-service-profile', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Profile updated`);
            getServiceProfileData(selectedServiceCRN);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error while updating provider profile');
            setLoadingProfiles(false);
        }           


    }, [history, props, getServiceProfileData, updatedServiceData, serviceProfileData, selectedServiceCRN]); 


    // get initial data
    useEffect(() => {
        getData();
    }, [getData])

    // get service profile data when service selected
    useEffect(() => {
        if (selectedServiceCRN) {
            getServiceProfileData(selectedServiceCRN);
        }
    }, [selectedServiceCRN, getServiceProfileData])

    // show inputs for editable fields if we're in editing mode
    let inputs;
    // let editableFields = ['serviceName', ''];
    if (editing) {
        inputs = (
            <Fragment>
                <hr />
                <h4>Update Profile Details</h4>
                <br/>
                <div className={classes.Row}> 
                    <span className={classes.Item}>Service Name</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.serviceName ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, serviceName: e.target.value})}}/>
                </div>  
                <br/>
                <h5>Physical Address</h5>
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 1</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.streetLine1 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, streetLine1: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 2</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.streetLine2 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, streetLine2: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Suburb</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.suburb ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, suburb: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>State</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.state ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, state: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Postcode</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.postcode ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, postcode: e.target.value}})}}/>
                </div>  
                <br/>
                <h5>Postal Address</h5>
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 1</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.streetLine1 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, streetLine1: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 2</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.streetLine2 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, streetLine2: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Suburb</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.suburb ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, suburb: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>State</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.state ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, state: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Postcode</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.postcode ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, postcode: e.target.value}})}}/>
                </div>  
                <p> </p> 
                <button className='btn btn-success btn-block btn-sm' onClick={updateProfile}>Submit</button>             
            </Fragment>
        );
    }

    let services = <SpinnerDark />;
    if (!loadingServices) {
        const serviceData = data.filter(obj => obj.service_crn);
        services = serviceData.map(obj => {
            return (
                <button key={obj.service_crn} className="btn btn-sm btn-secondary" onClick={() => setSelectedServiceCRN(obj.service_crn)}>{obj.centre_name}</button>
            );
        })
    }



    let profile = <SpinnerDark />;
    if (!loadingProfiles) {
        profile = serviceProfileData.map(obj => {
            return (
            <div key={obj.serviceID} className={classes.ServiceProfile}>
                    <h5>{obj.ServiceName.results[0].name}</h5>
                    <br />
                    <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                        <button className="btn btn-info btn-sm" onClick={enableEditing}>Edit Profile</button>
                    </div>
                    <div className={classes.Row}> <span className={classes.Item}>Service ID</span> <div>{obj.serviceID}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>CCS Approval Start Date</span> <div>{obj.CCSApproval.results[0].startDate}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>CCS Approval End Date</span> <div>{obj.CCSApproval.results[0].endDate}</div> </div>    
                    <br/>
                    <h6>Physical Address</h6>                
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 1</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].streetLine1}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 2</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].streetLine2}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Suburb</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].suburb}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>State</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].state}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Postcode</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].postcode}</div> </div>
                    <br/>
                    <h6>Postal Address</h6>                
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 1</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].streetLine1}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 2</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].streetLine2}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Suburb</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].suburb}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>State</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].state}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Postcode</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].postcode}</div> </div>
                    {inputs}
            </div>
            );
        });
    }

    
    return (
        <div className={classes.ServiceProfiles}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>    
            <Banner show={bannerText}>{bannerText}</Banner>                

            <h3>Select Service to View Profile</h3>
            <hr />
            <div className={classes.Row}>
                {services}
            </div>
            <hr />
            {profile}

        </div>
    )
}

export default ServiceProfiles;