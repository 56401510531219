import { useState, useEffect, useMemo } from 'react';


export const scrollPageToTop = () => {
    window.scrollTo(0, 0);
}

export const handleAwsApiError = (err, history) => {
    if (err.response && err.response.status === 403) {
        console.log('[handleAwsApiError fn] API call forbidden, redirecting to Auth.js');
        history.replace("/auth");
    } else if (err.response && err.response.status === 409) {
        console.log('409 Error Encountered, Message: ', err.response.data);
        return err.response.data;
    } else {
        console.log('Unknown Error Encountered in handleAWSApiError function: ', err.stack);
        if (err.response && err.response.data && err.response.data.message) {
            return err.response.data.message;
        } else if (err.response && err.response.data) {
            return err.response.data;
        } else if (err.stack) {
            return err.stack;
        } else {
            return null
        }
    }        
}

export const arraysMatch = (arr1, arr2) => {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

};

export const handleBanner = (showBannerFn, bannerTextFn, text) => {
    showBannerFn(true);
    bannerTextFn(text);
    setTimeout(() => {
        showBannerFn(false);
        // bannerTextFn('');
    }, 2000);
}

export const handleBannerText = (bannerTextFn, text) => {
    bannerTextFn(text);
    setTimeout(() => {
        bannerTextFn();
    }, 3000);    
}

export const onEnterKeyPress = (e, fns, fnInputs) => {
    if (e.keyCode === 13) {
        fns.forEach((fn, i) => {
            fn(fnInputs[i]);
        })
    }
}

export const handlePrompt = (message, proceedFunction, setPromptState) => {
    const newState = {
        show: true,
        message: message,
        proceedFunction: proceedFunction
    };
    setPromptState(newState);
}

// get next day of week from 
export const getNextDay = (x, date) => {
    var now = new Date(date);    
    now.setDate(now.getDate() + (x+(7-now.getDay())) % 7);
    return now.toLocaleDateString('sv-SW');
}

// get previous monday from given date
export const getPrevMonday = (date) => {
    const prevMonday = new Date(date);
    prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
    return prevMonday.toLocaleDateString('sv-SW');     
}

export function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => {
        return (
            new IntersectionObserver(
              ([entry]) => setIntersecting(entry.isIntersecting)
            )
        );        
    }, []); 
  
    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [ref, observer])
  
    return isIntersecting
}