import React, { 
    // useState 
} from 'react'
import classes from './ParentData.module.css';
 
const ParentData = (props) =>  {

    // get data from props
    const data = props.guardianData;

    // console logs
    console.log('data: ', data);


    return (
      <div className={classes.ParentData}>
      </div>
    );
}

export default ParentData;