export const masterDataPrimaryKeys = {
    groups: ['group_id'],
    guardians: ['guardian_id'],
    all_contacts: ['person_id'],
    children: ['child_id'],
    accounts: ['account_id'],
    child_guardian_relationships: ['person_type', 'person_id', 'child_id'],
    child_guardian_authorisations: ['person_type', 'person_id', 'child_id', 'authorisation'],
    children_medical: ['child_id'],
    emergency_contacts: ['contact_id'],
    documents_metadata: ['document_id'],
    form_approvals: ['form_id'],
    form_submissions: ['form_id'],
    casual_bookings: ['booking_id'],
    permanent_bookings: ['child_id', 'centre_id', 'session', 'start_date'],
    variables: ['group_id', 'variable'],
    roll: ['id'],
    centres: ['centre_id'],
    idp_group_access: ['group_id', 'idp', 'idp_group', 'api_route_allowed', 'api_method_allowed'],
    role_access: ['group_id', 'role', 'entity', 'field', 'value', 'operator'],
    excursions: ['group_id', 'centre_id', 'session', 'start_date', 'end_date'],
    excursion_detail: ['excursion_id', 'key']
};

export const masterDataSortKeys = {
    roll: 'date',
    casual_bookings: 'date',
}

export const updateTableByFormType = {
    updateBookingSchedule: {
        table: 'permanent_bookings',
        updateCols: ['mon_1', 'tue_1', 'wed_1', 'thu_1', 'fri_1', 'mon_2', 'tue_2', 'wed_2', 'thu_2', 'fri_2'],
        updateValueName: {
            fortnightly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon2', 'tue2', 'wed2', 'thu2', 'fri2'], 
            weekly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon1', 'tue1', 'wed1', 'thu1', 'fri1']
            }
    }
}

export const autoApprovedForms = ['permissionSlip'];

export const bootstrapTableConfig = {
    pending_is_case_claim_submissions: {
        excludeColumns: ['centre_id', 'week_commencing', 'week_ending']
    },
    pending_session_reports: {
        excludeColumns: ['centre_id', 'child_id', 'week_commencing', 'week_ending']
    },
    view_submitted_session_reports: {
        excludeColumns:  ['centre_id', 'child_id', 'week_commencing']
    },
    view_submitted_is_claims: {
        excludeColumns:  ['service_crn', 'week_commencing']
    },
}

export const personnelRoleTypes = {
'MANCON': 'Provider Management or Control',
'OPERAT': 'Day-to-day operation of the Service ',
'CONTAC': 'Service Contact',
'FDCEDU':'Educator', 	
};

export const personnelPositions = {
    'Z7': 'Chairperson' ,
    'Z8':  'Chief Executive Officer',
    'Z9':  'Child Care Service Director',
    'Z10':  'Company Director' ,
    'Z11':  'Company Financial Officer',
    'Z12': 'Company Secretary' ,
    'Z13':   'Coordinator',
    'Z14': 'Executive Officer' ,
    'Z15':  'General Manager' ,
    'Z16':  'Manager' ,
    'Z17': 'Nominated Supervisor' ,
    'Z18':  'Operator',
    'Z19': 'President' ,
    'Z20': 'Principal' ,
    'Z21': 'Program Manager' ,
    'Z22': 'Treasurer' ,
    'Z23':   'Other',	
};