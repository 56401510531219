import { React, useState, useCallback, useEffect, memo } from 'react';
import axios from 'axios';
import Spinner from '../../UI/Spinner/Spinner';
import Modal from '../../UI/Modal/Modal';
import classes from './Auth.module.css';
import { cognitoTokenAPI, AWS_API, cognitoClientId, cognitoRedirectURL, cognitoGrantTypeAuth, cognitoGrantTypeRefresh, cognitoURL, cognitoUserpoolId} from '../../utilities/globalVariables';
import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import Aux from '../../hoc/Auxiliary/Auxiliary';

const Auth = memo((props) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState();

    // react router history instance to navigate user to other pages
    let history = useHistory();

    const updateAppAuth = useCallback(
        (email, groups, token) => {
            props.updateAuthState(email, groups, token);
        },
        [props],
      );       

    console.log('[Auth.js] - rendered, props.code: ', props.code);

    // exchange code returned in URL for ID token
    useEffect( () => {
        
        const authenticateUser = async () => {

            setLoading(true);
            
            // attempt to get code from props, passed in via App.js (where Cognito is redirecting to after signin with grantCode in url)
            // const parsedURL = new URLSearchParams(location.search);
            const code = props.code;

            // attempt to get jwt token from storage
            const localToken = localStorage.getItem("jwt-token");

            // attempt to get refresh token from local storage
            const localRefreshToken = localStorage.getItem("jwt-token-refresh");

            // first check for a code in props - whether we got here via Cognito redirect or a manual redirect to try and refresh a token
            if (code) {

                // a grant code has been passed in by app, so we'll attempt to trade it for a token and validate that token
                console.log('code found in props, attempting to trade for token');
                const params = new URLSearchParams();
                params.append('grant_type', cognitoGrantTypeAuth);
                params.append('code', code);
                params.append('client_id', cognitoClientId);
                params.append('redirect_uri', cognitoRedirectURL);
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };
        
                // exchange code for token with AUTH API
                let token = null;
                try {
                    const res = await axios.post(cognitoTokenAPI, params, axiosConfig);
                    console.log('response from cognito token-4-code API: ', res.data);
                    token = res.data.id_token;
                    // console.log('token: ', token);
                    localStorage.setItem("jwt-token", res.data.id_token);
                    localStorage.setItem("jwt-token-refresh", res.data.refresh_token);
                } catch (err) {
                    console.log('error: ', err.stack);
                    // setError(true);
                    // setLoading(false);
                }

                // decode token to make sure it's valid and determine user's identity and privileges    
                try {
                    const res = await axios.post(AWS_API + 'decode-verify-jwt', {'token': token, 'appClientId': cognitoClientId, 'userpoolId': cognitoUserpoolId});
                    console.log('ID token data: ', res.data);
                    const groups = res.data["cognito:groups"];
                    console.log('groups: ', groups);
                    const email = res.data.email;
                    updateAppAuth(email, groups, token); // If authenticated this will switch routes and unmount component - don't change state after this!
                    return false;
                } catch (err) {
                    console.log('error: ', err.stack);
                }    
            } else if (localToken && localToken !== 'undefined') {
                console.log('trying to verify token from local storage');
                // decode token to make sure it's valid and determine user's identity and privileges    
                try {
                    const res = await axios.post(AWS_API + 'decode-verify-jwt', {'token': localToken, 'appClientId': cognitoClientId, 'userpoolId': cognitoUserpoolId});
                    const tokenVerifyRes = res.data;
                    console.log('ID token data: ', tokenVerifyRes);
                    const groups = tokenVerifyRes["cognito:groups"];
                    console.log('groups: ', groups);
                    const email = tokenVerifyRes.email;
                    
                    // if id token data verified, call updateAuth - otherwise token validation failed, remove from local storage and recall auth fn
                    if (tokenVerifyRes) {
                        updateAppAuth(email, groups, localToken); // If authenticated this will switch routes and unmount component - don't change state after this!
                    } else {
                        // token validation failed, probably expired - so remove from storage and call function again to either use refresh token or redirect to sign in again
                        console.log('verifying of local token failed, remove from local storage to avoid endless loop');
                        localStorage.removeItem("jwt-token");
                        authenticateUser();                        
                    }     
                    return false;
                } catch (err) {
                    console.log('error: ', err.stack);

                    // token validation failed, probably expired - so remove from storage and call function again to either use refresh token or redirect to sign in again
                    console.log('verifying of local token failed, remove from local storage to avoid endless loop');
                    localStorage.removeItem("jwt-token");
                    authenticateUser();
                } 

            } else if (localRefreshToken && localRefreshToken !== 'undefined') {

                // no code, so check for refresh token in storage
                console.log('no code found in props, so looking for refresh token');
                console.log('Found localRefreshToken: ', localRefreshToken);
                console.log('type of localrefresh: ', typeof localRefreshToken);

                const params = new URLSearchParams();
                params.append('grant_type', cognitoGrantTypeRefresh);
                params.append('refresh_token', localRefreshToken);
                params.append('client_id', cognitoClientId);
                params.append('redirect_uri', cognitoRedirectURL);
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };
        
                // exchange refresh token for fresh access token with AUTH API
                let token = null;
                try {
                    const res = await axios.post(cognitoTokenAPI, params, axiosConfig);
                    console.log('response from cognito refresh_token API: ', res.data);
                    token = res.data.id_token;
                    // console.log('token: ', token);
                    localStorage.setItem("jwt-token", res.data.id_token);
                    localStorage.setItem("jwt-token-refresh", res.data.refresh_token);
                } catch (err) {
                    console.log('error: ', err.stack);
                    console.log('failed to exchange refresh token for access token, removing from local storage');
                    localStorage.removeItem('jwt-token-refresh');
                    setError(true);
                    setLoading(false);
                    // history.replace('/');
                    authenticateUser();
                }

                // decode token to make sure it's valid and determine user's identity and privileges    
                try {
                    const res = await axios.post(AWS_API + 'decode-verify-jwt', {'token': token, 'appClientId': cognitoClientId, 'userpoolId': cognitoUserpoolId});
                    console.log('ID token data: ', res.data);
                    const groups = res.data["cognito:groups"];
                    console.log('groups: ', groups);
                    const email = res.data.email;
                    // const domain = email.split('@').pop();
                    // const role = allowedStaffEmails.includes(domain) ? 'staff' : null;
                    // setLoading(false);
                    updateAppAuth(email, groups, token); // If authenticated this will switch routes and unmount component - don't change state after this!
                    return false;
                } catch (err) {
                    console.log('error: ', err.stack);
                    // setLoading(false);
                    // setError(true);
                    // setErrorMessage('Authorisation could not be completed, invalid token');            
                }    
            } else {
                // no code in url and no refresh token, so redirect user to sign in screen
                console.log('no code or refresh token found, so reauthentication needed');
                window.location.replace(cognitoURL);
            }
        }

        authenticateUser();
        
        return () => {
            // const source = axios.CancelToken.source();
            // source.cancel()
            setLoading(false);
        }        
        
    }, [updateAppAuth, props.code, history]);

    let authContent = <div style={{paddingTop: '15vh'}}><Spinner /></div>;
    if (!loading) {
        authContent = null;
    }

    return (
        <div className={classes.Auth}>
            <Modal show={error} modalClosed={() => setError(false)}><h2>Oops!</h2><p>Something went wrong...</p></Modal> 
            {authContent}
        </div>
    );
});

export default Auth;