import {React, Fragment, useState, 
    // useEffect, useCallback, useRef
} from 'react';
import classes from './AdminMgmt.module.css';
// import Banner from '../../../UI/Banner/Banner';
import Modal from '../../../UI/Modal/Modal';
// import { useHistory } from "react-router-dom";
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import Cognito from './Cognito/Cognito';
import DeviceMgmt from './DeviceMgmt/DeviceMgmt';
import ProviderProfile from './ProviderProfile/ProviderProfile';
import ServiceProfiles from './ServiceProfiles/ServiceProfiles';
import Personnel from './Personnel/Personnel';

// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';


const AdminMgmt = (props) => {

    // UI state
    // const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [showContent, setShowContent] = useState({
        ccsDevices: true,
    })

    
    // react router history instance to navigate user to other pages
    // let history = useHistory();   

    // console logs

    
    const handleDisplayContent = (show) => {
        const newShowContent = {...showContent};

        // set all to false
        Object.keys(newShowContent).forEach(key => {
            newShowContent[key] = false;
            });

        // show selected and update state
        newShowContent[show] = true;
        setShowContent(newShowContent);

    }

    


    let content;
    if (showContent.cognitoUsers) {
        content = <Cognito token={props.token}/>;
    } else if (showContent.ccsDevices) {
        content = <DeviceMgmt token={props.token} />
    } else if (showContent.providerProfile) {
        content = <ProviderProfile  token={props.token}/>
    } else if (showContent.serviceProfiles) {
        content = <ServiceProfiles token={props.token}/>
    } else if (showContent.personnel) {
        content = <Personnel token={props.token}/>
    }

    return (
        <Fragment >
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>

            <div className={classes.Wrapper}>
                
                {/* Left nav bar */}
                <div className={classes.LeftBar}>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('cognitoUsers')}>Manage Users</button>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('ccsDevices')}>Manage CCS Devices</button>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('providerProfile')}>Provider Profile</button>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('serviceProfiles')}>Service Profiles</button>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('personnel')}>Personnel</button>
                </div>

                {/* main content */}
                <div className={classes.Content} >
                    <div className={classes.AdminMgmt}>
                        {content}
                    </div>
                </div>

            </div>
        </Fragment>
    );
}

export default AdminMgmt;