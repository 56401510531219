import { useState, useEffect, } from 'react';
// import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
// import { handleAwsApiError } from '../../../utilities/functions';
// import { useHistory } from "react-router-dom";
// import Modal from '../../../UI/Modal/Modal';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import classes from './Notifications.module.css';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import { raw } from '../../../translations/en/raw';
import { autoApprovedForms } from '../../../utilities/globalObjects';
import { NavLink } from 'react-router-dom';

const Notifications = (props) => {

    // state
    const [pendingFormCounts, setPendingFormCounts] = useState();
    const [approvedFormCounts, setApprovedFormCounts] = useState();
    const [showApprovedFormDetail, setShowApprovedFormDetail] = useState();
    const [selectedApprovedForm, setSelectedApprovedForm] = useState();


    // get data from props
    const formSubmissionData = props.formSubmissionData;
    const permissionSlipData = props.permissionSlipData;
    const childrenData = props.children;

    // console logs


    // effect to determine notifications to show accountOwner based on pending requests and approved requests
    useEffect(() => {
        if (formSubmissionData) {
            // console.log('formSubmissionData: ', formSubmissionData);


            // filter list of form IDs to only those still pending approval and those approved
            const newPendingForms = formSubmissionData.filter(obj => !obj.approved && !autoApprovedForms.includes(obj.form_type));
            const newApprovedForms = formSubmissionData.filter(obj => obj.approved);
            // console.log('newPendingForms: ', newPendingForms);
            // console.log('newApprovedForms: ', newApprovedForms);

            // create pending forms notification object
            const newPendingFormCounts = newPendingForms.reduce((agg, curr) => {
                if (agg[curr.form_type]) {
                agg[curr.form_type] += 1
                } else {
                agg[curr.form_type] = 1
                }
                return agg
            }, {})     
            setPendingFormCounts(newPendingFormCounts);
            
            // create approved forms notification object
            const newApprovedFormCounts = newApprovedForms.reduce((agg, curr) => {
                if (agg[curr.form_type]) {
                agg[curr.form_type] += 1
                } else {
                agg[curr.form_type] = 1
                }
                return agg
            }, {})                
            setApprovedFormCounts(newApprovedFormCounts);
            
        };

    }, [formSubmissionData])  
    
    // effect to determine outstanding permission slips to prompt user for
    // useEffect(() => {
    //     if (permissionSlipData) {}
 
    // }, [permissionSlipData])  


    // whenever outstanding permission slips data changes, update notifications
    let outstandingPermissionSlips = <p><i>none found</i></p>;
    if (permissionSlipData && permissionSlipData.length > 0 && childrenData && childrenData.length > 0) {
        outstandingPermissionSlips = permissionSlipData.sort((a,b) => a.child_id - b.child_id)
        .map((obj, i) => {
            const name = childrenData.filter(child => child.child_id === obj.child_id)[0].first_name;
            return (
                <div onClick={() => props.setPermissionSlipId(obj.id)} key={i} className={classes.Notification}>
                    <div className={classes.NotificationTitle}>{name + ', ' + new Date(obj.date).toLocaleDateString()}  </div>  
                    
                </div>
            );
        })
    }

    // whenever pendingFormCounts change, update notifications for user
    let pendingFormNotifications = <p><i>none found</i></p>;
    if (pendingFormCounts && Object.keys(pendingFormCounts).length > 0) {
        pendingFormNotifications = Object.keys(pendingFormCounts).map(key => 
            <div key={key} className={classes.Notification}>
                <div className={classes.NotificationTitle}>{raw[key]}  </div>  
                <span className={classes.PendingBadge}>{pendingFormCounts[key]}</span>
            </div>
        )
    }  
    // console.log('pendingFormCounts: ', pendingFormCounts);
    // whenever approvedFormCounts change, update notifications for user
    let approvedFormNotifications = <p><i>none found</i></p>;
    if (approvedFormCounts && Object.keys(approvedFormCounts).length > 0) {
        approvedFormNotifications = Object.keys(approvedFormCounts).map(key => {
            let detail;
            if (selectedApprovedForm === key && showApprovedFormDetail) {
                detail = (
                    <ul>
                        {formSubmissionData.filter(obj => obj.approved && obj.form_type === key).map((obj, i) => <li key={obj.child_first_name + i}>{obj.child_first_name}</li>)}
                    </ul>

                );
            } 

            return (
                <Aux key={key}>
                    <div onClick={() => {setSelectedApprovedForm(key); setShowApprovedFormDetail(!showApprovedFormDetail);}} key={key} className={classes.Notification}>
                        <div className={classes.NotificationTitle}>{raw[key]}  </div>  
                        <span className={classes.ApprovedBadge}>{approvedFormCounts[key]}</span>
                    </div>
                        {detail}
                </Aux>
                );
        });
    }        

    return (
        <Aux>
            <h6 className={classes.NotificationHeader}>Permission Slips  &nbsp;&nbsp;<span className={permissionSlipData && permissionSlipData.length > 0 ? classes.ActionBadge : null}>{permissionSlipData && permissionSlipData.length > 0 ? permissionSlipData.length : null}</span></h6>
            {outstandingPermissionSlips}   

            <h6 className={classes.NotificationHeader}>Pending Requests</h6>
            {pendingFormNotifications}                    
            
            <h6 className={classes.NotificationHeader}>Approved Requests</h6>
            {approvedFormNotifications}             

            <hr />
            <h6>QR Vouchers</h6>
            <NavLink to="/qrscanner">Redeem QR Voucher</NavLink>        
        </Aux>
    );
}

export default Notifications;