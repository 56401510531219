import React from 'react';
import classes from './Banner.module.css';

const Banner = (props) => {
    let classList = ["alert alert-success", classes.Banner];
    if (props.show) {
        classList.push(classes.Visible);
    } else {
        classList.push(classes.Hidden);
    }
   
    return (
        <div 
            className={classList.join(' ')}
        >
            {props.children} &nbsp;
        </div> 

    );
};

export default Banner;