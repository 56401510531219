import {React, Fragment, useState, useEffect, useCallback, useRef} from 'react';
import classes from './CentreLink.module.css';
import { handleAwsApiError, getPrevMonday, getNextDay, handleBannerText, useOnScreen 
} from '../../../utilities/functions';
import Banner from '../../../UI/Banner/Banner';
import Modal from '../../../UI/Modal/Modal';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../utilities/globalVariables';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import SmallSpinnerDark from '../../../UI/SmallSpinnerDark/SmallSpinnerDark';
import BSTable from '../../../UI/BSTable/BSTable';

// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';

// config for data and detailed data based on content selected
const dataMap = {
    pendingISCaseClaims: {
        data: 'pending_is_case_claim_submissions',
        detailData: 'pending_is_case_claim_submissions_detail'
    }, 
    pendingSessionReports: {
        data: 'pending_session_reports',
    },
    submittedISCaseClaims: {
        data: 'view_submitted_is_claims',
    },
    submittedSessionReports: {
        data: 'view_submitted_session_reports'
    }
};

const CentreLink = (props) => {

    // infinite scroll detection
    const ref = useRef()
    const isVisible = useOnScreen(ref)
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');       
    const [showContent, setShowContent] = useState({
        pendingISCaseClaims: true,
        pendingSessionReports: false,
    })

    
    // table state passed to child component
    const [selected, setSelected] = useState([]);  
    const [mode, setMode] = useState();

    // data state
    const [data, setData] = useState();
    const [detailData, setDetailData] = useState();

    // set week commencing/starting dates
    const weekStarting = getPrevMonday(new Date());
    const weekEnding = getNextDay(0, new Date()); // 0 = sunday
    
    // react router history instance to navigate user to other pages
    let history = useHistory();   

    // pagination state
    const [tableLoading, setTableLoading] = useState();
    const [pageSize] = useState(25);
    const [pageNumber, setPageNumber] = useState();

    // console logs
    console.log('pageNumber: ', pageNumber);

    
    // update page number whenever inf scroll component reached
    useEffect(() => {
        console.log('isVisible change to: ', isVisible);
        if (isVisible) {
            // increment page number by one
            setPageNumber(prevNum => !prevNum ? 2 : prevNum + 1);            
        }
    }, [isVisible])
    
    // whenever page number is increased, fetch more data
    useEffect(() => {

        const getMoreData = async () => {
            setTableLoading(true);

            // get additional data
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {table: dataMap[mode].data, pageSize: pageSize, pageNumber: pageNumber}
            };
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log(`fetched more data - pagesize: ${pageSize}, pagenumber: ${pageNumber}`);
                if (res.data.length > 0) {
                    setData(prevData => [...prevData, ...res.data]);
                }
            } catch (err) {
                setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
            }    

            setTableLoading(false);
        }

        if (pageNumber > 1) {
            getMoreData();
        }
    }, [pageNumber, history, pageSize, props.token, mode])



    
    const getMasterData = useCallback(async (tableName, stateFn, firstCall, lastCall, pageNumber, pageSize) => {
        
        console.log(`fetching data for table ${tableName} with page size ${pageSize} and page number ${pageNumber} `);

        firstCall ? setLoading(true) : void(0);

        if (tableName) {
            // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {table: tableName, pageSize: pageSize, pageNumber: pageNumber}
            };
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log('data fetch res: ', res.data);
                stateFn(res.data);
            } catch (err) {
                setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
            }            

        }

        lastCall ? setLoading(false) : void(0);
    }, [history, props]);

    // load up data for default option
    useEffect(() => {
        const show = 'pendingISCaseClaims'; 
        getMasterData(dataMap[show].data, setData, true, false, 1, pageSize);
        getMasterData(dataMap[show].detailData, setDetailData, false, true, null, null);
        setMode(show);
    }, [getMasterData, pageSize])    

    const handleDisplayContent = (show) => {
        const newShowContent = {...showContent};

        // reset pagination
        setPageNumber();

        // reset selected rows
        setSelected([]);

        // set all to false
        Object.keys(newShowContent).forEach(key => {
            newShowContent[key] = false;
            });

        // show selected and update state
        newShowContent[show] = true;
        setShowContent(newShowContent);

        // fetch data and detailed data
        if (dataMap[show].detailData) {
            console.log('fetching data and child data');
            getMasterData(dataMap[show].data, setData, true, false, 1, pageSize);
            getMasterData(dataMap[show].detailData, setDetailData, false, true);
        } else {
            console.log('fetching data, no child data specified');
            getMasterData(dataMap[show].data, setData, true, true, 1, pageSize);
            setDetailData([{'no drilldown data specified': 'no drilldown data specified'}]);
        }

        // update table name selected
        setMode(show);
    }

    

    // function to write to submission review table in rds
    const postSubmissionReview = async (ids, mode, submissionType,  status) => {
        setLoading(true);

        // get rows relating to id
        const rowsToSend = data.filter(obj => ids.includes(obj.id));
        console.log('rowsToSend: ', rowsToSend);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rows: rowsToSend,
            submissionType: submissionType,
            status: status
        };

        console.log('postData: ', postData);
        try {
            const res = await axios.post(AWS_API + 'master-data/record-cl-submission-review', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Submitted`);
            
            // update master data
            getMasterData(dataMap[mode].data, setData, true, true);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while submitting centrelink review data');
            console.log('error whilst submitting centrelink review data: ', err);
            setLoading(false);
        }          
    }

    // function to handle front-end interaction with CCS services via app/centrelink lambda middleman
    const postToAppCentrelinkLambda = async (route, ids) => {
        setLoading(true);

        // get rows relating to id
        let rowsToSend;
        if (ids) {
            rowsToSend = data.filter(obj => ids.includes(obj.id));
            console.log('rowsToSend: ', rowsToSend);
        }

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rows: rowsToSend,
        };

        console.log('postData: ', postData);
        try {
            const res = await axios.post(AWS_API + 'ccs/' + route, {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Request submitted`);
            
            // update master data
            getMasterData(dataMap[mode].data, setData, true, true);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while submitting centrelink review data');
            console.log('error whilst submitting centrelink review data: ', err);
            setLoading(false);
        }  
    }

    // add in title content/buttons depending on which view mode we're in
    let header;
    if (showContent.pendingISCaseClaims) {
        header = (
            <Fragment>
                <h3>Upcoming IS Case Claims</h3>
                <h6><i>{weekStarting} to {weekEnding}</i></h6>
                <p><i>In approving the following submissions, you are agreeing to the following statement: "I declare that the Additional Educator/s listed were engaged to increase the staff to child ratio above that required by state or territory licensing and regulatory requirements"</i></p>
                <div className={classes.HeaderButtonRow} >
                    <button className="btn btn-danger btn-sm" onClick={() => postSubmissionReview(selected,  mode, 'pending_is_case_claim_submissions', 'hold')}>Hold Submissions</button>
                    <button className="btn btn-success btn-sm" onClick={() => postSubmissionReview(selected,  mode, 'pending_is_case_claim_submissions', 'approved')}>Approve Submissions</button>
                </div>                
            </Fragment>
        );
    } else if (showContent.pendingSessionReports) {
        header = (
            <Fragment>
                <h3>Upcoming Session Reports</h3>
                <h6><i>{weekStarting} to {weekEnding}</i></h6>
                <div className={classes.HeaderButtonRow} >
                    <button className="btn btn-danger btn-sm" onClick={() => postSubmissionReview(selected, mode,  'pending_session_reports', 'hold')}>Hold Submissions</button>
                    <button className="btn btn-success btn-sm" onClick={() => postSubmissionReview(selected, mode,  'pending_session_reports', 'approved')}>Approve Submissions</button>
                </div>                
            </Fragment>      
        );
    } else if (showContent.submittedISCaseClaims) {
        header = (
            <Fragment>
                <h3>Submitted IS Case Claims</h3>   
                <div className={classes.HeaderButtonRow} >
                    <button className="btn btn-warning btn-sm" onClick={() => postToAppCentrelinkLambda('resubmit-is-claims', selected)}>Resubmit Case Claims</button>
                    <button className="btn btn-info btn-sm" onClick={() => postToAppCentrelinkLambda('validate-is-claims', selected)}>Validate Case Claims</button>
                </div>          
                <p>
                    <i className="text text-warning">Note: Resubmitting will resubmit any selected case/week combination, inclulding both face-to-face and non face-to-face claims where applicable</i>
                    <br/>
                    <i className="text text-info">Note: Validation will be attempted for selected Case Claim IDs only</i>
                </p>
            </Fragment>      
        );
    } else if (showContent.submittedSessionReports) {
        header = (
            <Fragment>
                <h3>Submitted Session Reports</h3>    
                <div className={classes.HeaderButtonRow} >
                    <button className="btn btn-danger btn-sm" onClick={() => postSubmissionReview(selected,  mode, 'pending_session_reports', 'hold')}>Hold Submissions</button>
                    <button className="btn btn-success btn-sm" onClick={() => postSubmissionReview(selected, mode,  'pending_session_reports', 'approved')}>Approve Submissions</button>
                    <button className="btn btn-warning btn-sm" onClick={() => postToAppCentrelinkLambda('resubmit-session-reports', selected)}>Resubmit Failed Submissions</button>
                    <button className="btn btn-info btn-sm" onClick={() => postToAppCentrelinkLambda('validate-session-reports', selected)}>Validate Session Reports</button>
                </div>                            
                <p>
                    <i className="text text-warning">Note: Resubmitting will resubmit all failed session reports, which aren't held, between the earliest start date and latest end date selected. To stop a row being resubmitted, select row and click "Hold Submissions"</i>
                    <br/>
                    <i className="text text-info">Note: Validation will be attempted for all session reports between the first period-start and the last period-end selected</i>
                </p>
            </Fragment>      
        );
    }

    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Fragment>
                {header}
                {data && detailData 
                ? <div >
                    <BSTable selected={selected} setSelected={setSelected} table={dataMap[mode].data} data={data} detailData={detailData}/> 
                </div>
                : null}           
            </Fragment>
        );
    }

    return (
        <Fragment >
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <Banner show={bannerText}>{bannerText}</Banner>                

            <div className={classes.Wrapper}>
                
                {/* Left nav bar */}
                <div className={classes.LeftBar}>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('pendingISCaseClaims')}>Upcoming Case Claims</button>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('pendingSessionReports')}>Upcoming Session Reports</button>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('submittedISCaseClaims')}>Submitted Case Claims</button>
                    <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent('submittedSessionReports')}>Submitted Session Reports</button>
                </div>

                {/* main content */}
                <div className={classes.Content} >
                    <div className={classes.CentreLink}>
                        {content}
                        {/* infinite scroll detection */}
                        <div ref={ref}>
                            {tableLoading && <SmallSpinnerDark />}
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
}

export default CentreLink;