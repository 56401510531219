import { useState, useEffect, useCallback } from 'react';
import classes from './Cognito.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBanner } from '../../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';

const Cognito = (props) => {

    // hoc state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [showBanner, setShowBanner] = useState(false); 
    const [bannerText, setBannerText] = useState('');      
    
    // data state
    const [allUserAccounts, setAllUserAccounts] = useState();
    const [cognitoGroups, setCognitoGroups] = useState();

    // UI state
    const [selectedEmail, setSelectedEmail] = useState();
    const [selectedGroup, setSelectedGroup] = useState();

    // create react-router history object to navigate user to other pages
    const history = useHistory();  

    
    // on component load get users and groups from cognito
    const getMasterData = useCallback(async () => {
        setLoading(true);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'all_user_accounts'}
        };
        
        // get all_user_accounts
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            setAllUserAccounts(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching all user accounts');
        } 
        
        // get cognito groups
        config.params = {userpoolId: cognitoUserpoolId}
        try {
            const res = await axios.get(AWS_API + 'cognito/list-groups', config);
            setCognitoGroups(res.data.groups);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while getting Cognito groups');
        }            
        

        setLoading(false);
    }, [props.token, history]);


    useEffect(() => {

        getMasterData();
    }, [getMasterData]);



    console.log('allUserAccounts: ', allUserAccounts);
    console.log('cognitoGroups: ', cognitoGroups);


    // function to send username and new group to update a user's cognito group
    const updateGroups = async () => {

        setLoading(true);

        // get username from email
        const username = allUserAccounts.filter(obj => obj.email === selectedEmail)[0].username;

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            username: username,
            userpoolId: cognitoUserpoolId,
            group: selectedGroup
        };
        try {
            const res = await axios.post(AWS_API + 'cognito/update-group', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBanner(setShowBanner, setBannerText, 'Group updated')
            getMasterData();
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error while updating user groups');
            setLoading(false);
        }     
        
        setLoading(false);
    }


    // UI to show user accounts for which to change group
    let ui;
    if (allUserAccounts && cognitoGroups) {
        
        // if email has been selected, get old group for that user
        let oldGroup;
        if (selectedEmail) {oldGroup = allUserAccounts.filter(obj => obj.email === selectedEmail)[0].account_type}
        
        // generate html for UI
        ui = (
            <div className={classes.Form}>
                <h5>Update Cognito Groups</h5>
                <br />
                <div className={classes.Row}>
                    <span className={classes.Label}>Email</span>
                    <select defaultValue="Select Email" className={[classes.Select, 'form-control'].join(' ')} value={selectedEmail} onChange={(e) => setSelectedEmail(e.target.value)} >
                        {[<option key="default" disabled>Select Email</option>].concat(allUserAccounts.map(obj => <option key={obj.email} value={obj.email} >{obj.email}</option>))}
                    </select>
                </div>
                <div className={classes.Row}>
                    <span className={classes.Label}>Old Group</span>
                    <select className={[classes.Select, 'form-control'].join(' ')} value={oldGroup} disabled >
                        <option value={oldGroup} >{oldGroup}</option>
                    </select>
                </div>
                <div className={classes.Row}>
                    <span className={classes.Label}>New Group</span>
                    <select defaultValue="Select Group" className={[classes.Select, 'form-control'].join(' ')} value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)} >
                        {[<option key="default" disabled>Select Group</option>].concat(cognitoGroups.map(el => <option key={el} value={el} >{el}</option>))}
                    </select>
                </div>
                <br />
                <button className='btn btn-outline-success btn-block'onClick={updateGroups}>Submit</button>
            </div>
        ); 

    }
    
    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <div>
                {/* <button className="btn btn-outline-secondary" onClick={test}>Test</button> */}
                {ui}
            </div>
        ); 
    }
    

    return (
        <div className={classes.Cognito}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>    
            <Banner show={showBanner}>{bannerText}</Banner>                
            {content}
            {/* <button className='btn btn-outline-warning btn-block' onClick={test}>Test </button> */}

        </div>
    );
}

export default Cognito;