import { React, useEffect, useState } from 'react';
import axios from 'axios';
import classes from './SearchContacts.module.css';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
import { handleAwsApiError } from '../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../UI/Modal/Modal';


const SearchContacts = (props) => {
    
    // data state
    const [name, setName] = useState('');
    const [eligbileUsers, setEligibleUsers] = useState();

    // hoc state
    const [error, setError] = useState();    

    // create react-router history object to navigate user to other pages
    const history = useHistory();    

    // when component renders, bring back list of people authorised to collect a child at given centre
    useEffect(() => {

        const getEligibleUsers = async () => {

            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            };
            try {
                const res = await axios.get(AWS_API + 'kiosk/' + props.route, config);
                console.log('response: ', res.data);
                setEligibleUsers(res.data);
            } catch (err) {
                setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
            }   
        }

        getEligibleUsers();
    }, [ history, props.token, props.route, setError]);

    // console.log('eligible users: ', eligbileUsers);
    // console.log('name: ', name);

    // return matching people based on user search and eligible contacts stored in state
    let rows;
    if (eligbileUsers) {
        rows = eligbileUsers.filter(obj => {
            const fn = obj.first_name.toLowerCase();
            const sn = obj.surname.toLowerCase();

            // console.log('name: ', name);
            // console.log('fn: ', fn);
            // console.log('sn: ', sn);

            return (name && (fn.includes(name) || sn.includes(name)))
        }).map((obj, index) =>  {

            const last4digits = '******' +  obj.phone_number.substr(7,9);
    
            return (
                <tr key={index} className={classes.ClickableRow} onClick={() => props.setUserData(obj)}>
                    <td>{obj.first_name}</td>
                    <td>{obj.surname}</td>
                    <td>{last4digits}</td>
                </tr>
            );
        });
    }

    return (
        <div className="form-group">
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>              

            <h4>Register New User</h4>
            <hr />
            <p>User must have collection authorisation for a child registered at this centre.</p>
            <input style={{fontSize: '1.3rem', marginBottom: '10px'}} autoFocus className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
            {rows && rows.length > 0 && <table className="table table-striped table-hover">
                <thead >
                    <tr>
                        <th>First Name</th>
                        <th>Surname</th>
                        <th>Phone Number</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}  
                </tbody>
            </table>}
        </div>
    );
}

export default SearchContacts;
