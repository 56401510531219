import './App.css';

import { React, useState, useEffect, useRef } from 'react';
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import 'url-search-params-polyfill';
// import axios from 'axios';

import Layout from './hoc/Layout/Layout';
import Enrol from './Components/Enrolment/Enrol/Enrol';
import Approvals from './Components/Admin/Approvals/Approvals';
import Auth from './Components/Auth/Auth';
import Login from './Components/Auth/Login/Login';
import MasterData from './Components/Admin/MasterData/MasterData';
import AccountOwner from './Components/AccountOwner/AccountOwner';
import Kiosk from './Components/Kiosk/Kiosk';
import CheckIns from './Components/AccountOwner/CheckIns/CheckIns';
import AdminMgmt from './Components/Admin/AdminMgmt/AdminMgmt';
import QRScanner from './Components/AccountOwner/QRScanner/QRSCanner';
import Roll from './Components/Admin/Roll/Roll';
import CentreLink from './Components/Admin/CentreLink/CentreLink';
// import PhotoUpload from './Components/Kiosk/PhotoUpload/PhotoUpload';
// import Spinner from './UI/Spinner/Spinner';

function App() {

  // hooks for dealing with URLs 
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState(); 
  const [role, setRole] = useState(); 

  // token strategy: pass through props to components that definitely need it to make API calls, use localStorage for components like
  // Auth.js which may be redirected to after an API call fails or a refresh (i.e. localRefreshToken will come from localstorage)
  const [token, setToken] = useState(); 

  // PWA state
  const [showInstallPrompt, setShowInstallPrompt] = useState();
  const [showInstallInstruct, setShowInstallInstruct] = useState();

    console.log('[App.js] - rendered');

  //---------------------------------------------------------------------------------------------------------------------------------
  // PWA SETUP
  //---------------------------------------------------------------------------------------------------------------------------------

    let installPrompt = useRef();

    useEffect(() => {
        console.log("Listening for Install prompt");
        window.addEventListener('beforeinstallprompt',e => {
          // For older browsers
          e.preventDefault();
          console.log("Install Prompt fired");
          installPrompt.current = e;
          // See if the app is already installed, in that case, do nothing
          if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
            return false;
          }
          // Set the state variable to make button visible
          setShowInstallPrompt(true);
        });
    
        // Checks if should display install instructions in sidebar
        if (isIos() && !isInStandaloneMode()) {
          setShowInstallInstruct(true);
        }

    }, []);


    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);


    const installApp = async () => {
        if(!installPrompt.current) return false;
            installPrompt.current.prompt();
            let outcome = await installPrompt.current.userChoice;
        if(outcome.outcome === 'accepted'){
            console.log("App Installed")
        }
        else{
            console.log("App not installed");
        }
        // Remove the event reference
        installPrompt.current = null;
        // Hide the button
        setShowInstallPrompt(false);
    }
    

  //---------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------



  // signout function
  const signout = () => {
    history.replace("/login");
    setEmail();
    setRole();
    localStorage.removeItem('jwt-token');
    localStorage.removeItem('jwt-token-refresh');
  }

  // check if we've been redirected here from Cognito with a grant code, and if not check if user has a token in local storage or a refresh token to auth with rather than making them sign into Cognito again
  let cognitoGrantCode = useRef();
  let localRefreshToken = useRef();
  let localToken = useRef();
  useEffect(() => {
    const parsedURL = new URLSearchParams(location.search)
    cognitoGrantCode.current = cognitoGrantCode.current ?? parsedURL.get("code");
    if (cognitoGrantCode.current && parsedURL.get("code")) {
  
      // code found, so redirect to auth with code to authenticate user
      // setCode(cognitoGrantCode);
      console.log('code found: ', cognitoGrantCode);
      console.log('calling history/replace("auth")');
      // setRole('beingAuthenticated');
      history.replace("/auth");
    } else {
        localRefreshToken.current = localStorage.getItem("jwt-token-refresh");
        localToken.current = localStorage.getItem("jwt-token");

        // if there's no grant code and there is a token or refresh token, force redirect to Auth where Auth component will see there's no code in props, but there is a refresh token in local storage
        if (localRefreshToken.current || localToken.current) {
            console.log('[App.js] - local token or refresh token found, redirecting to auth with said token');
            history.replace('/auth');
        } else {
            console.log('[App.js] - no code found and no tokens found, continuing with component render')
        }
  
    }
  }, [location.search, history])

  // if we didn't get here via cognito with a grant code, check if user has a token in local storage or a refresh token to auth with rather than making them sign into Cognito again
//   let localRefreshToken = useRef();
//   let localToken = useRef();
//   useEffect(() => {
//     console.log('[localRefreshToken useEffect]');
//     if (!cognitoGrantCode.current) {
//     //   localRefreshToken.current = localStorage.getItem("jwt-token-refresh");
//     //   localToken.current = localStorage.getItem("jwt-token");

//       // if there's no grant code and there is a refresh token, force redirect to Auth where Auth component will see there's no code in props, but there is a refresh token in local storage
//       if (localRefreshToken.current || localToken.current) {
//         console.log('[App.js] - local token or refresh token found, redirecting to auth with said token');
//         history.replace('/auth');
//       }
//     }
//   }, [history]);

  const updateAuthState = (email, groups, token) => {
    
    if (groups.includes('headOffice')) {
      history.replace('/approvals');
      setToken(token);
      setRole('Head Office');
      setEmail(email);
      console.log('set role to headOffice and redirected to /approvals');
    } else if (groups.includes('accountOwner')) {
      history.replace('/account');
      setToken(token);
      setRole('Account Owner');
      setEmail(email);
      console.log('set role to accountOwner and redirected to /account');
    } else if (groups.includes("centreAdmin")) {
      history.replace('/roll');
      setToken(token);
      setRole('Centre Admin');
      setEmail(email);
      console.log('set role to centreAdmin and redirected to /roll');
    } else if (groups.includes("kiosk")) {
      history.replace('/');
      setToken(token);
      setRole('Kiosk');
      setEmail(email);
      console.log('set role to Kiosk and redirected to /');
    } else {
      setRole('Guest');
      setToken(token);
      setEmail(email);
    }
  }
  
  
  let routes = null;

  switch (role) {
    case 'Guest':
      routes = (
        <Switch>
        </Switch>     
      );
      break;
    case 'Kiosk':
      routes = (
        <Switch>
          <Route path="/kiosk" render={(props) => <Kiosk {...props} token={token} />} /> 
          <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
          <Redirect to="/kiosk" />          
        </Switch>     
      );
      break;
      case 'Account Owner':
          routes = (
        <Switch>
          {/* token being passed in as props here for authorisation in API calls */}
          <Route path="/account" render={(props) => <AccountOwner {...props} token={token} email={email} />} /> 
          <Route path="/qrscanner" render={(props) => <QRScanner {...props} token={token} />} /> 
          <Route path="/checkin" render={(props) => <CheckIns {...props} token={token} />} /> 
          <Route path="/enrol" render={(props) => <Enrol {...props} email={email} token={token}/>} /> 
          <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />

          <Redirect to="/account" />
        </Switch>  
      );
      break;
      case 'Centre Admin':
          routes = (
              <Switch>
          {/* <Route path="/approvals" render={(props) => <Approvals {...props} email={email} token={token}/>} /> */}
          <Route path="/masterData" render={(props) => <MasterData {...props} token={token} />} />
            {/* <Route path="/photos" render={(props) => <PhotoUpload {...props} token={token} />} />  */}
          <Route path="/roll" render={(props) => <Roll {...props} token={token} />} />
          <Route path="/enrol" render={(props) => <Enrol {...props}  />} />
          <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
          <Redirect to="/roll" />
        </Switch>  
      );
      break;
      case 'Head Office':
          routes = (
            <Switch>
                <Route path="/approvals" render={(props) => <Approvals {...props} email={email} token={token}/>} />
                <Route path="/masterData" render={(props) => <MasterData {...props} token={token} />} />
                <Route path="/centreLink" render={(props) => <CentreLink {...props} token={token} />} />
                <Route path="/roll" render={(props) => <Roll {...props} token={token} />} />
                <Route path="/enrol" render={(props) => <Enrol {...props}  />} />
                <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
                <Route path="/mgmt" render={(props) => <AdminMgmt {...props} token={token} />} /> 
                <Redirect to="/approvals" />
            </Switch>     
      );
      break;
    // case 'beingAuthenticated':
    //   routes = (
    //     <Switch>
    //       <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current} />} />
    //       <Redirect to="/auth" />
    //     </Switch>     
    //   );
    //   break;
    default:
      routes = (
        <Switch>
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
          {/* <Route path="/enrol" render={(props) => <Enrol {...props}  />} /> */}
          <Redirect to="/login" />
        </Switch>
      );
    
  }


  // function to deal with S3 routing issues - S3 will redirect URL paths to /#/pathname, and this function will set route accordingly
  // const replaceHashPath = () => {
  //   const hash = location.hash;

  //   // console.log('location: ', location);
  //   // console.log('hash: ', hash);
  //   if (hash) {
  //       console.log('checking if need to replace path: ', hash);
  //       const path = hash.replace(/^#/, '')
  //       if (path) {
  //         console.log('replacing path with: ', path);
  //         setTimeout(() => history.replace(path), 10);
  //         // history.replace(path);
  //       }
  //     }
  //   }
  //   replaceHashPath();
    // console.log('location: ', location);

  return (

    // <Suspense fallback={<Spinner />}>
      
      <div className="App">
        {/* <Router> */}
          <Layout 
            email={email} 
            role={role} 
            signout={signout}
            showInstallPrompt={showInstallPrompt} 
            showInstallInstruct={showInstallInstruct}
            accepted={installApp} 
            dismissed={() => setShowInstallPrompt(false)}
          >

            {routes}
          </Layout>
        {/* </Router> */}
      </div>

    // </Suspense>
  );
}

export default App;
