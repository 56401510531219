import {React,  useState} from 'react';
import classes from './BSTable.module.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter  } from 'react-bootstrap-table2-filter';
import { bootstrapTableConfig } from '../../utilities/globalObjects';

// turn db names into more readable text
const humanize = (str) =>  {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}


const BSTable = (props) => {

    // UI state
    const [expanded, setExpanded] = useState([]);

    // get data from props
    const data = props.data;
    const detailData = props.detailData;
    const selected = props.selected;
    const setSelected = props.setSelected;
    const table = props.table;

    // select options as Function
    const selectOptions = (column) => {
        const dataField = column.dataField;

        const returnArr = [];
        const uniqueValues = data.map(obj => obj[dataField]).filter(el => el).filter((x, i, a) => a.indexOf(x) === i);
        uniqueValues.forEach(el => {
            returnArr.push({
                value: el,
                label: el
            })
        });
        
        return  returnArr;


    };

    // construct columns
    const columns = [];
    const detailColumns = [];
    Object.keys(data[0]).forEach(key => {
        
        // add column to list of columns to display if not specified in exclusions
        if (!bootstrapTableConfig[table]?.excludeColumns.includes(key)) {
            
            columns.push({
            dataField: key, 
            text: humanize(key), 
            sort: true, 
            filter: selectFilter({
                options: selectOptions,
                  className: classes.SelectFilter
                })
            });
        }
    });
    Object.keys(detailData[0]).forEach(key => {
        detailColumns.push({dataField: key, text: humanize(key)})
    });

    // console logs
    console.log('data: ', data);
    // console.log('selected: ', selected);
    console.log('detailData: ', detailData);
    // console.log('expanded: ', expanded);



    // function to handle single row selects
    const handleOnSelect = (row, isSelect) => {
        // console.log('row: ', row);
        if (isSelect) {
          setSelected([...selected, row.id]);
        } else {
          setSelected(selected.filter(x => x !== row.id));
        }
    }
    
    // function to handle all row selects
    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids);
        } else {
            setSelected([]);
        }
    }

    const handleOnExpand = (row, isSelect) => {
        // console.log('row: ', row);
        if (isSelect) {
          setExpanded([row.id]);
        } else {
            setExpanded(expanded.filter(x => x !== row.id));
        }
    }

    // object bootstrap table needs to handle selects
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        clickToExpand: true,
        selected: selected,
        onSelect:  handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    
    const expandRow = {
        renderer: row => (
        //   <div>
        //     {detailData.filter(obj => expanded.includes(obj.id)).map(obj => <p>{obj.child} - {obj.date} - {obj.session} - {obj.hours} hours - {obj.status}</p>)}
        //   </div>
            <BootstrapTable keyField='id' data={ detailData.filter(obj => expanded.includes(obj.id)) } columns={detailColumns} condensed/>         
        ),
        expanded: expanded,
        onExpand: handleOnExpand
    };



    // console.log('columns: ', columns);
          
    let tableContent = (
        <BootstrapTable 
            keyField='id' 
            data={ data } 
            columns={ columns }
            expandRow={ expandRow }
            selectRow={selectRow} 
            classes={classes.Table}
            filter={ filterFactory() }
            striped 
            bootstrap4 
            bordered={ false }
            condensed
        />  
    );

    return tableContent;
}

export default BSTable;