import { React, useState } from 'react';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import classes from './Headcounts.module.css';


const Headcounts = (props) => {

    const [selectedEventTime, setSelectedEventTime] = useState();

    console.log('props.headcountdata: ', props.headcountData);

    const headcountEvents = props.headcountData.reduce((agg, curr) => {
        if (agg[curr.local_time]) {
        agg[curr.local_time] += 1
        } else {
        agg[curr.local_time] = 1
        }
        return agg
    }, {});
    console.log('headcountEvents: ', headcountEvents);

    console.log('selected event: ', selectedEventTime);


    let content = <SpinnerDark/>;
    if (props.headcountData) {
        content = Object.keys(headcountEvents).sort((a, b) => +a.slice(0,2) - +b.slice(0,2)).map((key, i) => {
            return (
                <div key={key} onClick={() => setSelectedEventTime(key)} className={classes.HeadcountBox}>
                    <h6>Headcount {i + 1} - {key}</h6>
                    <div className={classes.HeadcountRow}>
                        <span>{headcountEvents[key]} children present</span>
                        <span>Completed by {props.headcountData.filter(obj => obj.local_time === key)[0].email.split("@")[0]}</span>
                    </div>

                </div>

            );
        });
    }

    let detail;
    if (selectedEventTime) {
        detail = (
            <div style={{paddingTop: '10px'}}>
                <h5>Headcount @ {selectedEventTime}</h5>
                <ul>
                    {
                        props.headcountData.filter(obj => obj.local_time === selectedEventTime).map(obj => {
                            return (
                                <li key={obj.child_id}>{obj.first_name} {obj.surname}</li>
                            );
                        })
                    }
                </ul>

            </div>
        );
        
        
    }

    return (
        <div className={classes.Headcounts}>
            <h3>Existing Counts for Today</h3>
            <p> </p>
            {content}
            {detail}

        </div>
    )
}

export default Headcounts;