import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../UI/Modal/Modal';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import SessionSignIns from '../../Kiosk/SessionSignIns/SessionSignIns';
import classes from './Roll.module.css';
import PhotoUpload from '../../Kiosk/PhotoUpload/PhotoUpload';
import Banner from '../../../UI/Banner/Banner';
import Headcounts from './Headcounts/Headcounts';

const Roll = (props) => {

    // hoc state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [uploadPhotoChildId, setUploadPhotoChildId] = useState();
    const [bannerText, setBannerText] = useState();    

    // data state
    const [upcomingSessions, setUpcomingSessions] = useState();
    const [headcountData, setHeadcountData] = useState();
    // const [permissionSlipsData, setPermissionSlipsData] = useState();
    // const [showPermissionSlip, setShowPermissionSlip] = useState();

    // UI state
    const [showHeadcounts, setShowHeadcounts] = useState();


    // console logs
    console.log('upcomingSessions: ', upcomingSessions);
    console.log('headcount data: ', headcountData);
    // console.log('permissionSlipsData: ', permissionSlipsData);

    // create react-router history object to navigate user to other pages
    const history = useHistory();    

    // function to call API to get upcoming sessions data to display on kiosk roll summary for checkins + to call API to get outstanding permission slips
    const getUpcomingSessions = useCallback(async () => {
        setLoading(true);

        // upcoming sessions data
        let config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'view_roll_summary'}
        };
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            setUpcomingSessions(res.data);
            console.log('res: ', res.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
        }   
  
  
    }, [history, props.token]);


  
    // fetch account owner data for portal - linked children, permanent & casual bookings
    useEffect(() => {

        getUpcomingSessions();

    }, [getUpcomingSessions]);


    // function to update roll with check in, check out and absents
    const updateRoll = async (rollEntries, signedInUserId, updateType) => {
        console.log('checking child in for roll session, using authorised user ID: ', rollEntries, updateType);
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rollEntries: rollEntries,
            updateType: updateType
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/update-roll', {postData: postData}, headers);
            console.log('response: ', res.data);
            getUpcomingSessions();
            handleBannerText(setBannerText, `${updateType} marking recorded`);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while checking children in');
            setLoading(false);
        }         
    }

    // function to submit headcount event
    const submitRollEvent = async (rollEntries, event) => {
        console.log('submitting headcount event for: ', rollEntries);
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rollEntries: rollEntries,
            event: event
        };
        try {
            const res = await axios.post(AWS_API + 'master-data/record-event', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `${event} recorded`);
            getUpcomingSessions();
            
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while recording roll event ' + event);
            console.log('error whilst recording roll event: ', err);
            setLoading(false);
        }    
    }

    // function to get headcounts completed on given centre and day
    const getHeadcounts = async (centreId, date) => {
        setLoading(true);
        setShowHeadcounts(true);

        // upcoming sessions data
        let config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {centre_id: centreId, date: date.toLocaleDateString('sv-SW')}
        };
        console.log(`sending request to get headcounts data for centre: ${centreId} and date ${date}`);
        try {
            const res = await axios.get(AWS_API + 'master-data/day-centre-headcounts', config);
            console.log('headcounts data: ', res.data);
            setHeadcountData(res.data)
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching headcounts data');
        }   
    }

    
    // function to record roll as 'marked' in db for use in parent notifications
    const submitRollAsMarked = async (sessionDate, session, centre_id, status) => {

        setLoading(true);

        // upcoming sessions data
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {sessionDate: sessionDate, session: session, centre_id: centre_id, status: status};
        console.log('postData for submit Roll as Marked: ', postData);
        try {
            const res = await axios.post(AWS_API + 'master-data/record-roll-marking-status', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, 'Roll marked');
            getUpcomingSessions();
            
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while recording roll as marked');
            console.log('error whilst setting roll as marked: ', err);
            setLoading(false);
        } 
        
        
    }      


    const handlePhotoUpload = (rollEntry) => {
        if (rollEntry && rollEntry.length === 1) {
            setUploadPhotoChildId(rollEntry[0].child_id);
        } else {
            setError('Photos can only be uploaded for one child at a time');
        }
    }

    const onPhotoUploadDone = () => {
        setUploadPhotoChildId();
        getUpcomingSessions();
    }

    
    console.log('uploadchildId: ', uploadPhotoChildId);


    let content = <SpinnerDark />
    if (!loading && upcomingSessions) {
        content = <SessionSignIns adminFlag headcountData={headcountData} showHeadcounts={showHeadcounts} setShowHeadcounts={setShowHeadcounts} getHeadcounts={getHeadcounts} submitRollEvent={submitRollEvent} submitRollAsMarked={submitRollAsMarked} setError={setError} headcountAllowed checkinAllowed absentAllowed 
        uploadPhoto={handlePhotoUpload} token={props.token} multipleCentres upcomingSessions={upcomingSessions} updateRoll={updateRoll} />;
        // (
            // <div>
            //     <button onClick={getUpcomingSessions} className='btn btn-outline-info btn-block'>Get Test</button>
            //     <button onClick={updateRoll} className='btn btn-outline-info btn-block'>Post Test</button>
            // </div>
        // ); 
    }


    return (
        <div className={classes.Roll}>
            <Banner show={bannerText}>{bannerText}</Banner>    
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>  
            <Modal show={uploadPhotoChildId} modalClosed={() => setUploadPhotoChildId()}>
                <PhotoUpload token={props.token} uploadPhotoChildId={uploadPhotoChildId} completed={onPhotoUploadDone} setBannerText={setBannerText}/>
            </Modal>  
            <Modal show={showHeadcounts} modalClosed={() => setShowHeadcounts(false)}>
                {headcountData ? <Headcounts headcountData={headcountData} /> : <SpinnerDark />}
            </Modal>               
             
            {content}
        </div>
    );
}

export default Roll;