import { useState, useEffect, useCallback} from 'react';
// import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
// import { useHistory } from "react-router-dom";
// import Modal from '../../../UI/Modal/Modal';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import classes from './PermissionSlip.module.css';
import Formio from 'formiojs/Formio';
import { handleAwsApiError } from '../../../utilities/functions';
import {  formioAPI, AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import axios from 'axios';
// import Aux from '../../../hoc/Auxiliary/Auxiliary';
// import { raw } from '../../../translations/en/raw';

const PermissionSlip = (props) => {

    // state
    const [loading, setLoading] = useState(false);

    let selectedSession;
    if (props.permissionSlipData && props.permissionSlipData.length > 0) {
        selectedSession = props.permissionSlipData.filter(obj => obj.id === props.permissionSlipId)[0];
        // console.log('permission slip selectedSession: ', selectedSession);

    }

    const postSubmission = useCallback(async (submission) => {
        
        setLoading(true);
        console.log('posting submission: ', submission);

        // get necessary data from childrenData
        const accountId = props.childrenData.filter(obj => obj.child_id === submission.childId)[0].account_id;
        
        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {submission: submission, formType: 'permissionSlip', childId: submission.childId, formForDate: submission.date, accountId: accountId};
        
        try {
            const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
            console.log('response from postSubmission to S3: ', res.data);
            props.setPermissionSlipId();
            props.setTriggerRefresh(true);
            props.handleBanner('Permission Slip Submitted');

            // formId = res.data.formWrittenId;
        } catch (err) {
            setLoading(false);
            console.log('err: ', err);
            props.setPermissionSlipId();
            props.setError(handleAwsApiError(err, props.history) ?? 'Unexpected error encountered while recording form submission, transaction aborted');
            return false;
        }

        setLoading(false);


    }, [props]);    


    useEffect(() => {

        setLoading(true);

        if (selectedSession) {

            Formio.createForm(document.getElementById('formio'), formioAPI + 'permissionSlip' )
            .then((form) => {
        
                
                // populate permission slip data form selected roll item
                form.setSubmission({data: {childId: selectedSession.child_id, date: selectedSession.date}})
        
                // function to handle custom submission, check for validity and either warn user or submit form
                const customSubmit = (submission) => {
                    const isValid = form.checkValidity(null, true, null, false);
                    console.log('isValid: ', isValid);  
                    
                    // if form is invalid, figure out which fields haven't been touched and alert user, otherwise submit form
                    if (!isValid) {
                        form.setPristine(false);
                        
                        alert('form validation failed');
                    } else {
                        
                        // form passed validation, submit
                        console.log('posting submission: ', submission);
                        postSubmission(submission);
                    }
                }
        
                // add listener with custom functions
                form.on('CustomSubmit', (submission) => customSubmit(submission));
            })

            // wide array of other methods that can be used here, i.e. form.everyComponent
            // full list in docs here: https://help.form.io/developers/form-renderer 
        }


        // setTimeout(() => {
            setLoading(false);
        // }, 1000);

    }, [selectedSession, postSubmission])


    // display child name on form 
    let name;
    if (props.childrenData && selectedSession) {
        name = props.childrenData.filter(obj => obj.child_id === selectedSession.child_id)[0].first_name;
    }

    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <div className={classes.PermissionSlip}>
                <h4>Permission Slip{' - ' + name}</h4>
                <div id="formio"></div>
            </div>
        );
    }

    return content;
}

export default PermissionSlip;