// library imports
import { React, useEffect, useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-formio';
import DatePicker from "react-multi-date-picker";

// local imports
import classes from './AccountOwner.module.css';
import MasterDataTable from '../../UI/MasterDataTable/MasterDataTable';
// import TopDrawer from '../../UI/TopDrawer/TopDrawer';
import SpinnerDark from '../../UI/SpinnerDark/SpinnerDark';
import Spinner from '../../UI/Spinner/Spinner';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Modal from '../../UI/Modal/Modal';
import ScheduleView from './Bookings/ScheduleView/ScheduleView';
import Calendar from './Bookings/Calendar/Calendar';
import { AWS_API } from '../../utilities/globalVariables';
import { handleAwsApiError } from '../../utilities/functions';
import { raw } from '../../translations/en/raw';
import { cognitoClientId, cognitoUserpoolId, formioAPI
    // ascCutOffHour, bscCutOffHour, vacCutOffHour 
} from '../../utilities/globalVariables';
import { arraysMatch, handleBanner } from '../../utilities/functions';
import Notifications from './Notifications/Notifications';
import PermissionSlip from './PermissionSlip/PermissionSlip';
import Banner from '../../UI/Banner/Banner';
import ParentData from './ParentData/ParentData';

const AccountOwner = (props) => {

    // accountOwner master data state
    const [formSubmissionData, setFormSubmissionData] = useState();
    const [permBookingData, setPermBookingData] = useState();
    const [casualBookingData, setCasualBookingData] = useState();
    const [childrenData, setChildrenData] = useState();
    const [centreSessionData, setCentreSessionData] = useState();
    const [childGuardianAuthData, setChildGuardianAuthData] = useState();
    const [centresData, setCentresData] = useState();
    const [rollData, setRollData] = useState();
    const [permissionSlipData, setPermissionSlipData] = useState();
    const [excursionData, setExcursionData] = useState();
    const [guardianData, setGuardianData] = useState();
    // const [centreCapacities, setCentreCapacities] = useState();
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [bannerText, setBannerText] = useState('');    

    const [showCasualBookingForm, setShowCasualBookingForm] = useState(false);
    const [showChildrenDrawer, setShowChildrenDrawer] = useState(false);
    const [showCasualDrawer, setShowCasualDrawer] = useState(false);
    const [showPermDrawer, setShowPermDrawer] = useState(false);
    const [showUpdateBookingSchedule, setShowUpdateBookingSchedule] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [showCgaDrawer, setShowCgaDrawer] = useState(false);
    const [showCalendar, setShowCalendar] = useState(true);
    const [showParentData, setShowParentData] = useState(false);

    const [permissionSlipId, setPermissionSlipId] = useState();
    
    // casual booking form state
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedCentre, setSelectedCentre] = useState();
    const [selectedSession, setSelectedSession] = useState();
    // const [selectedDatesSQLFormat, setSelectedDatesSQLFormat] = useState();
    // const [remainingSpaces, setRemainingSpaces] = useState();
    // const [selectedChild, setSelectedChild] = useState();
    
    // console logs
    // console.log('showApprovedFormDetail: ', showApprovedFormDetail);
    // console.log('selectedApprovedForm: ', selectedApprovedForm);
    // console.log('selectedchildren: ', selectedChildren);
    console.log('selectedSession: ', selectedSession);
    // console.log('selectedCentre: ', selectedCentre);
    // console.log('childrenData: ', childrenData);
    // console.log('centresData: ', centresData);
    // console.log('rollData: ', rollData);
    // console.log('rollData: ', rollData);
    // console.log('permission slip id: ', permissionSlipId);


    // react router history instance to navigate user to other pages
    let history = useHistory();

    // upon accountowner render, send message to react native indicating user is authenticated
    useEffect(() => {
        try {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    messageType: 'accountOwnerAuthenticated',
                    email: props.email,
                    authorization: props.token, 
                    appclientid: cognitoClientId, 
                    userpoolid: cognitoUserpoolId
                }));
                alert('message posted to react native webView');

            }
        } catch (err) {
            console.log('error encountered while trying to send message to react native webView');
        }

    }, [props.email, props.token]);    


    // function to call API to get data
    const fetchData = useCallback(async (tableName) => {
        // if token found, call lambda function to account master data
        if (props.token) {
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {tableName: tableName}
            };
            try {
                const res = await axios.get(AWS_API + 'account-owner-data', config);
                // console.log('response: ', res.data);
                return res.data;
            } catch (err) {
                setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
            }   
        } else {
            console.log('no token, redirecting to auth');
            history.replace('/auth');
        }
    }, [history, props.token]);
    
    // fetch account owner data for portal - linked children, permanent & casual bookings
    useEffect(() => {

        // loading true before calling data fetch functions
        setLoading(true); 
          
        // anon async function to await the data response and put into state
        // (async () => setCentresData(await fetchData('centres')))(); // modified this to return form_approvals instead of just submissions - now shows all submitted forms by user and whether each has been approved
        // (async () => setRollData(await fetchData('roll')))(); // modified this to return form_approvals instead of just submissions - now shows all submitted forms by user and whether each has been approved
        // (async () => setFormSubmissionData(await fetchData('form_approvals')))(); // modified this to return form_approvals instead of just submissions - now shows all submitted forms by user and whether each has been approved
        // (async () => setPermBookingData(await fetchData('permanent_bookings')))();
        // (async () => setCasualBookingData(await fetchData('casual_bookings')))();
        // (async () => setChildGuardianAuthData(await fetchData('child_guardian_authorisations')))();
        // (async () => {
        //     setChildrenData(await fetchData('children')); 
        //     setLoading(false);
        // })();
        console.log('trigger refresh: ', triggerRefresh);
        
        (async () => {
            const parentPortalData = await fetchData('parent_portal_data');
            console.log('parentPortalData: ', parentPortalData);
            setCentresData(parentPortalData.centres);
            setRollData(parentPortalData.roll);
            setFormSubmissionData(parentPortalData.form_approvals);
            setPermBookingData(parentPortalData.permanent_bookings);
            setCasualBookingData(parentPortalData.casual_bookings);
            setChildGuardianAuthData(parentPortalData.child_guardian_authorisations)
            setChildrenData(parentPortalData.children); 
            setPermissionSlipData(parentPortalData.permission_slips_outstanding);
            setExcursionData(parentPortalData.excursions);
            setGuardianData(parentPortalData.guardianData);
            setLoading(false);
        })();

        // (async () => console.log('parentPortal fetch: ', await fetchData('parent_portal_data')))();

        // upon useEffect completion, set loading to false
        return () => {
            setLoading(false);
            setTriggerRefresh(false);
        }    

    }, [fetchData, triggerRefresh, setTriggerRefresh]);



    

    // whenever childrenData is loaded, default selectedChild to first enrolled child to save user always having to select their only child from a dropdown
    // useEffect(() => {
    //     if (childrenData && childrenData.length > 0) {
    //         setSelectedChild(childrenData[0].child_id);
    //     }
    // }, [childrenData])

    // whenever selected child changes, update selected center to their home centre
    // useEffect(() => {
    //     if (selectedChild) {
    //         setSelectedCentre(childrenData.filter(obj => obj.child_id === selectedChild)[0].home_centre_id);
    //     }
    // }, [selectedChild, childrenData])

    // whenever selected date or centre change, check remaining spots for booking form
    // useEffect(() => {

    //     const fetchData = async () => {
            
    //         // convert date from dd/mm/yyyy to yyyy-mm-dd - UNNECESSARY NOW AS HTML DATE INPUT PROVIDES DATA IN STD ISO YYYY-MM-DD FORMAT
    //         const datesToSubmit = [];

    //         for (const date of selectedDates) {
    //             const dateObj = date.toDate();
    //             const dateStr = dateObj.toLocaleDateString();
    //             const dateParts = dateStr.split("/");
    //             const dateSQLFormat = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    //             datesToSubmit.push(dateSQLFormat);
    //         }
            
    //         // save sql format of selected dates for later use
    //         setSelectedDatesSQLFormat(datesToSubmit);

    //         // get remaining spots
    //         // const config = {
    //         //     headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         //     params: {dates: JSON.stringify(datesToSubmit), centreId: selectedCentre}
    //         // };        
    //         // try {
    //         //     const res = await axios.get(AWS_API + 'remaining-centre-spaces', config);
    //         //     const data = res.data;
    //         //     console.log('remaining spots data: ', data);
    //         //     setRemainingSpaces(data);
    //         // } catch (err) {
    //         //     setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while attempting to calculate remaining session spaces');
    //         // }                 
    //     }

    //     // if both inputs are available call api
    //     if (selectedCentre && selectedDates.length > 0) {
    //         console.log('call API for spots');
    //         fetchData();
    //     }

    // }, [selectedDates, selectedCentre, props.token, history])

    // function to get calendar of allowed sessions for each centre for use in booking form
    const initiateNewCasualBooking = async (dateStr) => {
        setShowCasualBookingForm(!showCasualBookingForm);

        // if function received a date input (i.e. called by user clicking day in calendar) - set selected date
        setSelectedDates([new Date(dateStr)]);
        console.log('selected dates: ', selectedDates);

        // get centre_sessions_calendar data
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {tableName: 'centre_sessions_calendar'}
        };        

        try {
            const res = await axios.get(AWS_API + 'account-owner-data', config);
            const data = res.data;
            setCentreSessionData(data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching centre session calendar data');
        }          
    }

    // function which takes a date and centre and returns available sessions to book
    const getAvailableSessions = (dates, centre) => {

        // convert standard ISO format date from html date input to locale string for comparisons
        const dateStrings = dates.map(date => new Date(date).toLocaleDateString());

        // filter centre session calendar to match selected date and centre
        const filteredCentreSessionData = centreSessionData.filter(obj => dateStrings.includes(new Date(obj.date).toLocaleDateString()) && obj.centre_id === centre);
        console.log('filtered centre session data: ', filteredCentreSessionData);

        // check if available sessions are in common for selected dates
        let openSessions = [];
        if (filteredCentreSessionData.length > 0) {

            // get sessions for each of the selected dates
            const x = filteredCentreSessionData.map(obj => [obj.asc, obj.bsc, obj.vac]);
            // console.log('x: ', x);

            // check each day has the same sessions open
            let allDaysEqual = true;
            x.forEach(arr => {
                allDaysEqual = arraysMatch(x[0], arr) && allDaysEqual;
            })

            if (allDaysEqual) {
                console.log('all selected days have the same sessions, so allowing booking for one of those sessions for multiple days');
                const obj = filteredCentreSessionData[0];
                openSessions = Object.keys(obj).filter(key => obj[key] === true);
            } else {
                alert('selected days are running different sessions, please only select dates that are either all vacation or all school days');
            }

        } else {
            console.log('No available sessions found in calendar for selected date, setting to empty array');
        }

        // old logic to do a front end check on cutoff time for booking sessions - has been moved serverside

        // // local current date to use when checking cut-off time for sessions
        // const today = new Date().toLocaleDateString();
        // const hourNow = new Date().getHours();
        // console.log('today: ', today);
        
        // // filter calendar object to only show sessions where available = true, and remove any for which we're past the cutoff time
        // const openSessions = Object.keys(filteredObj).filter(key => {
            
        //     // check current booking isn't past cutoff time if it's for today
        //     const withinCutOffTime = {bsc: true, asc: true, vac: true};
        //     if (dateString === today && hourNow >= bscCutOffHour) {withinCutOffTime['bsc'] = false}
        //     if (dateString === today && hourNow >= ascCutOffHour) {withinCutOffTime['asc'] = false}
        //     if (dateString === today && hourNow >= vacCutOffHour) {withinCutOffTime['vac'] = false}
        //     return filteredObj[key] === true && withinCutOffTime[key] === true;
        // });

        return openSessions;
    }

    // function to write new casual booking to RDS
    const writeCasualBooking = async () => {

        // convert date from DateObj in DatePicker to YYYY-MM-DD format needed in lambda
        
        const datesToSubmit = [];
        for (const date of selectedDates) {
            const dateObj = typeof date.toDate === 'function' ? date.toDate() : date;
            const dateStr = dateObj.toLocaleDateString();
            const dateParts = dateStr.split("/");
            const dateSQLFormat = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
            datesToSubmit.push(dateSQLFormat);
        }        
            
        // write booking to RDS
        console.log('running write casual booking fn');
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {childIds: selectedChildren, centreId: selectedCentre, dates: datesToSubmit, session: selectedSession, requestType: 'write'};
        setLoading(true); 
                
        try {
            const res = await axios.post(AWS_API + 'bookings/write', postData, headers);
            const data = res.data;
            console.log('write-booking response: ', data);
          
            // re-fetch casual booking data to show updated booking
            (async () => {
                setCasualBookingData(await fetchData('casual_bookings')); 
            })();   
            
            setSelectedCentre();
            setSelectedChildren([]);
            setSelectedDates();
            // setSelectedDatesSQLFormat();
            setSelectedSession();
            setShowCasualBookingForm(false);
            
        } catch (err) {
            setShowCasualBookingForm(false);
            setSelectedSession();
            setError(handleAwsApiError(err, history) ?? 'Booking could not be completed, check child is not already booked in for this date and session.');
        }   
        
        setLoading(false);
    }



    // function to pass to MasterDataTable to delete a row from parent portal data
    const deleteRowHandler = async (row) => {

        // write booking to RDS
        console.log('request to delete row: ', row);
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {childIds: [row.child_id], cancelBookingId: row.booking_id, requestType: 'cancel'};
        setLoading(true); 
                
        try {
            const res = await axios.post(AWS_API + 'bookings/write', postData, headers);
            const data = res.data;
            console.log('data: ', data);
          
            // re-fetch casual booking data to show updated booking
            (async () => {
                setCasualBookingData(await fetchData('casual_bookings')); 
            })();   
            
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Booking could not be cancelled at this time');
        }   
        
        setLoading(false);        
    }

    // create array of available children to select from and take actions with
    let childOptions;
    if (childrenData) {
        // create input options for children of signed in user
        childOptions = childrenData.map(obj => {
            //  <option value={obj.child_id} key={obj.child_id}>{obj.first_name}</option>
            return (
                // <Aux>
                <div key={obj.child_id} className="form-check" >
                    <input 
                        checked={selectedChildren.includes(obj.child_id)} 
                        onChange={() => selectedChildren.includes(obj.child_id) ? setSelectedChildren([...selectedChildren].filter(el => el !== obj.child_id)) : setSelectedChildren([...selectedChildren, obj.child_id])} 
                        className="form-check-input" 
                        type="checkbox" 
                        value={obj.child_id} id="flexCheckDefault"
                    />
                    <label className="form-check-label">{obj.first_name}</label>
                </div>                    
                // </Aux>

            );
        });
    }    

    // if selectedChildren array has > 0 selected children, look for a shared home centre - otherwise remove home centre
    let selectedHomeCentreIds = null;
    if (selectedChildren.length > 0) {
        selectedHomeCentreIds = [...new Set(childrenData.filter(obj => selectedChildren.includes(obj.child_id)).map(obj => obj.home_centre_id))];
        // console.log('selectedHomeCentreIds: ', selectedHomeCentreIds);
        
        // if there's only unique centre id for all children, add it to lookup array to allow booking, otherwise user will have to select only children who share a home centre
        if (selectedHomeCentreIds.length === 1) {
            const homeCentreId = selectedHomeCentreIds[0];
            if (selectedCentre !== homeCentreId) setSelectedCentre(homeCentreId);
            
        } else {
            if (selectedCentre) setSelectedCentre('');
        }
    } else {
        if (selectedCentre) setSelectedCentre('');
    }
    
    // create options arrays to render casual booking form - select date and select centre
    let casualBookingForm = null;
    if (centreSessionData && showCasualBookingForm && childrenData) {
        
        // create input options for centres showing both id and name, but filter to only show selected child's home centre
        
        // get id and name of selected centre to display to user
        const centreIdNameLookup = {};
        if (selectedCentre) {
            centreIdNameLookup[selectedCentre] = centresData.filter(obj => obj.centre_id === selectedCentre)[0].centre_name;
        }
        
        // get unique list of centreIDs from calendar 
        // const centreIds = centreSessionData.map(obj => obj.centre_id).filter((x, i, a) => a.indexOf(x) === i).filter(id => id === homeCentreId);
        // console.log('centre Ids: ', centreIds);

        
        // create lookup object so we can show user both id and name of centre for selected child's home centre
        // homeCentreId.forEach(id => centreIdNameLookup[id] = centreSessionData.filter(obj => obj.centre_id === id)[0].centre_name);
        
        // create array showing only child's home centre
        const centreOptions = Object.keys(centreIdNameLookup)
        .map(centre_id => {
            return (
                <option  value={centre_id} key={centre_id} >{centre_id} - {centreIdNameLookup[centre_id]}</option>

            );
        });
        // centreOptions.push(<option selected  key={'default'} disabled>Select Centre</option>);

        // console.log('selected centre: ', selectedCentre);
        // console.log('selected dates: ', selectedDates);
            
        // form inputs for date and centre    
        casualBookingForm = (
                <div> 
                    <div className="form-group" >
                        {/* <label>Select Children</label> */}
                        {/* <select className="form-control" id="childOptions" value={selectedChild} onChange={(e) => {setSelectedChild(+e.target.value)}}>
                            {childOptions}
                        </select> */}

                        {childOptions}
                    </div>  
                    <div className="form-group">
                        {/* <input className="form-control" type="date" value={selectedDates} onChange={(e) => {setSelectedDates(e.target.value)}}/> */}
                        <DatePicker inputClass="form-control" placeholder="Select Date" value={selectedDates} fixMainPosition={true} format='YYYY-MM-DD' multiple onChange={(e) => {setSelectedDates(e); setSelectedSession();}}/>
                    </div>
                    <div className="form-group">
                        <select className="form-control" disabled id="centreOptions" value={selectedCentre} onChange={(e) => {setSelectedCentre(+e.target.value)}}>
                            {centreOptions}
                        </select>
                    </div>
                </div> 

        );
    }

    // if both a centre and a date have been selected and we've received the availability data back from AWS lambda, get available sessions from calendar
    let sessionForm;
    if (selectedCentre && selectedDates && showCasualBookingForm) {
        
        // get available sessions from centre session calendar data
        const openSessions = getAvailableSessions(selectedDates, selectedCentre);
        console.log('openSessions: ', openSessions);
        // console.log('remaining spaces: ', remainingSpaces);

        // const minSpaces = Object.values(remainingSpaces).filter(obj => openSessions.)
        // console.log('minSpaces: ', minSpaces);

        // render input with available sessions and show remaining spots for each
        const sessionOptions = openSessions.map(session =>  <option  key={session} value={session} >{raw[session]}</option>);
        sessionOptions.push(<option key={'default'} value="select session" disabled>Select Session</option>);
        
        // set up html with session options
        sessionForm = (
            <div> 
                <div className="form-group">
                    <select className="form-control" id="sessionOptions" value={selectedSession ?? 'select session'} onChange={(e) => {setSelectedSession(e.target.value)}}>
                        {sessionOptions}
                    </select>
                </div>
                <button onClick={writeCasualBooking} disabled={!(selectedCentre && selectedDates && selectedSession)} className="btn btn-block btn-success">Submit</button>
            </div> 

        );

    }


    // function to post form to pending form submissions table in RDS and save submission data to S3
    const postSubmission = async (submission) => {
        
        setLoading(true);
        
        // send submission to server to record in RDS and then save in S3, aborting transaction if any error encountered
        console.log('posting submission: ', submission);
        
        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {submission: submission, childId: submission.childIds[0], centreId: submission.centreId, formType: 'updateBookingSchedule'};
        
        try {
            const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
            console.log('response from postSubmission to S3: ', res.data);
            setSelectedChildren([]);
            setShowUpdateBookingSchedule(false);
            (async () => {
                setFormSubmissionData(await fetchData('form_submissions')); 
            })();               
            handleBanner(setShowBanner, setBannerText, 'requestSubmitted')
        } catch (err) {
            setShowUpdateBookingSchedule(false);
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while recording form submission in RDS');
        }
        
        setLoading(false);    
    }
    
    // function called when user tried to submit updateBookingSchedule form - checks validity to determine whether to post or not
    const checkFormValidity = (submission) => {
        console.log('checking form validity: ', submission);
        let isValid = true;
        if (!submission.centreId || submission.centreId === "") {
            isValid = false;
            console.log('failed validity 1');
        } 
        if (!submission.childIds || submission.childIds.length <= 0 ) {
            isValid = false;
            console.log('failed validity 2');
        }

        // commenting out as validating now handled via form submit button "disabled on invalid" option on form.io
        // if (!submission.startDate || !(Date.parse(submission.startDate)) ) {
        //     isValid = false;
        //     console.log('failed validity 3');
        //     console.log('submission.startDate', submission.startDate);
        //     console.log('Date.parse(submission.startDate)', Date.parse(submission.startDate));
        // }
        
        if (!isValid) {
            console.log('form invalid');
            alert('Form invalid, if nothing is highlighted in red please refresh the page and try again');
            return false
        } else {
            console.log('form valid, posting now');
            postSubmission(submission);
        }
    }
    
    // children data table to go inside top drawer #1
    let childrenTable = <SpinnerDark/>;
    if (childrenData) {
        childrenTable = <MasterDataTable data={childrenData} />;
    }
    
    // perm bookigns table to go inside top drawer #2
    // let permBookingTable = <SpinnerDark/>;
    // if (permBookingData) {
    //     permBookingTable = <MasterDataTable data={permBookingData}/>;
    // }
    
    // casual bookings data table to go inside top drawer #3
    let casualBookingTable = <SpinnerDark/>;
    if (casualBookingData) {
        casualBookingTable = <MasterDataTable table='casual_bookings' deleteAllowed="Cancel" deleteFunction={deleteRowHandler} data={casualBookingData} />;
    }

    // child guardian auths table to go inside top drawer #4
    let childGuardianAuthsTable = <SpinnerDark />;
    if (childGuardianAuthData) {
        childGuardianAuthsTable = <MasterDataTable updateCompleted={async () => setChildGuardianAuthData(await fetchData('child_guardian_authorisations'))} setLoading={setLoading} setError={setError} insertAllowed token={props.token} updateAllowed table='child_guardian_authorisations' data={childGuardianAuthData}/>;
    }

    let updateBookingScheduleForm = null;
    if (showUpdateBookingSchedule && childrenData) {
        updateBookingScheduleForm = 
        <Aux>
            <div className="form-group">
                {childOptions}
            </div>              
            <Form 
                src={formioAPI + 'updateBookingSchedule'} 
                submission={{data: {childIds: selectedChildren, centreId: selectedCentre}}}
                // onChange={(submission) => console.log('change: ', submission)}
                onCustomSubmit={checkFormValidity} 
                onChange={(submission) => {console.log('submission: ', submission)}}
                // onError={() => console.log('error found')}
            />
        </Aux>
    }

    const handleDisplayContent = (displayFn) => {
        setShowCalendar(false);
        setShowCasualDrawer(false);
        setShowChildrenDrawer(false);
        setShowPermDrawer(false);
        setShowCgaDrawer(false);
        setShowParentData(false);
        displayFn(true);
    } 


    // have main content in account owner page show content depending on stage user is in having their enrolment approved
    let accountOwnerContent = (
        <div className={classes.AccountOwner}>
            <h6>Please fill out the enrolment form <Link to="/enrol">here</Link> to get started</h6>
        </div>
    ); 
    if (!loading && childrenData && childrenData.length > 0) {
        accountOwnerContent = (
            <Aux >
                {/* <TopDrawer open={showCalendar} toggle={() => setShowCalendar(!showCalendar)} title="Calendar">
                </TopDrawer> */}
                {showCalendar && <div className={classes.AccountOwner} >
                    <h3>Calendar</h3>
                        {rollData && childrenData && centresData && casualBookingData
                        ? <Calendar
                            showSchedule={() => {setShowCalendar(false); setShowPermDrawer(true);}} 
                            updateSchedule={() => setShowUpdateBookingSchedule(!showUpdateBookingSchedule)} 
                            newCasual={initiateNewCasualBooking} 
                            children={childrenData} 
                            centres={centresData} 
                            rollData={rollData} 
                            casualData={casualBookingData}
                            excursionData={excursionData}
                        /> 
                        : null}
                </div>}
                {showCasualDrawer && <div className={classes.AccountOwner} >
                    <h3>Casual Bookings</h3>

                        <button onClick={initiateNewCasualBooking} className="btn btn-block btn-outline-info">Create New Booking</button>
                        <hr/>
                        {casualBookingTable}
                </div>}
                {showPermDrawer && <div className={classes.AccountOwner} >
                    <h3>Permanent Bookings</h3>
                        <Modal show={showUpdateBookingSchedule} modalClosed={() => setShowUpdateBookingSchedule(false)}>
                            <div className={classes.Form} style={{width: '90%', margin: 'auto'}}>
                            <h4>Request Schedule Change</h4>
                                {updateBookingScheduleForm}
                            </div>                        
                        </Modal>
                        <button onClick={() => setShowUpdateBookingSchedule(!showUpdateBookingSchedule)} className="btn btn-block btn-outline-info">Request Schedule Change</button>
                        {permBookingData && childrenData && centresData  ? <ScheduleView centres={centresData} children={childrenData} data={permBookingData}/> : null}
                        <hr/> 
                        {/* {permBookingTable} */}
                </div>}
                {showChildrenDrawer && <div className={classes.AccountOwner} >
                    <h3>Children</h3>
                    {childrenTable}
                </div>}
                {showCgaDrawer && <div className={classes.AccountOwner} >
                    <h3>Authorisations</h3>
                    {childGuardianAuthsTable}
                </div>}
                {showParentData && <div className={classes.AccountOwner}>
                <ParentData guardianData={guardianData}/>
                </div>}
            </Aux>
        ); 
    } else if (!loading && formSubmissionData && formSubmissionData.length > 0) {
        accountOwnerContent = (
            <div className={classes.AccountOwner}>
                <h6>Enrolment pending approval</h6>
            </div>
        );
    }


    // const testfn = async () => {
    //     // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //     const config = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         params: {tableName: 'centres'}
    //     };
        
    //     // call lambda function to get master data
    //     try {
    //         const res = await axios.get(AWS_API + 'aosh-get-data-RDS', config);
    //         console.log(res.data);
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
    //     }   
    // }



    // if (childrenData) {
    //     console.log('selected child: ', selectedChild);
    //     console.log('childrenData: ', childrenData);
    //     console.log('home centre id: ', childrenData[0].home_centre_id);
    
    //     console.log('type select: ', typeof selectedChild);
    //     console.log('type home centre: ', typeof childrenData[0].home_centre_id);

    // }

    let content = <div style={{paddingTop: '15vh'}}><Spinner /></div>
    if (!loading) {
        content = (
            <Aux>
                {/* <button className="btn btn-dark" onClick={testfn}>Test</button> */}
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>
                <Modal show={permissionSlipId} modalClosed={() => setPermissionSlipId()}>
                    <PermissionSlip handleBanner={(text) => handleBanner(setShowBanner, setBannerText, text)} setTriggerRefresh={setTriggerRefresh} token={props.token} permissionSlipId={permissionSlipId} permissionSlipData={permissionSlipData} childrenData={childrenData} setError={setError} setPermissionSlipId={setPermissionSlipId} history={history}/>
                </Modal>
                <Modal show={showCasualBookingForm} modalClosed={() => setShowCasualBookingForm(false)}>
                        <div  style={{width: '90%', margin: 'auto', textAlign: 'left'}}>
                        <h4>New Casual Booking</h4>
                            {casualBookingForm}
                            {sessionForm}
                        </div>

                </Modal>                
                <Banner show={showBanner}>{bannerText}</Banner>                

                <div className={classes.Wrapper}>
                    
                    {/* Left nav bar */}
                    <div className={classes.LeftBar}>
                        <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent(setShowCalendar)}>Calendar</button>
                        <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent(setShowParentData)}>Your Details</button>
                        <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent(setShowPermDrawer)}>Permanent Bookings</button>
                        <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent(setShowCasualDrawer)}>Casual Bookings</button>
                        <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent(setShowChildrenDrawer)}>Children</button>
                        <button className='btn btn-block btn-info btn-sm' onClick={() => handleDisplayContent(setShowCgaDrawer)}>Authorisations</button>
                        <Notifications children={childrenData} formSubmissionData={formSubmissionData} permissionSlipData={permissionSlipData} setPermissionSlipId={setPermissionSlipId} />
                    </div>

                    {/* main content */}
                    <div className={classes.Content} >
                        {accountOwnerContent}
                    </div>

                </div>
            </Aux>
        );
    }


    return content
}


  
export default AccountOwner;