import {React, useState, useCallback, useEffect, Fragment} from 'react';
import classes from './Personnel.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';
import { personnelPositions, personnelRoleTypes } from '../../../../utilities/globalObjects';

const Personnel = (props) => {

    // UI state
    const [loadingServices, setLoadingServices] = useState(false);
    const [loadingPersonnel, setLoadingPersonnel] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    // const [editing, setEditing] = useState();
    const [showAddPersonForm, setShowAddPersonForm] = useState();

    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [selectedPersonId, setSelectedPersonId] = useState();
    const [addPersonData, setAddPersonData] = useState();

    // data state
    const [centresData, setCentresData] = useState([]);
    const [personnelData, setPersonnelData] = useState([]);
    
    // react router history instance to navigate user to other pages
    let history = useHistory();       

    // console logs
    console.log('centresData: ', centresData);
    console.log('personnelData: ', personnelData);
    console.log('addPersonData: ', addPersonData);


    // function to enable editing mode
    const enableEditing = () => {
        updateProfile();
    }


    const getCentresData = useCallback(async () => {
        
        setLoadingServices(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'centres'}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setCentresData(res.data.filter(obj => obj.service_crn));
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }            

        setLoadingServices(false);


    }, [history, props]); 
    
    // function to get personnel data
    const getPersonnelData = useCallback(async () => {
        
        setLoadingPersonnel(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {serviceCRN: selectedServiceCRN, personId: selectedPersonId, filterType: selectedServiceCRN ? 'service' : 'provider'} // read-personnel CCS endpoint needs either provider or service but not both
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/read-personnel', config);
            console.log('data fetch res: ', res.data);
            setPersonnelData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }            

        setLoadingPersonnel(false);


    }, [history, props, selectedPersonId, selectedServiceCRN]); 


    // function to update provider profile data
    const updateProfile = useCallback(async () => {
        
        setLoadingPersonnel(true);

        // get the CCS start date to use as the start date in service profile update call
        const ccsStartDate = personnelData[0].CCSApproval.results[0].startDate;
        
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            ccsStartDate: ccsStartDate,
            serviceCRN: selectedServiceCRN
        };
        try {
            const res = await axios.post(AWS_API + 'ccs/update-service-profile', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Profile updated`);
            setPersonnelData();
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error while updating provider profile');
            setLoadingPersonnel(false);
        }           


    }, [history, props, setPersonnelData, personnelData, selectedServiceCRN]); 


    // get initial data
    useEffect(() => {
        getCentresData();
    }, [getCentresData])

    // get service profile data when service selected
    useEffect(() => {
        getPersonnelData();
    }, [selectedServiceCRN, selectedPersonId, getPersonnelData])    

    
    let services = <SpinnerDark />;
    if (!loadingServices) {
        const serviceData = centresData;
        services = serviceData.map(obj => {
            return (
                <button key={obj.service_crn} className="btn btn-sm btn-secondary" onClick={() => setSelectedServiceCRN(obj.service_crn)}>{obj.centre_name}</button>
            );
        });
        services.unshift(<button className="btn btn-sm btn-secondary" onClick={() => setSelectedServiceCRN()}>All</button>)
    }

    let inputs;

    let personnel = <SpinnerDark />;
    if (!loadingPersonnel) {
        // personnel = <h5>personnel</h5>
        personnel = personnelData.map(obj => {

            

            return (
            <div className={classes.Person}>
                    <h5>{}</h5>
                    <br />
                    <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                        <button className="btn btn-info btn-sm" onClick={enableEditing}>Edit Profile</button>
                    </div>
                    <button className="btn btn-dark btn-sm" onClick={() => setSelectedPersonId(obj.personID)}>Query User</button>
                    <div className={classes.Row}> <span className={classes.Item}>Person ID</span> <div>{obj.personID}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>First Name</span> <div>{obj.firstName}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Last Name</span> <div>{obj.lastName}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Date of Birth</span> <div>{obj.dateOfBirth}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Phone</span> <div>{obj.phone}</div> </div>


                    {inputs}
            </div>
            );
        });
    }

    const submitAddPerson = async () => {

        setShowAddPersonForm(false);
        setLoadingPersonnel(true);

        console.log('submitting addPersonData: ', addPersonData);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            personnelData: addPersonData
        };

        try {
            const res = await axios.post(AWS_API + 'ccs/add-personnel', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Personnel Added`);
            getPersonnelData();
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error while adding personnel');
            setLoadingPersonnel(false);
        }            
    }

    const roleOptions = Object.keys(personnelRoleTypes).map(role => <option key={role} value={role}>{personnelRoleTypes[role]}</option>);
    roleOptions.unshift(<option disabled value='default'>Select Role</option>);
    const positionOptions = Object.keys(personnelPositions).map(position => <option key={position} value={position}>{personnelPositions[position]}</option>);
    positionOptions.unshift(<option disabled value='default'>Select Position</option>);
    const centreOptions = centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>);
    centreOptions.unshift(<option disabled value='default'>Select Centre</option>);
    const addPersonForm = (
        <Fragment>
            <div className={classes.Row}> <span className={classes.Item}>Start Date</span> <input type='date' className='form-control' value={addPersonData?.startDate ?? ''} onChange={(e) => setAddPersonData({...addPersonData, startDate: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>PRODA RA Number</span> <input type='text' className='form-control' value={addPersonData?.prodaRANumber ?? ''} onChange={(e) => setAddPersonData({...addPersonData, prodaRANumber: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>First Name</span> <input type='text' className='form-control' value={addPersonData?.firstName ?? ''} onChange={(e) => setAddPersonData({...addPersonData, firstName: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Last Name</span> <input type='text' className='form-control' value={addPersonData?.lastName ?? ''} onChange={(e) => setAddPersonData({...addPersonData, lastName: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Date of Birth</span> <input type='date' className='form-control' value={addPersonData?.dob ?? ''} onChange={(e) => setAddPersonData({...addPersonData, dob: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Email</span> <input type='email' className='form-control' value={addPersonData?.email ?? ''} onChange={(e) => setAddPersonData({...addPersonData, email: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Phone</span> <input type='phone' className='form-control' value={addPersonData?.phone ?? ''} onChange={(e) => setAddPersonData({...addPersonData, phone: e.target.value})}/></div>
            <div className={classes.Row}> 
                <span className={classes.Item}>Role</span> 
                <select className='form-control' value={addPersonData?.role ?? 'default'} onChange={(e) => setAddPersonData({...addPersonData, role: e.target.value})}>
                    {roleOptions}
                </select>
            </div>
            <div className={classes.Row}> 
                <span className={classes.Item}>Position</span> 
                <select className='form-control' value={addPersonData?.position ?? 'default'} onChange={(e) => setAddPersonData({...addPersonData, position: e.target.value})}>
                    {positionOptions}
                </select>
            </div>
            <div className={classes.Row}> 
                <span className={classes.Item}>Service (Optional)</span> 
                <select className='form-control' value={addPersonData?.service ?? 'default'} onChange={(e) => setAddPersonData({...addPersonData, service: e.target.value})}>
                    {centreOptions}
                </select>
            </div>
            <br/>
            <button className="btn btn-success btn-block" onClick={submitAddPerson}>Submit</button>
        </Fragment>
    );

    
    return (
        <div className={classes.Personnel}>
            <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                <button className="btn btn-success btn-sm" onClick={() => setShowAddPersonForm(!showAddPersonForm)}>Add Personnel</button>
            </div>
            <Modal show={showAddPersonForm} modalClosed={() => setShowAddPersonForm(false)}>
                <h3>Add New Personnel</h3>
                {addPersonForm}
            </Modal>    
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>    
            <Banner show={bannerText}>{bannerText}</Banner>                

            <h3>Personnel</h3>
            <hr />
            <p><i>Select a service to filter personnel</i></p>
            <div className={classes.Row}>
                {services}
            </div>
            <hr />
            {personnel}

        </div>
    )
}

export default Personnel;