import { React, useState } from 'react';
import classes from './Kiosk.module.css';

import CreateKioskLogin from './CreateKioskLogin/CreateKioskLogin';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import SpinnerDark from '../../UI/SpinnerDark/SpinnerDark';

import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../utilities/functions';
import { useHistory } from "react-router-dom";
import Modal from '../../UI/Modal/Modal';
import AdminPin from './AdminPin/AdminPin';
import SearchContacts from './SearchContacts/SearchContacts';
import SessionSignIns from './SessionSignIns/SessionSignIns';
import Banner from '../../UI/Banner/Banner';

const Kiosk = (props) => {

    // UI state
    const [showCreateKioskLogin, setShowCreateKioskLogin] = useState(false);
    const [showUserLogin, setShowUserLogin] = useState(false);
    const [showSigninSignout, setShowSigninSignout] = useState(false);
    const [showAdminPin, setShowAdminPin] = useState(false);
    const [showSearchContacts, setShowSearchContacts] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // hoc state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [bannerText, setBannerText] = useState();    

    // data state
    const [upcomingSessions, setUpcomingSessions] = useState();
    const [signedInUserData, setSignedInUserData] = useState();

    // create react-router history object to navigate user to other pages
    const history = useHistory();

    // console logs
    console.log('signed in user data: ', signedInUserData);

    // function to send user auth data to lambda to authenticate user and bring back upcoming sessions for collection authorised children
    const authenticateUser = async (phoneNumber, pin) => {
        console.log('authenticating user with phone number: ', phoneNumber);
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            phone_number: phoneNumber,
            pin: pin
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/authenticate-user', {postData: postData}, headers);
            console.log('response: ', res.data);
            setUpcomingSessions(res.data.upcomingSessions);
            setSignedInUserData(res.data.userData);
            setLoading(false);
            setShowUserLogin(false);
            setShowSigninSignout(true);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while logging in');
            setLoading(false);
        }           
    }
    
    // function to pass into AdminPin component to validate user-entered Kiosk admin pin server-side
    const checkPin = async (pin) => {
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            pin: pin,
            getSessions: true
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/kiosk-pin-check', {postData: postData}, headers);
            console.log('response: ', res.data);
            setShowAdminPin(false);
            
            // setShowSearchContacts(true);
            // changes here to remove admin signing in children "as" a parent, instead as themselves they can straight away see the roll
            setShowSigninSignout(true);
            setUpcomingSessions(res.data.upcomingSessions);
            setSignedInUserData(res.data.userData);
            
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching data');
        } finally {
            setLoading(false);
        }   
    }

    const getUpcomingSessions = async (personId) => {
        setLoading(true);
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {person_id: personId}
        };
        try {
            const res = await axios.get(AWS_API + 'kiosk/upcoming-sessions', config);
            console.log('response: ', res.data);
            setUpcomingSessions(res.data.upcomingSessions);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching upcoming sessions');
        }   
        setLoading(false);
    }

    const updateRoll = async (rollEntries, signedInUserId, updateType) => {
        console.log('checking child in for roll session, using authorised user ID: ', rollEntries, signedInUserId);
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rollEntries: rollEntries,
            checked_in_by_id: signedInUserId,
            updateType: updateType
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/update-roll', {postData: postData}, headers);
            console.log('response: ', res.data);
            getUpcomingSessions(signedInUserId);
            setShowConfirmationModal(true);
        } catch (err) {
            setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while checking children in');
            setLoading(false);
        }         
    }


    const logout = () => {
        setUpcomingSessions();
        setSignedInUserData();
        setShowSigninSignout(false);        
    }

    const signInAdminAsUser = (obj) => {
        setShowSearchContacts(false);
        setSignedInUserData(obj);
        getUpcomingSessions(obj.person_id);
        setShowSigninSignout(true);
    }

    
    let content = (
        <Aux>
            <h4>Welcome to the Kiosk!</h4>
            <hr />            
            <button className="btn btn-info btn-block" onClick={() => setShowUserLogin(true)}>Existing Users</button>
            <button className="btn btn-info btn-block" onClick={() => setShowCreateKioskLogin(true)}>Register New User</button>
            <button className="btn btn-outline-info btn-block" onClick={() => setShowAdminPin(true)}>Staff Login</button>
        </Aux>
    );
    if (showCreateKioskLogin) {
        content = <CreateKioskLogin userCreated={() => {setShowUserLogin(true); setShowCreateKioskLogin(false)}} token={props.token}/>;
    } else if (showUserLogin) {
        content = <UserLogin setBannerText={setBannerText} setError={setError} login={authenticateUser} token={props.token}/>;
    } else if (showSigninSignout && upcomingSessions) {
        content = <SessionSignIns absentAllowed checkinAllowed token={props.token} logout={logout} updateRoll={updateRoll} userData={signedInUserData} upcomingSessions={upcomingSessions} />;
    } else if (showAdminPin) {
        content = <AdminPin checkPin={checkPin} />
    } else if (showSearchContacts) {
        content = <SearchContacts route="all-users" setUserData={signInAdminAsUser} token={props.token}/>
    }

    if (loading) {
        content = <SpinnerDark />
    }

    return (
        <div className={classes.Kiosk}>
            <Banner show={bannerText}>{bannerText}</Banner>    
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>            
            <Modal show={showConfirmationModal} modalClosed={() => setShowConfirmationModal(false)}>
                <h3>Check in/out completed successfully</h3>
                <hr/>
                <p>Do you need to do anything else, or can we close your kiosk session?</p>
                {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> */}
                    <button onClick={() => {setShowConfirmationModal(false);}} className="btn btn-outline-info btn-block">Continue Using Kiosk</button>
                    <button onClick={() => {setShowConfirmationModal(false); logout();}} className="btn btn-info btn-block">Close Session</button>
                {/* </div> */}
            </Modal>     
            {!showSigninSignout && <button onClick={() => history.replace('/')} className={[classes.AdminLoginButton,'btn', 'btn-outline-info'].join(' ')}>Home Screen</button>}    
            {content}
        </div>
    )
}




const UserLogin = (props) => {
    // data state & varaibles
    const [pin, setPin] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState();
    const [usersWithPhoneNumber, setUsersWithPhoneNumber] = useState();

    // create react-router history object to navigate user to other pages
    const history = useHistory();

    console.log('usersWithPhoneNumber: ', usersWithPhoneNumber);

    const validate = (userPin) => {
        setPin(''); 

        if (userPin.toString().length === 4) {
            props.login(phoneNumber, userPin);
        } else {
            alert('PIN must be 4-digits');
        }
    }

    const forgotPin = async () => {
        if (phoneNumber.length === 0) {
            alert('Please enter your phone number');
        } else {
            setLoading(true);
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {phone_number: phoneNumber}
            };
            try {
                const res = await axios.get(AWS_API + 'kiosk/users-by-phone-number', config);
                console.log('response: ', res.data);
                setUsersWithPhoneNumber(res.data);
            } catch (err) {
                props.setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while fetching upcoming sessions');
            }   
            setLoading(false);            
        }
    }

    const resetPin = async (person_id, centre_id) => {
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            person_id: person_id,
            centre_id: centre_id
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/reset-pin', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(props.setBannerText, `PIN Reset`);
            setTimeout(() => {
                history.replace("/");
            }, 1000);
        } catch (err) {
            props.setError(handleAwsApiError(err, history) ?? 'Unexpected error encountered while checking children in');
        } finally {
            setLoading(false);
        }
    }

    let content = (
        <Aux>
            
            <h4>Please Sign In Below</h4>
            <hr />
            <h6>Registered Phone Number</h6>
            <input style={{fontSize: '1.3rem', marginBottom: '10px'}} autoFocus className="form-control" name="phone" type="text" inputMode="numberic"
                value={phoneNumber}
                autoComplete="off"
                onChange={(e) => setPhoneNumber(e.target.value)}
                
            />
            <h6>4-Digit PIN</h6>
            <input style={{fontSize: '1.3rem', marginBottom: '10px'}}  className="form-control" name="pincode" type="password" maxLength="4" inputMode="numeric"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
            />
            <p className={classes.Link} onClick={() => forgotPin()}>Forgot pin?</p>
            <button className="btn btn-block btn-success" onClick={() => validate(pin)}>Submit</button>

        </Aux>
    ); 
    if (usersWithPhoneNumber) {
        
        content = (
            <Aux>
                <h4>Select User To Reset PIN</h4>
                <hr />            
                {
                    usersWithPhoneNumber.map(obj => {
                        return (
                            <div className={classes.ContactCard} onClick={() => resetPin(obj.person_id, obj.centre_id)}>{obj.first_name} {obj.surname}</div>
                            );
                    })
                }

            </Aux>
        ); 
        
    }

    if (loading) content = <SpinnerDark/>;

    return (
        <div className="form-group">
            {content}
        </div>
    );    
}


export default Kiosk;
