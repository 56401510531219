// cognito variables for localhost testing - using aosh-user-pool-PROD in cognito 
// export const cognitoTokenAPI = 'https://active-oosh.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
// export const cognitoClientId = '1st5mmvdqm28gb6hj2n0kp2sc1';
// export const cognitoRedirectURL = 'http://localhost:3000';
// export const cognitoUserpoolId = 'ap-southeast-2_0gkoOPR01';
// export const cognitoURL = 'https://active-oosh.auth.ap-southeast-2.amazoncognito.com/login?client_id=1st5mmvdqm28gb6hj2n0kp2sc1&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;
// export const AWS_API = 'https://69japvxa71.execute-api.ap-southeast-2.amazonaws.com/';
// export const public_s3_url = 'https://aosh-reference-data.s3.ap-southeast-2.amazonaws.com/'


// cognito variables for dev version on S3 - using aosh-user-pool-PROD in cognito 
export const cognitoTokenAPI = 'https://activeoosh-prod.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
export const cognitoClientId = '493vrcj9b2aurp6cvem001djch';
export const cognitoRedirectURL = 'https://ao-dev.citras.io';
export const cognitoUserpoolId = 'ap-southeast-2_O7UU5f1dU';
export const cognitoURL = 'https://activeoosh-prod.auth.ap-southeast-2.amazoncognito.com/login?client_id=493vrcj9b2aurp6cvem001djch&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;
export const AWS_API = 'https://69japvxa71.execute-api.ap-southeast-2.amazonaws.com/dev/';
export const public_s3_url = 'https://aosh-reference-data.s3.ap-southeast-2.amazonaws.com/'


// cognito variables for activeoosh-test AWS environment
// export const cognitoTokenAPI = 'https://active-oosh-dev.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
// export const cognitoClientId = 'd7rvue31kind7qf6mq6bbs9ck';
// export const cognitoRedirectURL = 'https://ao-test.citras.io';
// export const cognitoUserpoolId = 'ap-southeast-2_GA3zqcOHT';
// export const cognitoURL = 'https://active-oosh-dev.auth.ap-southeast-2.amazoncognito.com/login?client_id=' + cognitoClientId +  '&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;
// export const AWS_API = 'https://7566gnyru1.execute-api.ap-southeast-2.amazonaws.com/';
// export const public_s3_url = 'https://aosh-reference-data-dev.s3.ap-southeast-2.amazonaws.com/'

// generic variables for both localhost and dev/prod
export const cognitoGrantTypeAuth = 'authorization_code';
export const cognitoGrantTypeRefresh = 'refresh_token';
export const formioAPI = 'https://rgpmmogmwzdplxi.form.io/';
export const bscCutOffHour = 0;
export const ascCutOffHour = 12;
export const vacCutOffHour = 0;
export const bscStartTime = 6.5;
export const ascStartTime = 15;
export const vacStartTime = 9;
export const centreLinkMon1 = new Date('2021-11-1');
export const bscSessionLength = 2.5;
export const ascSessionLength = 3;
export const vacSessionLength = 8;

