import React from 'react';
import Modal from '../Modal/Modal';

// import classes from './Prompt.module.css';

const Prompt = (props) => (
    <Modal show={props.data.show} modalClosed={() => props.setData({...props.data, show: false})}>
        <h6>Are you sure you want to {props.data.message}?</h6>
        <hr/>
        <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px'}}>
            <button onClick={() => props.setData({...props.data, show: false})} className="btn btn-outline-danger" style={{flex: 1}}>Cancel</button>
            <button onClick={() => {props.data.proceedFunction(); props.setData({...props.data, show: false});}} className="btn btn-success" style={{flex: 1}}>Proceed</button>
        </div>
    </Modal>   
);


  
export default Prompt;